import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";

export const checkOnTimeProductIsWithCompatibleChartType = (
  chartType: EvaChart,
  product: string
): boolean => {
  if (
    (chartType === EvaChart.RateCum ||
      chartType === EvaChart.TotalRateCum ||
      chartType === EvaChart.CumTime) &&
    (product === ALL_PRODUCT_TYPES.OnTime.label ||
      product === ALL_PRODUCT_TYPES.Shrinkage.label)
  ) {
    return true;
  }
  return false;
};
