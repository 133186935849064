import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Tabs } from "antd";
import { ICheckedForecast, SyncWells, setSyncWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { toExpectedBackendProductName } from "utils/arps/productMapping";

import { convertConstantsToDatabaseUnits } from "components/arps/utils/convertConstantsToDatabaseUnits";
import TypeWellInputTable from "components/arps/widget/TypeWellEditor/tabs/TypeWellInputTable";
import { useSelectedWellFromStore } from "components/data-analyzer/hooks";

import { DEFAULT_DECLINE_TYPE_SETTING } from "../user-settings/constants/userSetting.constants";
import { useUserSettings } from "../user/hooks";
import ForecastingEditorChangeRecords from "./ForecastingEditorChangeRecords";
import { ForecastingEditorHeader } from "./ForecastingEditorHeader";
import useUpdateForecastWell from "./hooks/updateForecastWell";
import useUpdateForecastWellOnTime from "./hooks/updateForecastWellOnTime";
import useBackfillForecast from "./hooks/useBackfillForecast";
import useFetchCumulativeData from "./hooks/useFetchCumulativeData";
import useFetchForecastWell from "./hooks/useFetchForecastWell";
import usePromoteForecast from "./hooks/usePromoteForecast";
import { ForecastWellStatus, OnTime, UpdateForecastWell } from "./models/forecastWell";

const ARPS_EDITOR_KEY = "1";
const PUBLISHED_RECORDS_KEY = "2";
const ARCHIVED_RECORDS_KEY = "3";

export interface CumulativeData {
  oil: number;
  gas: number;
  water: number;
  condensate: number;
  lastProductionDate: string;
  primaryProduct: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForecastingProps {
  // wellHierarchy: WellHierarchy;
}

const Forecasting: FC<ForecastingProps> = () => {
  const selectedWell = useSelectedWellFromStore();
  const pdenSource = useSelector(
    (state: RootState) => state.groupBy.globalGroupBy.pdenSource
  );
  const { data: forecastWell } = useFetchForecastWell(selectedWell?.Uwi || "");
  const { data: cumulativeData } = useFetchCumulativeData({
    uwi: selectedWell?.Uwi || "",
    pdenSource: parseInt(pdenSource)
  });
  const { mutateAsync: updateForecast } = useUpdateForecastWell();
  const { mutateAsync: updateOnTime } = useUpdateForecastWellOnTime();
  const { mutateAsync: promoteForecast } = usePromoteForecast();
  const { mutateAsync: backfillForecast } = useBackfillForecast();

  const dispatch = useDispatch();

  const syncWells = useSelector((state: RootState) => state.app.syncWells);
  const syncWellsRef = useRef<SyncWells>(syncWells);

  const role = useSelector((state: RootState) => state.auth.user?.role);
  const isAdminOrPowerUser = role === "Admin" || role === "PowerUser";

  const [activeTab, setActiveTab] = useState(ARPS_EDITOR_KEY);

  const { declineType: declineTypeSetting } = useUserSettings();
  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;

  useEffect(() => {
    syncWellsRef.current = syncWells;
  }, [syncWells]);

  useEffect(() => {
    if (!forecastWell || !syncWellsRef.current) {
      return;
    }
    dispatch(
      setSyncWells({
        ...syncWellsRef.current,
        activeWells: [forecastWell.uwi]
      })
    );
  }, [forecastWell]);

  const handleForecastChange = async (item: ICheckedForecast) => {
    try {
      await updateForecast({
        uwi: forecastWell.uwi,
        update: {
          entityName: forecastWell.entityName,
          forecast: {
            segments: item.arps.map((seg) => ({
              ...seg,
              product: toExpectedBackendProductName(seg.product)
            })),
            onTimeArray: item.onTimeArray,
            constants: convertConstantsToDatabaseUnits(item.constants).map((fc) => ({
              ...fc,
              product: toExpectedBackendProductName(fc.product)
            }))
          }
        } as UpdateForecastWell
      });
    } catch (error) {
      toast.error("Failed to update forecast");
    }
  };

  const handleArchiveForecast = async () => {
    try {
      await promoteForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Archived
      });
    } catch (error) {
      toast.error("Failed to archive forecast");
    }
  };

  const handlePublishForecast = async () => {
    try {
      await promoteForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Published
      });
    } catch (error) {
      toast.error("Failed to publish forecast");
    }
  };

  const handleDownloadPublishedForecast = async () => {
    try {
      await backfillForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Published
      });
    } catch (error) {
      toast.error("Failed to populate forecast from published version");
    }
  };

  const handleUnarchiveForecast = async () => {
    try {
      await backfillForecast({
        uwis: [forecastWell.uwi],
        status: ForecastWellStatus.Archived
      });
    } catch (error) {
      toast.error("Failed to populate forecast from archived version");
    }
  };

  const handleOnTimeChange = async (onTime: OnTime[]) => {
    try {
      await updateOnTime({
        uwi: selectedWell?.Uwi,
        update: {
          forecast: {
            onTimeArray: onTime
          }
        } as UpdateForecastWell
      });
    } catch {
      toast.error("Unable to update on-time");
    }
  };

  return (
    <RootContainer>
      <Header>
        <ForecastingEditorHeader
          entityName={forecastWell?.entityName}
          handleArchiveForecast={handleArchiveForecast}
          handlePublishForecast={handlePublishForecast}
          handleDownloadPublishedForecast={handleDownloadPublishedForecast}
          handleUnarchiveForecast={handleUnarchiveForecast}
          isWidget={true}
          saveStatus={true}
          hideButtons={!isAdminOrPowerUser}
        />
      </Header>
      {forecastWell && (
        <Tabs
          defaultActiveKey={ARPS_EDITOR_KEY}
          activeKey={activeTab}
          onTabClick={setActiveTab}
          items={[
            {
              key: ARPS_EDITOR_KEY,
              label: "Forecast",
              children: (
                <TypeWellInputWrapper>
                  <TypeWellInputTable
                    declineType={declineType}
                    selectedTypeWellTitle={""}
                    kind={"Forecasting"}
                    arps={
                      (forecastWell?.forecast as unknown as ICheckedForecast) ??
                      ({ title: forecastWell?.entityName } as ICheckedForecast)
                    }
                    selectedTypeWellRescat={"P+PDP"}
                    onOnTimeChange={handleOnTimeChange}
                    onChange={handleForecastChange}
                    cumulativeData={cumulativeData}
                  />
                </TypeWellInputWrapper>
              )
            },
            {
              key: PUBLISHED_RECORDS_KEY,
              label: "Published Records",
              children: (
                <>
                  <ForecastingEditorChangeRecords
                    forecastId={forecastWell?.uwi}
                    status={ForecastWellStatus.Published}
                  />
                </>
              )
            },
            {
              key: ARCHIVED_RECORDS_KEY,
              label: "Archived Records",
              children: (
                <>
                  <ForecastingEditorChangeRecords
                    forecastId={forecastWell?.uwi}
                    status={ForecastWellStatus.Archived}
                  />
                </>
              )
            }
          ]}
        />
      )}
      {!forecastWell && <div>Select a well from Well Hierarchy to start...</div>}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  display: grid;
  overflow-x: auto;

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    caret-color: transparent;
  }

  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 1.4rem;
    color: #a2aaad;
    letter-spacing: 0.5px;
    padding: 0 15px 0;
  }

  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: var(--color-text);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-text);
    font-weight: 700;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
    left: ${(props) => (props.activeKey - 1) * 157}px !important;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-na {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: var(--color-primary);
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs {
    min-height: 0;
  }

  .ant-tabs .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-content {
    display: grid;
    overflow-y: auto;
    padding-bottom: 10px;
  }

  .ant-tree {
    background: transparent;
  }

  .ant-tree-switcher {
    background: transparent;
  }
`;

const Header = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
`;

const TypeWellInputWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: grid;
  margin-top: 10px;
`;

export default Forecasting;
