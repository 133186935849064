export const allTrue = (...arr: boolean[]): boolean => arr.every((item) => item === true);

export const anyTrue = (...arr: boolean[]): boolean => arr.some((item) => item === true);

export function isEmptyObject<T>(item: T): boolean {
  return Object.keys(item).length === 0 && item.constructor === Object;
}

export function isPresent<T>(item: Maybe<T>): item is T {
  return allTrue(typeof item !== "undefined", item !== null, !isEmptyObject(item));
}
