import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _zip from "lodash/zip";
import { EvaChartStates } from "types/factory";
import { uid } from "utils";

export function translateCrossPlotResponseToOptions(states: Partial<EvaChartStates>) {
  const { title, series, uwis, layout, uwiCoordinates, debug } = states;

  const xAxisValues = layout.xAxis.data;

  const colorList = {};
  for (const s of series) {
    colorList[s.label] = s.style.hexColor;
  }
  const options = createEntityState(ChartOption.Chart, {
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        type: "category",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        data: xAxisValues, // data is present in CategoryAxisBaseOption,
        name: layout.xAxis.title
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: layout.yAxis.title,
        nameTruncate: { maxWidth: 520, ellipsis: "..." } // TODO chart: use getAxisTitleTruncatingWidth
      })
    },
    uwiList: uwis,
    uwiCoordinates: uwiCoordinates,
    series: series.map((s) => {
      const { label, style, x, y, uwiIndices } = s;
      const zippedData = _zip(x, y, uwiIndices);

      const scatterSeries = createEntityState(ChartOption.ScatterSeries, {
        id: `${label}, ${uid()}`,
        name: label,
        data: zippedData,
        itemStyle: {
          color: style.hexColor
        }
      });
      return scatterSeries;
    })
  });

  if (debug?.active) {
    // eslint-disable-next-line no-console
    console.log("Please see dev docs to see how to debug cross plot data.");
  }
  return options;
}
