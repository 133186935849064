import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useGlobalProductionSettings } from "hooks";

import { useChartsContext } from "../../../contexts";
import { GlobalProductionSettings } from "../../../hooks/useGlobalProductionSettings";
import { INormalizeBySetting } from "../../../models";
import { EntityKind } from "../../../models/entityKind";
import {
  selectChartFocusFeature,
  selectChartNormalizationFeature,
  selectChartSourceFeature,
  selectChartTimeStepFeature
} from "../../../store/features";

export interface GlobalSettings {
  usingGlobalFocus: boolean;
  usingGlobalTimestep: boolean;
  usingGlobalProduction: boolean;
  usingGlobalNormalizeBy: boolean;
  usingAllGlobalSettings: boolean;
}

const useChartVsGlobalSettings = (): GlobalSettings => {
  const { id } = useChartsContext();
  const globalSettings = useGlobalProductionSettings(EntityKind.Well);
  const normalizeBySettings = useSelector(
    (state: RootState) => selectChartNormalizationFeature(state, id)?.properties
  );
  const chartFocusSettings = useSelector((state: RootState) =>
    selectChartFocusFeature(state, id)
  );
  const timeStepSettings = useSelector((state: RootState) =>
    selectChartTimeStepFeature(state, id)
  );
  const chartSourceSettings = useSelector((state: RootState) =>
    selectChartSourceFeature(state, id)
  );

  // TODO chart - open legend conditionally once the legend has been implemented
  // useEffect(() => {
  //   openLegendConditionally(!settings.legend.visible && settings?.useChartFocus);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [settings?.useChartFocus]);

  const isUsingGlobalNormalizeBy = (
    settings: INormalizeBySetting,
    globalSettings: GlobalProductionSettings
  ): boolean => {
    // If both are disabled, they're equivalent
    if (!settings?.useNormalizeBy && !globalSettings?.normalizeBy) {
      return true;
    }

    // If both are enabled, compare only the relevant properties
    if (settings?.useNormalizeBy && !!globalSettings?.normalizeBy) {
      const localNormalizeSettings = {
        field: settings.field,
        per: settings.per,
        unit: settings.unit,
        useMultilinearNormalization: settings.useMultilinearNormalization,
        threshold: settings.threshold,
        lowerScalar: settings.lowerScalar,
        higherScalar: settings.higherScalar
      };

      const globalNormalizeSettings = {
        field: globalSettings.normalizeBy.field,
        per: globalSettings.normalizeBy.per,
        unit: globalSettings.normalizeBy.unit,
        useMultilinearNormalization:
          globalSettings.normalizeBy.useMultilinearNormalization,
        threshold: globalSettings.normalizeBy.threshold,
        lowerScalar: globalSettings.normalizeBy.lowerScalar,
        higherScalar: globalSettings.normalizeBy.higherScalar
      };

      return (
        JSON.stringify(localNormalizeSettings) === JSON.stringify(globalNormalizeSettings)
      );
    }
    return false;
  };

  /* OLD CODE - do we need to still open the legend conditionally? */
  // const openLegendConditionally = (condition) => {
  //   if (instance && condition) {
  //     dispatch({
  //       type: "settings",
  //       payload: {
  //         ...settings,
  //         legend: {
  //           visible: !settings.legend.visible,
  //           position: settings.legend.position
  //         }
  //       }
  //     });
  //   }
  // };

  return useMemo(() => {
    const usingGlobalFocus: boolean = !chartFocusSettings?.active;
    const usingGlobalTimestep: boolean =
      globalSettings?.timeStep === timeStepSettings?.properties.value;
    const usingGlobalProduction: boolean =
      globalSettings?.source === chartSourceSettings?.properties.value;
    const usingGlobalNormalizeBy: boolean = isUsingGlobalNormalizeBy(
      normalizeBySettings,
      globalSettings
    );

    // TODO chart - multi product chart settings
    // const usingMultiProduct: boolean =
    //   settings?.otherProducts?.length > 0 && settings.chartType === "Total Rate Date";

    return {
      usingGlobalFocus,
      usingGlobalTimestep,
      usingGlobalProduction,
      usingGlobalNormalizeBy,
      usingAllGlobalSettings:
        usingGlobalFocus &&
        usingGlobalTimestep &&
        usingGlobalProduction &&
        usingGlobalNormalizeBy
      // && !usingMultiProduct
    };
  }, [
    JSON.stringify(globalSettings?.normalizeBy),
    globalSettings?.source,
    globalSettings?.timeStep,

    JSON.stringify(normalizeBySettings),
    // TODO chart: sort out types so it doesnt complain about properties
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    normalizeBySettings?.useNormalizeBy,
    chartFocusSettings?.active,
    timeStepSettings.properties.value,
    chartSourceSettings.properties.value
    // settings?.otherProducts // TODO chart - multi product chart settings
  ]);
};
export default useChartVsGlobalSettings;
