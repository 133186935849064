import {
  addDays,
  addMonths,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  getDaysInMonth,
  isValid,
  parseISO,
  startOfDay
} from "date-fns";
import dayjs, { Dayjs, extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import { roundTo } from "utils/numbers";

extend(utc);
export const AVG_DAYS_IN_YEAR = 365.242374;
export const AVG_DAYS_IN_MONTH = 30.436;
export const SECONDS_IN_DAY = 60 * 60 * 24;

interface RateDate {
  year: number;
  month: number;
  day: number;
}

export function getStartOfNextMonth(): Date {
  const currentDate = new Date();
  const day = currentDate.getDay();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  if (day > 1) {
    month += 1;
  }
  if (month > 11) {
    month = 0;
    year += 1;
  }
  return new Date(year, month, 1);
}

export function getDurationInMonths(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 12;
  }

  const monthsDifference = differenceInMonths(date2, date1);
  const startOfNextMonth = addMonths(date1, monthsDifference);
  const remainingDays = differenceInDays(date2, startOfNextMonth);
  const endMonthDays = getDaysInMonth(date2);

  return monthsDifference + remainingDays / endMonthDays;
}

export function numberOfMonthsAfterDate(date1?: Date, date2?: Date): number {
  if (!date1 || !date2) {
    return 0;
  }
  return Math.max(0, differenceInMonths(date2, date1));
}

export function getDurationInYears(startDate: string, endDate: string): number {
  const start = startOfDay(parseISO(startDate));
  const end = startOfDay(parseISO(endDate));
  if (!isValid(start) || !isValid(end)) {
    throw "Invalid date format";
  }
  const yearsDifference = differenceInYears(end, start);
  const startOfNextYear = addMonths(start, yearsDifference * 12);
  const remainingDays = differenceInDays(end, startOfNextYear);

  return yearsDifference + remainingDays / AVG_DAYS_IN_YEAR;
}

export function getDurationInDays(startDate: string, endDate: string) {
  const start = startOfDay(parseISO(startDate));
  const end = startOfDay(parseISO(endDate));
  if (!isValid(start) || !isValid(end)) {
    throw "Invalid date format";
  }
  return differenceInDays(end, start);
}

export function compareRateDate(a: RateDate, b: RateDate): number {
  return new Date(a.year, a.month + 1, a.day) >= new Date(b.year, b.month + 1, b.day)
    ? 1
    : -1;
}

export function addMonthsToDate(date: Date, monthsToAdd: number): Date {
  const wholeMonthsToAdd = Math.floor(monthsToAdd);
  const partialMonthToAdd = monthsToAdd - wholeMonthsToAdd;

  let newDate = addMonths(date, wholeMonthsToAdd);

  // Add fractional month (approximated to days based on the specific month length).
  if (partialMonthToAdd > 0) {
    const nextMonthDate = addMonths(newDate, 1);
    const daysInNextMonth = differenceInDays(nextMonthDate, newDate);
    const additionalDays = Math.round(partialMonthToAdd * daysInNextMonth);
    newDate = addDays(newDate, additionalDays);
  }

  return newDate;
}

export function addAvgMonthsToDate(date: Date, monthsToAdd: number): dayjs.Dayjs {
  const seconds = monthsToAdd * AVG_DAYS_IN_MONTH * SECONDS_IN_DAY;
  return dayjs.utc(date).add(seconds, "seconds");
}

/**
 * Computes the absolute difference in days between two dates.
 *
 * @param d1 - The first date string (ISO format)
 * @param d2 - The second date string (ISO format)
 * @returns The absolute difference in days as a decimal value
 */
export const daysDiff = (d1: string, d2: string): number => {
  if (!d1 || !d2 || typeof d1 !== "string" || typeof d2 !== "string") {
    throw new Error("Both dates must be provided as non-empty strings");
  }

  if (!dayjs(d1).isValid() || !dayjs(d2).isValid()) {
    throw new Error("Invalid date format. Dates should be in ISO format");
  }

  return Math.abs(dayjs.utc(d1).diff(dayjs.utc(d2), "days", true));
};
/**
 * Computes the difference in avg months between two dates.
 *
 * @param d1 - The first date string (ISO format)
 * @param d2 - The second date string (ISO format)
 * @returns The absolute difference in months as a decimal value
 */
export const avgMonthsDiff = (d1: string, d2: string): number => {
  if (!d1 || !d2 || typeof d1 !== "string" || typeof d2 !== "string") {
    throw new Error("Both dates must be provided as non-empty strings");
  }

  if (!dayjs(d1).isValid() || !dayjs(d2).isValid()) {
    throw new Error("Invalid date format. Dates should be in ISO format");
  }
  const date1 = dayjs.utc(d1);
  const date2 = dayjs.utc(d2);
  const days = Math.abs(date1.diff(date2, "days", true));
  return roundTo(days / AVG_DAYS_IN_MONTH, 2);
};

export const addAvgFractionalMonths = (utcString: string, months: number): string => {
  return addAvgMonthsToDate(new Date(utcString), months).toISOString();
};

export const getFirstDayOfNextMonthUTC = (date: Date): Date => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const nextMonth = month + 1;

  const isNextMonthJanuary = nextMonth > 11;

  if (isNextMonthJanuary) {
    return new Date(Date.UTC(year + 1, 0, 1)); // January 1 of the next year.
  } else {
    return new Date(Date.UTC(year, nextMonth, 1)); // First day of the next month.
  }
};

export const getDateStringAtMidnightUtc = (date: string): string => {
  return date.split("T")[0] + "T00:00:00.000Z";
};
/**
 * Calculates a fractional month value based on a date and month index.
 *
 * This function takes a date and returns a decimal number representing the month
 * with a fractional part indicating how far into the month the date is. For example,
 * if the date is the 15th day of a 30-day month, the fractional part would be 0.5.
 *
 * @param date - A Day.js date object to calculate the fractional month for
 * @param monthIndex - Base month index (integer) to add the fraction to
 * @returns A decimal number representing the month index plus the fractional progress through the month
 *
 * @example
 * // If date is January 15, 2023 and monthIndex is 0
 * // In a 31-day month, returns approximately 0.48 (0 + 15/31)
 * getFractionalMonths(dayjs('2023-01-15'), 0);
 */
export function getFractionalMonths(date: Dayjs, monthIndex: number): number {
  const day = date.date() - 1; //-1 to make sure that if it starts at day 1 it uses whole monthIndex
  const frac = day / date.daysInMonth();
  const monthFrac = monthIndex + frac;
  return monthFrac;
}

/**
 * Determines if a date is in the month immediately following another date.
 *
 * This function checks if the month of nextDate is exactly one month after
 * the month of lastDate, regardless of the day within the month. It normalizes
 * both dates to the start of their respective months before comparing.
 *
 * @param nextDate - The later date to check
 * @param lastDate - The earlier date to compare against
 * @returns Boolean indicating if nextDate is in the month immediately following lastDate
 *
 * @example
 * // Returns true
 * isNextMonth(new Date('2023-02-15'), new Date('2023-01-20'));
 *
 * // Returns true (handles year boundaries)
 * isNextMonth(new Date('2024-01-01'), new Date('2023-12-31'));
 *
 * // Returns false (more than one month apart)
 * isNextMonth(new Date('2023-03-01'), new Date('2023-01-01'));
 */
export function isNextMonth(lastDate?: Date, nextDate?: Date): boolean {
  if (!lastDate || !nextDate) {
    return false;
  }
  const nextDayjs = dayjs.utc(nextDate).startOf("month");
  const lastDayjs = dayjs.utc(lastDate).startOf("month");
  const lastPlusOneMonth = lastDayjs.add(1, "month");
  return nextDayjs.isSame(lastPlusOneMonth);
}
