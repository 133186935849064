import { memo } from "react";

function SalesOutlet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
      fill="currentColor"
      transform="scale(0.85)"
      height="40"
      width="40">
      <g transform="scale(0.8) translate(0, -2)">
        {/* fire */}
        <g transform="scale(0.35) translate(25.2,26)">
          <g transform="translate(5, 0)">
            <path
              d="M19.4786 16.6962C19.141 11.248 9.59585 0 9.59585 0C7.43684 9.69182 -0.00861992 10.7961 7.49155e-06 17.1761C0.00539963 21.4812 1.95735 23.981 4.39244 25.3085C3.62136 2.73 3.93411 19.7374 6.24949 17.574C10.5244 13.5796 10.9946 10.8932 10.9946 10.8932C15.4172 18.7452 14.3323 23.679 12.6661 26.1669C16.7232 25.0465 19.7881 21.6839 19.4786 16.6962"
              id="Gas"
              fill="currentColor"
              fillRule="evenodd"
              stroke="none"
            />
          </g>
          <g>
            <rect
              x="16" // Set the x position to 50% for centering
              y="16" // Set the y position to 50% for centering
              width="15" // Set the width to 50% for the bottom right position
              height="11" // Set the height to 50% for the bottom right position
              fill="#FFF"
            />
          </g>
          <g transform="translate(9.5, 13) scale(0.85)">
            <path
              d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
              fill="currentColor"
              fillRule="evenodd"
              stroke="none"
            />
          </g>
        </g>
        {/* circle */}
        <path
          transform="rotate(180, 12, 12) scale(0.8)"
          d="M22 12A10 10 0 0 1 2.46 15H4.59A8 8 0 1 0 4.59 9H2.46A10 10 0 0 1 22 12Z"
        />

        {/* arrow */}
        <path
          transform="scale(0.4) translate(47, 24)"
          d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z"
        />

        {/* dashed box */}
        <path
          transform="translate(-1.6,-1.4) scale(1.3)"
          d="M2.4,4C2.4,3.3 3.1,2.4 4.2,2.4H6.6V4H4.2V6.6H2.4V4M21.6,4V6.6H20V4H17.4V2.4H20A1.6,1.6 0 0,1 21.6,4M20,20V17.4H21.6V20C21.6,20.9 20.9,21.6 20,21.6H17.4V20H20M2.4,20V17.4H4.2V20H6.6V21.6H4.2A1.6,1.6 0 0,1 2.4,20M10.4,2.4H13.6V4H10.4V2.4M10.4,20H13.6V21.6H10.4V20M2.4,10H4.2V13.6H2.4V10Z"
        />
      </g>
    </svg>
  );
}

export default memo(SalesOutlet);
