import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Divider, Slider, Switch, Typography } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChart,
  selectChartAverageFeature,
  selectChartP10Feature,
  selectChartP50Feature,
  selectChartP90Feature,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { EvaChartFeatures } from "types";

import { ColorChooser, SimpleColorPicker } from "components/base";

const { Text } = Typography;

interface StatisticsOptionsProps {
  isVisible: boolean;
}

function StatisticsOptions({ isVisible }: StatisticsOptionsProps) {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const chart = useSelector((state: RootState) => selectChart(state, id));

  const averageSettings = useSelector((state: RootState) =>
    selectChartAverageFeature(state, id)
  );
  const p10Settings = useSelector((state: RootState) => selectChartP10Feature(state, id));
  const p50Settings = useSelector((state: RootState) => selectChartP50Feature(state, id));
  const p90Settings = useSelector((state: RootState) => selectChartP90Feature(state, id));

  const averageColor = averageSettings?.properties?.color as string;
  const averageThickness = averageSettings?.properties.thickness as number;

  const p10Color = p10Settings?.properties?.color as string;
  const p10Thickness = p10Settings?.properties.thickness as number;

  const p50Color = p50Settings?.properties?.color as string;
  const p50Thickness = p50Settings?.properties?.thickness as number;

  const p90Color = p90Settings?.properties?.color as string;
  const p90Thickness = p90Settings?.properties?.thickness as number;

  const [customColors, setCustomColors] = useState([]);
  const [showColorChooser, setShowColorChooser] = useState("");

  const showPercentiles =
    chart.chartType === EvaChart.RateCum ||
    chart.chartType === EvaChart.RateTime ||
    chart.chartType === EvaChart.CumTime;

  useEffect(() => {
    if (!isVisible) {
      setShowColorChooser("");
    }
  }, [isVisible]);

  const handleToggle = (key: keyof EvaChartFeatures) => (active: boolean) => {
    dispatch(updateChartByPath({ id, path: `features.${key}.active`, value: active }));
  };

  const handleThicknessChange = (key: keyof EvaChartFeatures) => (thickness: number) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.properties.thickness`,
        value: thickness
      })
    );
  };

  const handleColorChange = (key: keyof EvaChartFeatures) => (color: string) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.${key}.properties.color`,
        value: color
      })
    );
  };

  return (
    <Wrapper>
      {showPercentiles ? (
        <>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                data-testid="p10ColorPicker"
                setColor={() => setShowColorChooser("p10")}
                values={{
                  name: "p10",
                  color: p10Color,
                  strokeColor: "#ffffff",
                  thickness: p10Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P10</StyledText>
            </FlexItem>
            <Switch
              data-testid="p10Switch"
              size="small"
              checked={p10Settings?.active}
              onChange={handleToggle("p10")}
            />
          </Head>
        </>
      ) : (
        <></>
      )}

      <Head>
        <FlexItem>
          <SimpleColorPicker
            data-testid="averageColorPicker"
            setColor={() => setShowColorChooser("average")}
            values={{
              name: "average",
              color: averageColor,
              strokeColor: "#ffffff",
              thickness: averageThickness,
              opacity: null
            }}
          />
          <StyledText strong>Average</StyledText>
        </FlexItem>
        <Switch
          data-testid="averageSwitch"
          size="small"
          checked={averageSettings?.active}
          onChange={handleToggle("average")}
        />
      </Head>

      {showPercentiles ? (
        <>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                data-testid="p50ColorPicker"
                setColor={() => setShowColorChooser("p50")}
                values={{
                  name: "p50",
                  color: p50Color,
                  strokeColor: "#ffffff",
                  thickness: p50Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P50</StyledText>
            </FlexItem>
            <Switch
              data-testid="p50Switch"
              size="small"
              checked={p50Settings?.active}
              onChange={handleToggle("p50")}
            />
          </Head>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                data-testid="p90ColorPicker"
                setColor={() => setShowColorChooser("p90")}
                values={{
                  name: "p90",
                  color: p90Color,
                  strokeColor: "#ffffff",
                  thickness: p90Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P90</StyledText>
            </FlexItem>
            <Switch
              data-testid="p90Switch"
              size="small"
              checked={p90Settings?.active}
              onChange={handleToggle("p90")}
            />
          </Head>
        </>
      ) : (
        <></>
      )}

      {showColorChooser === "average" && (
        <>
          <StyledDivider />
          <LinePreview color={averageColor} thickness={averageThickness} />
          <ColorChooser
            data-testid="averageColorChooser"
            selectedColor={averageColor}
            onChange={handleColorChange("average")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              data-testid="averageThicknessSlider"
              min={1}
              max={20}
              value={averageThickness}
              onChange={handleThicknessChange("average")}
            />
          </SliderWrapper>
        </>
      )}
      {showColorChooser === "p90" && (
        <>
          <StyledDivider />
          <LinePreview color={p90Color} thickness={p90Thickness} />
          <ColorChooser
            data-testid="p90ColorChooser"
            selectedColor={p90Color}
            onChange={handleColorChange("p90")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              data-testid="p90ThicknessSlider"
              min={1}
              max={20}
              value={p90Thickness}
              onChange={handleThicknessChange("p90")}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "p50" && (
        <>
          <StyledDivider />
          <LinePreview color={p50Color} thickness={p50Thickness} />
          <ColorChooser
            data-testid="p50ColorChooser"
            selectedColor={p50Color}
            onChange={handleColorChange("p50")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              data-testid="p50ThicknessSlider"
              min={1}
              max={20}
              value={p50Thickness}
              onChange={handleThicknessChange("p50")}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "p10" && (
        <>
          <StyledDivider />
          <LinePreview color={p10Color} thickness={p10Thickness} />
          <ColorChooser
            data-testid="p10ColorChooser"
            selectedColor={p10Color}
            onChange={handleColorChange("p10")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              data-testid="p10ThicknessSlider"
              min={1}
              max={20}
              value={p10Thickness}
              onChange={handleThicknessChange("p10")}
            />
          </SliderWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default StatisticsOptions;

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const Head = styled.div`
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  padding-left: 2px;
  padding-top: 1px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const LinePreview = styled.div`
  background-color: ${(p) => p.color};
  height: ${(p) => p.thickness}px;
  width: 80%;
  margin: 8px auto;
`;

const SliderWrapper = styled.div`
  display: grid;
`;

const FlexItem = styled.div`
  display: inline-flex;
`;
