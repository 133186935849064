import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { EvaChart } from "constants/charts.enums";
import { selectChartFeatures, selectChartProduct, selectChartType } from "store/features";
import { RootState } from "store/rootReducer";
import { EvaChartFeatures } from "types";

import { extractActiveAndProperties } from "./utils";

interface ChartSettings {
  chartType: EvaChart;
  product: string;
  features: Partial<EvaChartFeatures>;
}

export const useChartSessions = (id: string) => {
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const product = useSelector((state: RootState) => selectChartProduct(state, id));
  const features = useSelector((state: RootState) => selectChartFeatures(state, id));

  const prevChartSettingsRef = useRef<ChartSettings | null>(null);

  useEffect(() => {
    const newChartSettings: ChartSettings = { chartType, product, features };

    if (
      JSON.stringify(newChartSettings) !== JSON.stringify(prevChartSettingsRef.current)
    ) {
      const extracted = extractActiveAndProperties(newChartSettings.features);
      // `${entityKind === "Well" ? "chart" : "midstream-chart"}::${chartData.id}`,
      sessionStorage.setItem(
        `chart::${id}`,
        JSON.stringify({ chartType, product, features: extracted })
      );
      prevChartSettingsRef.current = newChartSettings;
    }
  }, [chartType, product, features, id]);
};
