import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MaterialBalanceTimeChartProducts } from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts";
import { selectChartProduct, selectChartType, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import { checkIncompatibleProductWithTotalRateDate } from "../components/ProductSelector/utils/checkIncompatibleProductWithTotalRateDate";
import { checkOnTimeProductIsWithCompatibleChartType } from "../components/ProductSelector/utils/checkOnTimeProductIsWithCompatibleChartType";

export const useChartProductHandler = () => {
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const product = useSelector((state: RootState) => selectChartProduct(state, id));
  const dispatch = useDispatch();

  useEffect(() => {
    // display "rate time" chart if "rate cum" or "cum time" or "total rate cum" is selected for on time product
    if (checkOnTimeProductIsWithCompatibleChartType(chartType, product)) {
      dispatch(
        updateChartByPath({
          id,
          path: "chartType",
          value: EvaChart.RateTime
        })
      );
    }

    // display "rate date" chart if "total rate date" is selected for ratios/percentages/yields/HeatingValue/Shrinkage
    if (checkIncompatibleProductWithTotalRateDate(chartType, product)) {
      dispatch(
        updateChartByPath({
          id,
          path: "chartType",
          value: EvaChart.RateDate
        })
      );

      // TODO chart: handle total rate date toast
      //   const totalRateDateUnsupportedProductWarning = `total-rate-date-unsupported-warning-${id}`;
      //   toast.dismiss(totalRateDateUnsupportedProductWarning);
      //   toast.warn(
      //     "Total Rate Date not supported for ratio products or percentages. Showing Rate Date instead.",
      //     {
      //       containerId: id,
      //       toastId: totalRateDateUnsupportedProductWarning
      //     }
      //   );
    }

    if (chartType !== chartType || product !== product) {
      // TODO chart: used in conjunction with commented out spaghetti code below
      // let showAverage = average;
      // if (
      //   chartType === ALL_CHART_TYPES.RateDate.label ||
      //   chartType === ALL_CHART_TYPES.CAGR.label ||
      //   chartType === ALL_CHART_TYPES.BaseDeclineRate.label ||
      //   chartType === ALL_CHART_TYPES.TotalRateDate.label
      // ) {
      //   showAverage = false;
      // }

      const isMaterialBalance = chartType === EvaChart.MaterialBalanceTime;

      // Switch product to oil if the previously selected product isn't compatible with material balance
      if (isMaterialBalance) {
        if (!MaterialBalanceTimeChartProducts.includes(product)) {
          dispatch(
            updateChartByPath({
              id,
              path: "chartType",
              value: EvaChart.MaterialBalanceTime
            })
          );
          dispatch(
            updateChartByPath({
              id,
              path: "product",
              value: MaterialBalanceTimeChartProducts[0]
            })
          );
        }
      }

      // TODO chart: check if this is needed after material balance chart is enabled
      // Spaghetti mode defaulting
      // Keep sum on if sum has been previously enabled
      // const newSum = sum || isMaterialBalance;

      //   dispatch({
      //     type: "settings",
      //     payload: Object.assign({}, chart, {
      //       product: product,
      //       chartType: chartType,
      //       average: showAverage,
      //       sum: newSum
      //     })
      //   });
    }
  }, [product, chartType, dispatch]);
};
