import { Uwi } from "models";

export const getSeriesNameWithFormattedUwi = (name: string): string => {
  if (!name) {
    return "";
  }

  const uwi = new Uwi();
  if (name.includes("Forecast")) {
    const [uwiString, forecast] = name.split("Forecast");
    const formattedUwi = uwi.toFormatted(uwiString.trim());
    return `${formattedUwi} Forecast ${forecast.trim()}`;
  }

  return uwi.toFormatted(name);
};
