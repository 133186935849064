import { LineSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_LINE_CHART_OPTIONS: Partial<LineSeriesOption> = {
  name: "",
  type: SeriesType.Line,
  showSymbol: false,
  animation: false,
  blendMode: "source-over",
  lineStyle: { width: 2, type: "solid", opacity: 1 },
  z: 2, // TODO Chart: check if we can use z-index for highlighting target data point
  emphasis: {
    focus: "series",
    lineStyle: {
      width: 6,
      opacity: 1
    },
    itemStyle: {
      borderWidth: 3,
      borderRadius: 10,
      // borderColor: "rgba(0,0,0,0.5)",
      opacity: 1,
      decal: {
        backgroundColor: "white"
      }
    }
  },
  blur: {
    lineStyle: {
      opacity: 0.4
    }
  }
};

export const generateLineSeriesOptions = createStateWithDefaults(
  DEFAULT_LINE_CHART_OPTIONS
);
