import { EvaChart } from "constants/charts.enums";
import { curry, map } from "lodash";
import { ChartActionType } from "types/echarts";

/**
 * Accepts a chart instance, an action type, and then a series index.
 */
export const dispatchAction = curry((chartInstance, actionType, seriesIndex: number) => {
  chartInstance.dispatchAction({
    type: actionType,
    seriesIndex
  });
});

/**
 * Resets highlights based on the chart type.
 * Calls the appropriate highlight reset function depending on the chart type.
 *
 * @param chartInstance - The chart instance.
 * @param chartType - The type of the chart (EvaChart.CrossPlot or EvaChart.RateCum).
 * @param series - The series array from the chart options.
 */
export function resetHighlightsByChartType(chartInstance, chartType: EvaChart, series) {
  if (chartType === EvaChart.CrossPlot) {
    resetCrossPlotHighlight(chartInstance);
  } else if (chartType === EvaChart.RateCum) {
    resetRateCumSeriesHighlights(chartInstance, series);
  }
}

/**
 * Resets all highlights based on the chart type
 */
export function resetHighlights(chartInstance, chartType: EvaChart) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  resetHighlightsByChartType(chartInstance, chartType, chartOptions.series);

  dispatchResetActionsForAllSeries(chartInstance, chartOptions.series.length);
}

/**
 * Resets CrossPlot highlights by hiding the target graphic.
 */
export function resetCrossPlotHighlight(chartInstance) {
  chartInstance.setOption({
    graphic: {
      id: "target",
      type: "circle",
      invisible: true
    }
  });
}

/**
 * Updates each series for the RateCum chart.
 */
export function resetRateCumSeriesHighlights(chartInstance, series) {
  const updatedSeries = map(series, updateRateCumSeries);
  chartInstance.setOption({ series: updatedSeries });
}

/**
 * Updates a single series item for RateCum highlighting.
 */
export function updateRateCumSeries(seriesItem) {
  return {
    ...seriesItem,
    z: 2,
    emphasis: {
      lineStyle: {
        opacity: 1,
        width: 6,
        color: seriesItem.lineStyle?.color
      }
    }
  };
}

/**
 * Iterates through all series indices and applies the provided dispatcher function.
 */
export function dispatchForAllSeries(
  dispatcher: (seriesIndex: number) => void,
  seriesCount: number
) {
  for (let seriesIndex = 0; seriesIndex < seriesCount; seriesIndex++) {
    dispatcher(seriesIndex);
  }
}

export function dispatchResetActionsForAllSeries(chartInstance, seriesCount: number) {
  const dispatchDownplay = dispatchAction(chartInstance, ChartActionType.Downplay);
  const dispatchHideTip = dispatchAction(chartInstance, ChartActionType.HideTip);
  dispatchForAllSeries(dispatchDownplay, seriesCount);
  dispatchForAllSeries(dispatchHideTip, seriesCount);
}

/**
 * Updates the emphasis of the target data point and dispatches a highlight action
 *
 * @param {EChartsType} chartInstance - chart instance to update
 * @param {number} seriesIndex - index of the target series
 * @param {number} dataIndex - index of the data point to isolate
 *
 */
export function updateTargetDataEmphasis(chartInstance, seriesIndex, dataIndex) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  const seriesArray = chartOptions.series;
  const targetSeries = seriesArray[seriesIndex];
  if (!targetSeries?.data) return;
  if (!targetSeries.data[dataIndex]) return;
  const [x, y] = targetSeries.data[dataIndex];

  chartInstance.setOption({
    graphic: {
      id: "target",
      invisible: false,
      type: "circle",
      silent: true,
      position: chartInstance.convertToPixel("grid", [x, y]),
      shape: { cx: 0, cy: 0, r: 4 },
      z: 3
    }
  });
}

/**
 * Updates the emphasis of the target series and dispatches a highlight action
 *
 * @param {EChartsType} chartInstance - chart instance to update
 * @param {number} seriesIndex - indices of the target series
 *
 */
export function updateTargetSeriesEmphasis(chartInstance, seriesIndex) {
  if (!chartInstance) return;

  const chartOptions = chartInstance.getOption();
  if (!chartOptions?.series) return;

  const seriesArray = chartOptions.series;
  // for each series, update the emphasis of the series if its index matches the target seriesIndex array
  const updatedSeries = seriesArray.map((series, idx) => {
    if (seriesIndex.includes(idx)) {
      return {
        ...series,
        z: 4,
        emphasis: {
          lineStyle: {
            opacity: 1,
            width: 6,
            color: "rgb(0,0,0)"
          }
        }
      };
    } else {
      return {
        ...series,
        z: 1,
        emphasis: {
          lineStyle: {
            opacity: 0.8,
            width: 4
          }
        }
      };
    }
  });

  chartOptions.series = updatedSeries;

  chartInstance.setOption(chartOptions);
}
