import { useMemo } from "react";
import { useSelector } from "react-redux";

import { EvaChart } from "constants/charts.enums";
import {
  selectChartAverageFeature,
  selectChartCutoffFeature,
  selectChartDebugFeature,
  selectChartFocusFeature,
  selectChartForecastFeature,
  selectChartIsProducingRateTypeFeature,
  selectChartLockUnitsFeature,
  selectChartMovingAverageDaysFeature,
  selectChartNormalizationFeature,
  selectChartP10Feature,
  selectChartP50Feature,
  selectChartP90Feature,
  selectChartProduct,
  selectChartShutInMonthsFeature,
  selectChartSourceFeature,
  selectChartSpaghettiFeature,
  selectChartSurvivorBiasFeature,
  selectChartTimeStepFeature,
  selectChartType,
  selectChartTypeWellsFeature,
  selectChartUseWeightedRatioAverageFeature
} from "store/features";
import { RootState } from "store/rootReducer";

export const useChartEntities = (id: string) => {
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const product = useSelector((state: RootState) => selectChartProduct(state, id));
  const sum = useSelector((state: RootState) =>
    selectChartSpaghettiFeature(state, id)
  )?.active;
  const forecast = useSelector((state: RootState) =>
    selectChartForecastFeature(state, id)
  )?.active;
  const source = useSelector((state: RootState) => selectChartSourceFeature(state, id))
    ?.properties?.value;
  const timeStep = useSelector((state: RootState) =>
    selectChartTimeStepFeature(state, id)
  )?.properties?.value;
  const isProducingRateType = useSelector((state: RootState) =>
    selectChartIsProducingRateTypeFeature(state, id)
  )?.active;
  const cutoff = useSelector((state: RootState) => selectChartCutoffFeature(state, id))
    ?.properties?.value;
  const shutInMonths = useSelector((state: RootState) =>
    selectChartShutInMonthsFeature(state, id)
  )?.properties?.value;
  const movingAverageDays = useSelector((state: RootState) =>
    selectChartMovingAverageDaysFeature(state, id)
  )?.properties?.value;
  const survivorBias = useSelector((state: RootState) =>
    selectChartSurvivorBiasFeature(state, id)
  )?.active;
  const useWeightedRatioAverage = useSelector((state: RootState) =>
    selectChartUseWeightedRatioAverageFeature(state, id)
  )?.active;
  const lockUnits = useSelector((state: RootState) =>
    selectChartLockUnitsFeature(state, id)
  )?.active;
  const debugState = useSelector((state: RootState) =>
    selectChartDebugFeature(state, id)
  );
  const typewells = useSelector((state: RootState) =>
    selectChartTypeWellsFeature(state, id)
  );
  const average = useSelector((state: RootState) => selectChartAverageFeature(state, id));
  const p10 = useSelector((state: RootState) => selectChartP10Feature(state, id));
  const p50 = useSelector((state: RootState) => selectChartP50Feature(state, id));
  const p90 = useSelector((state: RootState) => selectChartP90Feature(state, id));

  const normalizeBy = useSelector((state: RootState) =>
    selectChartNormalizationFeature(state, id)
  );

  const chartFocus = useSelector((state: RootState) =>
    selectChartFocusFeature(state, id)
  );

  return useMemo(
    () => ({
      chartType,
      product,
      sum,
      forecast,
      source,
      timeStep,
      isProducingRateType,
      cutoff,
      shutInMonths,
      movingAverageDays,
      survivorBias,
      useWeightedRatioAverage,
      lockUnits,
      debugState,
      typewells,
      average,
      p10,
      p50,
      p90,
      normalizeBy,
      chartFocus
    }),
    [
      chartType,
      product,
      sum,
      forecast,
      source,
      timeStep,
      isProducingRateType,
      cutoff,
      shutInMonths,
      movingAverageDays,
      survivorBias,
      useWeightedRatioAverage,
      lockUnits,
      debugState,
      typewells,
      average,
      p10,
      p50,
      p90,
      normalizeBy,
      chartFocus
    ]
  );
};

export type ChartEntities = ReturnType<typeof useChartEntities>;

export const DEFAULT_CHART_ENTITIES: ChartEntities = {
  chartType: EvaChart.None,
  sum: false,
  forecast: false,
  source: undefined,
  timeStep: undefined,
  isProducingRateType: false,
  cutoff: undefined,
  shutInMonths: undefined,
  movingAverageDays: undefined,
  survivorBias: false,
  useWeightedRatioAverage: false,
  lockUnits: false,
  debugState: undefined,
  typewells: undefined,
  average: undefined,
  p10: undefined,
  p50: undefined,
  p90: undefined,
  normalizeBy: undefined,
  chartFocus: undefined
};
