import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { updateChartByPath } from "store/features";

/**
 * Saves the chart bounds to context, syncs on resize
 * @param target reference of chart's wrapper element
 */
const useChartSize = (id, target: MutableRefObject<HTMLDivElement>): void => {
  const dispatch = useDispatch();

  // save
  useLayoutEffect(() => {
    if (!target.current) return;
    const next = target.current.getBoundingClientRect();
    dispatch(
      updateChartByPath({
        id,
        path: `bounds`,
        value: next
      })
    );
  }, [target]);

  // sync
  useResizeObserver(target, () => {
    if (!target.current) return;

    const next = target.current.getBoundingClientRect();
    dispatch(
      updateChartByPath({
        id,
        path: `bounds`,
        value: next
      })
    );
  });
};

export default useChartSize;
