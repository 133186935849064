import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewList } from "@material-ui/icons";
import { useChartsContext } from "contexts/ChartContext";
import { selectChartDataTableFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { ToolbarButton } from "components/base";

import { ChartSelector } from "./ChartSelector/ChartSelector";
import { ProductSelector } from "./ProductSelector/ProductSelector";

const ChartHeader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const dataTableStates = useSelector((state: RootState) =>
    selectChartDataTableFeature(state, id)
  );
  const isActive = dataTableStates.active;

  const onToggleDataTable = (visible: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.datatable.active`,
        value: visible
      })
    );
  };
  return (
    <Wrapper>
      <ToolbarButton
        style={{ marginLeft: "4px", marginRight: "4px" }}
        active={isActive}
        icon={<ViewList fontSize="large" />}
        tooltipText={dataTableStates.properties.tooltipText}
        overflowLabel={dataTableStates.properties.overflowLabel as string}
        onToggle={onToggleDataTable}
        disabled={false}
      />
      <Divider />

      <ChartSelector />
      <ProductSelector />
    </Wrapper>
  );
};

export default ChartHeader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;
