import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import tdistance from "@turf/distance";
import {
  LineString,
  Point,
  geometryCollection,
  point,
  polygon as tPolygon
} from "@turf/helpers";
import transformTranslate from "@turf/transform-translate";
import {
  WELL_LAYER,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL
} from "constants/mapLayers.constants";
import { setXdaLineRequest } from "store/features";
import { RootState } from "store/rootReducer";
import { Vector2 } from "three";

import { ToolbarButton } from "components/base";
import { useDashboardContext } from "components/dashboard/hooks/useDashboardContext";
import { useSelectedWellFromStore } from "components/data-analyzer/hooks";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import Line from "components/icons/Line";

import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { emptyFeatureCollection } from "../utils/emptyFeatureCollection";
import { getXdaLineNearCenterOfMap } from "../utils/getXdaLineNearCenterOfMap";
import {
  getPerpendicularLineAndMakeXdaRequest,
  makeXdaLineRequest
} from "../utils/xdaLineHelpers";

export interface XdaLineMarkerComponentModel {
  localState;
  selectionPolygonRef;
  getAndSetFeaturesIntersectingPolygon;
  drawRef;
}

export default function XdaLineMarker({
  localState,
  getAndSetFeaturesIntersectingPolygon,
  drawRef,
  selectionPolygonRef
}: XdaLineMarkerComponentModel) {
  const [isInVisDashboard, setIsInVisDashboard] = useState(false);
  const [defaultXdaLineDrawn, setDefaultXdaLineDrawn] = useState(false);
  const { mapbox, isMapInitialized } = useMapContext();
  const mapDispatch = useMapDispatch();
  const { isActive: isGeomBinOpen } = useGeomBinContext();
  const selectedWell = useSelectedWellFromStore();

  const selectedWellUwi = selectedWell?.Uwi;
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const dashboardRef = useRef({
    isInSingleDashboard: false,
    isInVisDashboard
  });
  useHotkeys(
    "esc",
    () => {
      if (!mapbox) {
        return;
      }
      clearXda();
    },
    {},
    [mapbox]
  );

  const { capabilities } = useDashboardContext();
  useEffect(() => {
    if (!capabilities) return;

    dashboardRef.current = {
      isInSingleDashboard: capabilities.hasSingleWellWidget,
      isInVisDashboard: capabilities.hasXdaWidget || capabilities.hasThreeDWidget
    };
    setIsInVisDashboard(dashboardRef.current.isInVisDashboard);
  }, [capabilities]);

  const xdaInterceptData = useSelector((root: RootState) => root.map.xdaInterceptData);
  const dispatch = useDispatch();
  const isLineSelectRef = useRef(false);

  const setLineSelect = (value) => {
    if (!drawRef.current) return;

    if (value) {
      drawRef.current.changeMode("two_point_line");
    } else {
      drawRef.current.deleteAll();
    }

    isLineSelectRef.current = value;
  };

  function clearXda() {
    clearXdaInterceptLine(mapbox);
    clearXdaLabel(mapbox);
    if (drawRef?.current) {
      drawRef.current.deleteAll();
    }
    mapDispatch({
      payload: {
        selectedFeatures: []
      }
    });
    mapDispatch({
      payload: {
        highlightedFeatures: { line: [], point: [], facility: [] }
      }
    });
  }

  function clearXdaInterceptLine(mapbox) {
    if (!mapbox) {
      return;
    }
    const xda = mapbox.getSource(XDA_INTERCEPT_LAYER);
    if (!xda) {
      return;
    }
    xda.setData(emptyFeatureCollection);
  }

  function clearXdaLabel(mapbox) {
    if (!mapbox) {
      return;
    }
    const label = mapbox.getSource(XDA_LABEL);
    if (!label) {
      return;
    }
    label.setData(emptyFeatureCollection);
  }

  useEffect(() => {
    if (localState.current.xdaLineMoved) {
      // This selected well uwi was changed following a line move event
      // since the xda line was already triggered by the line move event
      // skip it here
      localState.current.xdaLineMoved = false;
      return;
    }

    if (defaultXdaLineDrawn) {
      // We don't want to draw a new line if the default line has already been drawn
      setDefaultXdaLineDrawn(false);
      return;
    }

    if (
      !selectedWellUwi ||
      !mapbox ||
      isLineSelectRef.current ||
      (!dashboardRef.current.isInSingleDashboard &&
        !dashboardRef.current.isInVisDashboard)
    ) {
      //only register click if dashboard has vis or single well
      return;
    }
    if (!mapbox.getLayer(WELL_LAYER)) {
      return;
    }

    const selectedWellIds = {};
    selectedWellIds[selectedWellUwi] = true;

    // get feature of selected well
    const features = mapbox.querySourceFeatures(WELL_LAYER, {
      sourceLayer: "eva-wells",
      filter: ["has", ["get", "Uwi"], ["literal", selectedWellIds]]
    });

    if (features.length === 0 || !features[0]) {
      return;
    }

    const feat = features[0];
    if (!feat) {
      return;
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      getPerpendicularLineAndMakeXdaRequest(
        selectedWellUwi,
        // We need to pass in the selected feature, otherwise the line will be drawn for all nearby wells
        feat,
        mapbox,
        dispatch,
        selectionPolygonRef,
        getAndSetFeaturesIntersectingPolygon
      );
    }, 100); // 100 ms debounce
  }, [selectedWellUwi]);

  useEffect(() => {
    // An edge case, this prevents the xda line from snapping to it's default position while moving,
    // when xdaInterceptData is changed, this can happen when user zooms in/out, or moves the map
    if (localState.current.isXdaLineMoving) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = xdaInterceptData;
    if (!mapbox || !xdaInterceptData || data.line.length === 0) {
      return;
    }

    const line = {
      type: "LineString",
      coordinates: [
        [data.line.start.x, data.line.start.y],
        [data.line.end.x, data.line.end.y]
      ]
    } as LineString;

    const geoms = xdaInterceptData.points.map((p) => {
      return { type: "Point", coordinates: [p.x, p.y] };
    }) as Point[];

    const gcoll = geometryCollection(geoms);
    gcoll.geometry.geometries.push(line);
    const source = mapbox.getSource(XDA_INTERCEPT_LAYER) as mapboxgl.GeoJSONSource;
    if (!source) {
      return;
    }
    localState.current.xdaInterceptLine = gcoll;
    source.setData(gcoll);
  }, [xdaInterceptData]);

  useEffect(() => {
    function onMouseMove(e) {
      const coords = e.lngLat;
      const prevMouseCoordinate = localState.current.currentMouseCoordinate;

      if (localState.current.xdaInterceptLine) {
        const from = point([prevMouseCoordinate.lng, prevMouseCoordinate.lat]);
        const to = point([coords.lng, coords.lat]);

        //angle from north in axis degree
        let angle =
          new Vector2(
            coords.lng - prevMouseCoordinate.lng,
            coords.lat - prevMouseCoordinate.lat
          ).angle() *
          (180 / Math.PI);
        angle = 360 - (angle - 90);
        const distance = tdistance(from, to, { units: "kilometers" });
        transformTranslate(localState.current.xdaInterceptLine, distance, angle, {
          mutate: true
        });
        const source = mapbox.getSource(XDA_INTERCEPT_LAYER) as mapboxgl.GeoJSONSource;
        source.setData(localState.current.xdaInterceptLine);
        localState.current.currentMouseCoordinate = coords;
      }
    }

    async function onDrawEnd(e) {
      //calls when finished drawing
      if (e.features.length > 0) {
        const feature = e.features[0];

        const geom = feature.geometry;
        if (geom.type !== "LineString") {
          return;
        }
        //convert linestring to polygon
        await makeXdaLineRequest(
          geom,
          {},
          mapbox,
          dispatch,
          selectionPolygonRef,
          getAndSetFeaturesIntersectingPolygon
        );
        if (drawRef?.current) {
          drawRef.current.deleteAll();
        }
        setLineSelect(false);
      }
    }

    if (!mapbox) {
      return;
    }

    function onMouseUp() {
      localState.current.isXdaLineMoving = false;

      //get centroid of line and pass it to xda viewer
      const geom = localState.current.xdaInterceptLine;
      if (!geom || !geom.geometry || !geom.geometry.geometries) {
        return;
      }
      const line = geom.geometry.geometries.filter((x) => x.type === "LineString");
      if (!line) {
        return;
      }

      mapbox.off("mousemove", onMouseMove);
      mapbox.off("mouseup", onMouseUp);
      if (localState.current.xdaLineMoved) {
        setTimeout(
          async () =>
            await makeXdaLineRequest(
              line[0],
              {},
              mapbox,
              dispatch,
              selectionPolygonRef,
              getAndSetFeaturesIntersectingPolygon
            ),
          500
        );
      }
    }

    mapbox.on("mousedown", XDA_INTERCEPT_LAYER, (e) => {
      localState.current.isXdaLineMoving = true;

      e.preventDefault();

      const state = localState.current;
      if (!state) {
        return;
      }
      const coords = e.lngLat;
      state.currentMouseCoordinate = coords;
      state.xdaLineMoved = true;

      mapbox.on("mousemove", onMouseMove);
      mapbox.on("mouseup", onMouseUp);
    });

    mapbox.on("draw.create", onDrawEnd);

    return () => {
      drawRef.current = undefined;
    };
  }, [drawRef, localState, mapbox]);

  async function setDefaultXdaLine() {
    //is vis dash so we want to draw xda line at random well near center of screen
    if (!mapbox) {
      return;
    }

    const { line } = await getXdaLineNearCenterOfMap(mapbox);

    if (!line) {
      return;
    }

    // send notification on the default xda line was being drawn
    setDefaultXdaLineDrawn(true);

    const poly = tPolygon([
      [
        [line.start.x, line.start.y],
        [line.end.x, line.end.y],
        [line.end.x + 0.0001, line.end.y + 0.0001],
        [line.start.x, line.start.y]
      ]
    ]);
    const [selectedWells] = await getAndSetFeaturesIntersectingPolygon(poly);

    dispatch(
      setXdaLineRequest({
        line: [
          [line.start.x, line.start.y],
          [line.end.x, line.end.y]
        ],
        wells: selectedWells
      })
    );
  }

  useEffect(() => {
    async function setInitialXda() {
      let count = 0;
      while ((!mapbox || !mapbox.areTilesLoaded) && count < 5) {
        await new Promise((r) => setTimeout(r, 1000));
        count++;
      }
      setDefaultXdaLine();
    }

    if (isInVisDashboard && isMapInitialized) {
      if (!dashboardRef.current.isInSingleDashboard) {
        // stop drawing default xda line if in single dashboard
        setInitialXda();
      }
    } else {
      clearXda();
    }
  }, [isInVisDashboard, dashboardRef.current, isMapInitialized]);

  if (!isInVisDashboard || !mapbox || isGeomBinOpen) {
    return <></>;
  }
  return (
    <ToolbarButton
      active={isLineSelectRef.current}
      disabled={drawRef?.current === undefined}
      icon={<Line />}
      onToggle={setLineSelect}
      tooltipText="Toggle Line Selection"
      styleKey="mapToolbar"
    />
  );
}
