import { EvaChart } from "constants/charts.enums";
import { LassoSelectionStates } from "types/lasso";

import { getCrossPlotList } from "./crossPlot";
import { getCumTimeList } from "./cumTime";
import { getRateCumList } from "./rateCum";
import { getRateTimeList } from "./rateTime";

export const LASSO_MAP = {
  [EvaChart.RateCum]: getRateCumList,
  [EvaChart.CrossPlot]: getCrossPlotList,
  [EvaChart.CumTime]: getCumTimeList,
  [EvaChart.RateTime]: getRateTimeList
};

export function getBrushSelectList({
  params,
  chartType,
  series,
  uwiList = []
}: LassoSelectionStates): string[] {
  const list = LASSO_MAP[chartType];
  if (!list) {
    throw new Error(`Unknown chart type: ${chartType} to get brush select list`);
  }
  return list({ params, series, uwiList });
}
