import { useCallback, useEffect, useState } from "react";

import { ALL_PRODUCT_TYPES } from "constants/chart.constants";

import { IProduct } from "../models/shared.models";

const getProductUnitType = (product: string) => {
  const p = Object.values(ALL_PRODUCT_TYPES).find((o) => o.label === product);
  return p?.unit || "";
};

export default function useAxisUnitLimit(products: IProduct[]) {
  const MAX_UNITS_PER_CATEGORY = 2;
  const [primaryUnits, setPrimaryUnits] = useState<Set<string>>(new Set());
  const [secondaryUnits, setSecondaryUnits] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (products && products.length > 0) {
      const primaryProducts = products.filter((p) => p.axis === "primary");
      const secondaryProducts = products.filter((p) => p.axis === "secondary");

      setPrimaryUnits(new Set(primaryProducts.map((p) => getProductUnitType(p.name))));
      setSecondaryUnits(
        new Set(secondaryProducts.map((p) => getProductUnitType(p.name)))
      );
    } else {
      setPrimaryUnits(new Set());
      setSecondaryUnits(new Set());
    }
  }, [products]);

  const canAddProduct = useCallback(
    (newProduct: string, axis: "primary" | "secondary") => {
      const newProductUnit = getProductUnitType(newProduct);
      const targetUnits = axis === "primary" ? primaryUnits : secondaryUnits;

      if (targetUnits.has(newProductUnit)) {
        return true;
      }

      return targetUnits.size < MAX_UNITS_PER_CATEGORY;
    },
    [primaryUnits, secondaryUnits]
  );

  return {
    canAddProduct,
    primaryUnits,
    secondaryUnits,
    primaryUnitCount: primaryUnits.size,
    secondaryUnitCount: secondaryUnits.size
  };
}
