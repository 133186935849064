import { ProductKind } from "constants/chart.constants";

export function getYieldsToggleLabels(selectedProductGroup: number): {
  rawLabel: string;
  salesLabel: string;
} {
  if (
    selectedProductGroup === ProductKind.Throughput ||
    selectedProductGroup === ProductKind.Shrinkage
  ) {
    return { rawLabel: "Inlet", salesLabel: "Outlet" };
  } else {
    return { rawLabel: "Raw", salesLabel: "Sales" };
  }
}
