import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BLACK_HEX, DEFAULT_LINE_THICKNESS } from "constants/chart.constants";
import { Generators, createEntityState } from "entities/charts/factory";
import { selectOverrideSizeLimit, updateChart } from "store/features/charts/chartSlice";
import { ChartRequestType } from "types";
import { getProductType } from "utils";

import { usePostChartData } from "api/charts/usePostChartData";

import { EntityKind } from "models/entityKind";
import { ResultMessageType } from "models/model";

import handleMissingChartDataToasts from "../../components/charts/utils/handleMissingChartDataToasts";
import { sanitizeBinValues } from "./proxies";
import { useChartDependencies } from "./useChartDependencies";
import { useChartEntities } from "./useChartEntities";

export interface ChartDataControllerProps {
  id: string;
}

/**
 * Hook to manage chart data fetching based on current chart states and dependencies
 */
export function useChartDataController({ id }: ChartDataControllerProps) {
  const overrideSizeLimit = useSelector(selectOverrideSizeLimit);

  const {
    filterId,
    globalNormalizeBy,
    useNormalizeBy,
    activeColorPalette,
    groupBy,
    lockedColors
  } = useChartDependencies(EntityKind.Well);
  const dispatch = useDispatch();

  const {
    chartType,
    product,
    sum,
    forecast,
    source,
    timeStep,
    isProducingRateType,
    cutoff,
    shutInMonths,
    movingAverageDays,
    survivorBias,
    useWeightedRatioAverage,
    lockUnits,
    average,
    p10,
    p50,
    p90,
    normalizeBy,
    chartFocus
  } = useChartEntities(id);

  const getGroupBySetting = () => {
    const chartFocusGroupBy = chartFocus?.properties?.groupBy;
    return chartFocus?.active
      ? {
          ...chartFocusGroupBy,
          bin: {
            ...chartFocusGroupBy.bin,
            ...sanitizeBinValues(chartFocusGroupBy.bin)
          }
        }
      : { ...groupBy, bin: { ...groupBy.bin, ...sanitizeBinValues(groupBy.bin) } };
  };

  const request = useMemo(() => {
    if (!chartType) return null;
    return createEntityState(chartType as Generators, {
      FilterId: filterId.id,
      NormalizeBy: globalNormalizeBy,
      UseNormalizeBy: useNormalizeBy,
      ColorPalette: chartFocus?.active
        ? chartFocus?.properties?.colorPalette
        : activeColorPalette,
      chartSetting: {
        product: getProductType(product)?.key, // Need to convert display name to key, ie "Oil + Cond" to "Oil"
        showIndividualSeries: sum,
        showForecast: forecast,
        source,
        timeStep,
        pdenDataSourceSetting: {
          source,
          timeStep
        },
        rateType: isProducingRateType ? 1 : 0,
        cutoff,
        shutInMonths,
        movingAverageDays,
        survivorBias,
        useWeightedRatioAverage,
        lockUnits,
        normalizeBy: normalizeBy.properties,
        showAverage: average.active,
        averageSettings: {
          color: average.properties?.color || BLACK_HEX,
          thickness: average.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP10: p10.active,
        p10Settings: {
          color: p10.properties?.color || BLACK_HEX,
          thickness: p10.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP50: p50.active,
        p50Settings: {
          color: p50.properties?.color || BLACK_HEX,
          thickness: p50.properties?.thickness || DEFAULT_LINE_THICKNESS
        },
        showP90: p90.active,
        p90Settings: {
          color: p90.properties?.color || BLACK_HEX,
          thickness: p90.properties?.thickness || DEFAULT_LINE_THICKNESS
        }
      },
      GroupBy: getGroupBySetting(),
      colorLockedItems: lockedColors,
      overrideSizeLimit
    }) as ChartRequestType;
  }, [
    chartType,
    product,
    filterId.lastUpdated,
    globalNormalizeBy,
    useNormalizeBy,
    activeColorPalette,
    forecast,
    source,
    sum,
    timeStep,
    isProducingRateType,
    cutoff,
    shutInMonths,
    movingAverageDays,
    survivorBias,
    useWeightedRatioAverage,
    lockUnits,
    groupBy,
    lockedColors,
    overrideSizeLimit,
    average,
    p10,
    p50,
    p90,
    normalizeBy,
    chartFocus
  ]);

  const { data: apiResponse, loading, error } = usePostChartData(request);
  const prevMessageTypeRef = useRef<ResultMessageType | null>(null);

  useEffect(() => {
    if (
      apiResponse &&
      (apiResponse.messageType === ResultMessageType.Ok ||
        apiResponse.messageType === ResultMessageType.NoData ||
        apiResponse.messageType === ResultMessageType.MissingNormalizeData)
    ) {
      if (
        apiResponse.messageType === ResultMessageType.MissingNormalizeData &&
        prevMessageTypeRef.current !== ResultMessageType.MissingNormalizeData
      ) {
        handleMissingChartDataToasts(apiResponse, id);
      }
      prevMessageTypeRef.current = apiResponse.messageType;

      dispatch(
        updateChart({
          id,
          chartType: chartType,
          product,
          request,
          response: apiResponse
        })
      );
    }
  }, [apiResponse]);

  return { loading, error, apiResponse };
}
