import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { difference } from "lodash";
import {
  addMultipleLegendItemsToFilter,
  filterWellsFromList,
  selectChartLassoList,
  selectChartResponse,
  selectChartType,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { FilterMinus } from "components/icons";
import FilterPlusIcon from "components/icons/FilterPlus";

const AddToFilterButton = () => {
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const chartResponse = useSelector((state: RootState) => selectChartResponse(state, id));
  const property = useSelector(
    (state: RootState) => state.groupBy?.globalGroupBy?.property ?? ""
  );

  const brushedList =
    useSelector((state: RootState) => selectChartLassoList(state, id)) ?? [];

  function inactiveLasso() {
    dispatch(
      updateChartByPath({
        id,
        path: `features.lasso.active`,
        value: false
      })
    );
  }

  const handleAddToFilter = () => {
    const list = [...brushedList];
    if (chartType === EvaChart.CrossPlot) {
      dispatch(filterWellsFromList(list));
    } else {
      dispatch(addMultipleLegendItemsToFilter(list, false, property));
    }

    inactiveLasso();
  };

  const handleExcludeFromFilter = () => {
    if (chartType === EvaChart.CrossPlot) {
      const filteredList = difference(chartResponse.uwis, brushedList);
      const list = [...filteredList];
      dispatch(filterWellsFromList(list));
    } else {
      dispatch(addMultipleLegendItemsToFilter(brushedList, true, property));
    }
    inactiveLasso();
  };

  return (
    <>
      <ButtonContainer>
        <StyledButton type="text" onClick={handleAddToFilter}>
          <FilterPlusIcon />
          Add to filter
        </StyledButton>
        <StyledButton type="text" onClick={handleExcludeFromFilter}>
          <FilterMinus />
          Exclude from filter
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

export default memo(AddToFilterButton);

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
