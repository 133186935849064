import { memo, useState } from "react";
import { useSelector } from "react-redux";

import { Popover } from "antd";

import { GroupBy as GroupByIcon } from "components/icons";

import { useChartsContext } from "../../../../contexts/ChartContext";
import { selectChartFocusFeature } from "../../../../store/features";
import { RootState } from "../../../../store/rootReducer";
import ToolbarButton from "../../../base/ToolbarButton";
import ChartFocusSettings from "./ChartFocusSettings";

const ChartFocusToggle = () => {
  const { id } = useChartsContext();
  const isActive = useSelector(
    (state: RootState) => selectChartFocusFeature(state, id)?.active
  );
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      placement="bottomRight"
      arrowPointAtCenter
      content={<ChartFocusSettings />}
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        data-testid={isActive ? "chartFocusToggleActive" : "chartFocusToggleInactive"}
        icon={<GroupByIcon size={18} />}
        isMenuButton={true}
        overflowLabel="Chart Focus"
        tooltipText="Chart Focus"
        active={isActive}
      />
    </Popover>
  );
};

export default memo(ChartFocusToggle);
