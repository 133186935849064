import { useQuery } from "react-query";

import useBetaFeatures from "hooks/useBetaFeatures";

import { fetchUserDataSources } from "api/dataSource";

import { MCDANIEL_NAME, WORKING_FORECAST_FOLDER_NAME } from "../../../constants";
import { arrayOrEmpty } from "../../../utils";

interface UseForecastPreferenceQueryParamsT {
  onSuccess?: (data) => void;
  onError?: (error) => void;
  isAutoRefetching?: boolean;
  staleTime?: number;
}

interface UseForecastPreferenceQueryReturnT {
  data;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export default function useForecastPreferenceQuery(
  params: UseForecastPreferenceQueryParamsT = {}
): UseForecastPreferenceQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true, staleTime } = params;
  const { hasFeature } = useBetaFeatures();

  const queryInfo = useQuery({
    queryKey: "dataSources",
    queryFn: async () => {
      const response = await fetchUserDataSources();
      if (response.ok) {
        const forecast = response.value.filter((ds) => ds.category === "ForecastData");
        if (forecast.length == 1) {
          return forecast[0].dataSources;
        }
      }
      return null;
    },
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching,
    staleTime: staleTime
  });

  let data = queryInfo.data;
  if (!data || !Array.isArray(data)) {
    data = [];
  }
  if (
    hasFeature("Forecasting") &&
    data.findIndex((item) => item.dataSourceName === WORKING_FORECAST_FOLDER_NAME) < 0
  ) {
    const idx = arrayOrEmpty(data).findIndex(
      (item) => item.dataSourceName === MCDANIEL_NAME
    );
    const workingFolder = {
      id: WORKING_FORECAST_FOLDER_NAME,
      dataSourceName: WORKING_FORECAST_FOLDER_NAME,
      isActive: true,
      order: 1.5,
      source: WORKING_FORECAST_FOLDER_NAME
    };
    if (idx >= 0) {
      const mcdaniel = data[idx];
      workingFolder.order = mcdaniel.order + 0.5;
      data.splice(idx, 0, workingFolder);
    } else {
      data.push(workingFolder);
    }
  }
  return {
    data: data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
