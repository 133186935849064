import { EvaChartFeatures } from "types";

export function extractActiveAndProperties(features: Partial<EvaChartFeatures>) {
  return Object.keys(features).reduce((acc, key) => {
    const feature = features[key] || {};
    acc[key] = {
      properties: feature.properties || {},
      active: feature.active || false
    };
    return acc;
  }, {});
}
