import { useQuery } from "react-query";

import axios from "axios";

export type ProjectLayerType =
  | "Well"
  | "Facility"
  | "Completion"
  | "Grid"
  | "Base Layer"
  | "shapefile"
  | "Shapefile Properties"
  // Referring to an organization shapefile
  | "Organization"
  // Referring to a System/McDaniel shapefile
  | "McDaniel"
  | "geomBin"
  | "Facility"
  | "Facility Label";

export interface IProjectLayer {
  Color: string | null;
  layerId: string;
  name: string;
  opacity?: number;
  order: number;
  projectLayerType: ProjectLayerType;
  properties?: string[];
  shapefileId: string;
  projectShapefileId?: string;
  StrokeColor: string | null;
  Thickness: number | null;
  //TODO BF: assign a type to this 'organization' | etc
  visbility: string;
  geomBinId?: string;
}

export const PROJECT_LAYER_LIST_QUERY_KEYS = {
  currentProjectLayerList: "currentProjectLayerList"
};

export type UseProjectLayerListQueryKeysT = keyof typeof PROJECT_LAYER_LIST_QUERY_KEYS;

export type UseProjectLayerListQueryParamsT = {
  projectId: string;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  select?;
  queryKey?: UseProjectLayerListQueryKeysT;
  isAutoRefetching?: boolean;
};

export type UseProjectLayerListQueryReturnT = {
  data: IProjectLayer[];
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
};

export const fetchProjectLayerList = async ({ queryKey }) => {
  const [, projectId] = queryKey;
  try {
    return await axios.get(
      `${process.env.REACT_APP_PROJECT_SERVICE}/project/projectLayers/${projectId}`
    );
  } catch (error) {
    throw new error("Error fetching project layer list");
  }
};

export function useProjectLayerListQuery(
  params: UseProjectLayerListQueryParamsT
): UseProjectLayerListQueryReturnT {
  const {
    isAutoRefetching,
    onError,
    onSuccess,
    projectId,
    queryKey = "currentProjectLayerList",
    select
  } = params;

  const nextIsAutoFetching =
    isAutoRefetching !== undefined ? isAutoRefetching : !(params.projectId === undefined);

  const queryInfo = useQuery({
    queryKey: [queryKey, projectId],
    queryFn: fetchProjectLayerList,
    select,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: nextIsAutoFetching
  });

  return {
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch,
    data: queryInfo.data?.data
  };
}
