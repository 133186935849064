const isNextIndexANumber = (data, index) => {
  if (index + 1 < data.length) {
    const nextItem = data[index + 1];
    return nextItem !== 0 && !isNaN(nextItem) && typeof nextItem === "number";
  } else {
    return false;
  }
};

export default isNextIndexANumber;
