import { PropsWithChildren, createContext, useContext, useState } from "react";

export enum ChartStatus {
  Loading = "loading",
  Error = "error",
  Idle = "idle"
}

interface ChartContextProps {
  id: string;
  status: ChartStatus;
  setStatus: (newStatus: ChartStatus) => void;
}

export const ChartContext = createContext<ChartContextProps | undefined>(undefined);

export const useChartsContext = () => {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error("useChartContext must be used within a ChartProvider");
  }
  return context;
};

interface ChartProviderProps {
  id: string;
}

export const ChartsProvider = ({
  id,
  children
}: PropsWithChildren<ChartProviderProps>) => {
  const [status, setStatus] = useState(ChartStatus.Idle);
  return (
    <ChartContext.Provider value={{ id, status, setStatus }}>
      {children}
    </ChartContext.Provider>
  );
};
