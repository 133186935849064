import { EvaChart } from "constants/charts.enums";
import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { translateCrossPlotTooltipOptions } from "./crossPlot";
import { translateCumTimeResponseTooltipOptions } from "./cumTime";
import { translateRateCumResponseTooltipOptions } from "./rateCum";
import { translateRateTimeResponseTooltipOptions } from "./rateTime";

export const TOOLTIP_CREATORS_MAP = {
  [EvaChart.RateCum]: translateRateCumResponseTooltipOptions,
  [EvaChart.CrossPlot]: translateCrossPlotTooltipOptions,
  [EvaChart.CumTime]: translateCumTimeResponseTooltipOptions,
  [EvaChart.RateTime]: translateRateTimeResponseTooltipOptions
};

export function translateTooltipOptions({
  type,
  states
}: {
  type: EvaChart;
  states: Partial<EvaChartStates>;
}): TooltipOption {
  const tooltipOptions = TOOLTIP_CREATORS_MAP[type];
  if (!tooltipOptions) {
    throw new Error(`Unknown chart type: ${type} to create tooltip options`);
  }
  return tooltipOptions(states);
}
