import axios from "axios";

import { AddChartPresetModel, IChartResult, UpdateChartPresetModel } from "models/model";

import { IMultiPhaseInput } from "components/multiphase-chart/models/shared.models";

const CHART_URI = process.env.REACT_APP_CHART_SERVICE;

const deleteChart = async (chartId: string) => {
  return await axios.delete(`${CHART_URI}/chart/${chartId}`);
};

const getPresets = async () => {
  const response = await axios.get(`${CHART_URI}/chart/multi-phase-presets`);
  return response.data;
};

const addNewChartPreset = async (payload: AddChartPresetModel) => {
  return await axios.post(`${CHART_URI}/chart/multi-phase-presets`, {
    id: payload.id,
    name: payload.name,
    products: payload.products,
    chartType: payload.chartType,
    rateType: "Calendar", // payload.rateType,
    mBblPosition: payload.mBblPosition,
    mMcfPosition: payload.mMcfPosition,
    roleType: payload.roleType,
    style: payload.style,
    combineSameUnitAxis: payload.combineSameUnitAxis
  });
};

const updateChart = async (payload: UpdateChartPresetModel) => {
  return await axios.put(`${CHART_URI}/chart/${payload.id}`, {
    name: payload.name,
    products: payload.products,
    chartType: payload.chartType,
    rateType: "Calendar", // payload.rateType,
    mBblPosition: payload.mBblPosition,
    mMcfPosition: payload.mMcfPosition,
    roleType: "Organization",
    style: payload.style,
    combineSameUnitAxis: payload.combineSameUnitAxis
  });
};

const deleteChartPreset = async (payload: AddChartPresetModel) => {
  return await axios.delete(
    `${CHART_URI}/chart/multi-phase-presets/${payload.id}/${payload.ownerId}/${payload.roleType}`
  );
};

const postMultiPhase = async (data: IMultiPhaseInput): Promise<IChartResult> => {
  const response = await axios.post<IChartResult>(`${CHART_URI}/chart/multi-phase`, data);
  if (response.status !== 200) {
    throw "error occurred while fetching chart data";
  }
  return response.data;
};

export {
  postMultiPhase,
  deleteChartPreset,
  updateChart,
  addNewChartPreset,
  getPresets,
  deleteChart
};
