import { getProductType } from "utils";

import { ProductKind } from "../../constants";

export const getProductTooltip = (label: string, productGroup: number): string => {
  if (
    productGroup === ProductKind["Throughput"] ||
    productGroup === ProductKind["Shrinkage"]
  ) {
    const productType = getProductType(label);
    return productType?.tooltip ?? "";
  }
};

export default getProductTooltip;
