import {
  ChartProduct,
  InjectionProduct,
  SalesProduct,
  ShrinkageProduct,
  SummaryProduct,
  ThroughputProduct,
  rateDateCategories
} from "constants/chart.constants";
import { isLabelInCategory } from "utils/chart/isLabelInCategory";

export const checkIsProductInRateDateCategory = (product: string) => {
  const productConstants = [
    ChartProduct,
    SummaryProduct,
    SalesProduct,
    InjectionProduct,
    ShrinkageProduct,
    ThroughputProduct
  ];

  return productConstants.some((productConstant) =>
    rateDateCategories.some((category) =>
      isLabelInCategory(productConstant, category, product)
    )
  );
};
