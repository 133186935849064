import { MCDANIEL_SEARCH_FOLDER_NAME, SYNC_KEY } from "constants/settings.constants";

import { isSyncFolder } from "components/sync/util";

import { DataSourceSetting } from "../../components/data-table/DataTableActions";

const getPropertiesWithForecast = (
  properties: string[],
  dataSources: DataSourceSetting
) => {
  if (dataSources.forecastSource !== MCDANIEL_SEARCH_FOLDER_NAME) {
    return properties.map((prop) => {
      if (isSyncFolder(dataSources.forecastSource) && prop.startsWith("Performance")) {
        return prop + `.${SYNC_KEY}.${dataSources.forecastSource}`;
      }
      if (prop.startsWith("Performance.")) {
        return prop + `.${dataSources.forecastSource}.${dataSources.rescat}`;
      }
      if (prop.startsWith("Forecast.")) {
        return prop + `.fcst.${dataSources.forecastSource}.${dataSources.rescat}`;
      }
      return prop;
    });
  } else {
    return properties;
  }
};

export default getPropertiesWithForecast;
