import { memo } from "react";

function TotalOutlet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
      fill="currentColor"
      transform="scale(1.35) translate(2, 0)"
      height="40"
      width="40">
      <g transform="scale(0.65) translate(4.5, 1.8)">
        {/* fire */}
        <path
          fill="currentColor"
          transform="translate(10.8,9.6) scale(0.5)"
          d="M13.8165 11.8429C13.5771 7.9784 6.80651 0 6.80651 0C5.27508 6.87459 -0.00611427 7.65789 5.31389e-06 12.1833C0.00383005 15.237 1.38839 17.0101 3.11564 17.9518C2.5687 16.1228 2.79053 14.0001 4.43288 12.4656C7.46513 9.63222 7.79865 7.72674 7.79865 7.72674C10.9357 13.2963 10.1662 16.796 8.98432 18.5607C11.8621 17.7659 14.036 15.3808 13.8165 11.8429"
        />
        {/* circle */}
        <path
          transform="rotate(180, 12, 12) scale(0.8)"
          d="M22 12A10 10 0 0 1 2.46 15H4.59A8 8 0 1 0 4.59 9H2.46A10 10 0 0 1 22 12Z"
        />

        {/* arrow */}
        <path
          transform="scale(0.4) translate(47, 24)"
          d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z"
        />

        {/* dashed box */}
      </g>
      <g>
        <path
          d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
          fill="none"
          transform="translate(1.5,8) scale(0.75)"
          stroke="currentColor"
          strokeWidth="1.25"
        />
      </g>
    </svg>
  );
}

export default memo(TotalOutlet);
