export function calculateTooltipPosition(
  point: number[],
  tooltipDom: HTMLDivElement,
  margin: number = 10
): Point2 {
  const tooltipWidth = tooltipDom.offsetWidth;
  const tooltipHeight = tooltipDom.offsetHeight;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  let xPos = point[0] - tooltipWidth;
  let yPos: number;

  // Vertically: if the point is too close to the top, place tooltip below; otherwise, above.
  if (point[1] < tooltipHeight + margin) {
    yPos = point[1] + margin;
  } else {
    yPos = point[1] - tooltipHeight - margin;
  }

  // When point is near the right edge, shift tooltip so its right edge flushes with the viewport.
  if (point[0] > viewportWidth - tooltipWidth / 2 - margin) {
    xPos = viewportWidth - tooltipWidth - margin;
  }

  // Adjust horizontal position if tooltip would overflow left.
  if (xPos < margin) {
    xPos = margin;
  }
  // Ensure it stays within the right boundary.
  if (xPos + tooltipWidth > viewportWidth - margin) {
    xPos = viewportWidth - tooltipWidth - margin;
  }

  // Adjust vertical position if tooltip would overflow.
  if (yPos < margin) {
    yPos = margin;
  } else if (yPos + tooltipHeight > viewportHeight - margin) {
    yPos = viewportHeight - tooltipHeight - margin;
  }

  return [xPos, yPos];
}
