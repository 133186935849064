import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChartStatus, useChartsContext } from "contexts/ChartContext";
import { selectChartLassoFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";
import { allTrue, anyTrue } from "utils/fp";

import LassoIcon from "components/icons/Lasso";

import ToolbarButton from "../../../base/ToolbarButton";

export const LASSO_SELECTION_LABEL = "Lasso Selection";

function LassoBrush() {
  const { id, status } = useChartsContext();
  const dispatch = useDispatch();
  const lassoStates = useSelector((state: RootState) =>
    selectChartLassoFeature(state, id)
  );
  const isFiltering = useSelector((state: RootState) => state.filter.isFiltering);

  const isActive = allTrue(lassoStates.active);
  const isDisabled = anyTrue(
    !lassoStates.enabled,
    isFiltering,
    status === ChartStatus.Loading
  );
  const handleLassoToggle = (visible: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.lasso.active`,
        value: visible
      })
    );
  };

  useEffect(() => {
    if (status === ChartStatus.Loading || status === ChartStatus.Error) {
      handleLassoToggle(false);
    }
  }, [status]);

  return (
    <ToolbarButton
      icon={<LassoIcon />}
      active={isActive}
      disabled={isDisabled}
      overflowLabel={LASSO_SELECTION_LABEL}
      tooltipText={LASSO_SELECTION_LABEL}
      onToggle={handleLassoToggle}
    />
  );
}

export default memo(LassoBrush);
