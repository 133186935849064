import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { getPrecision, getSeriesTooltipDot, getUnit } from "components/chart/utils";

export function translateCumTimeResponseTooltipOptions(
  states: EvaChartStates
): TooltipOption {
  const { layout } = states;

  const yAxisTitle = layout?.yAxis?.title || "";
  return {
    trigger: "axis",
    confine: true,
    enterable: true,
    extraCssText: "border-style:none;",
    showDelay: 500,
    hideDelay: 0,
    show: true,
    axisPointer: {
      label: {
        precision: 2,
        show: true
      },
      type: "cross"
    },

    position: function (point, _, dom) {
      const xPos = point[0];
      const yPos =
        point[1] - (dom as HTMLDivElement).offsetHeight > 48
          ? point[1] - (dom as HTMLDivElement).offsetHeight
          : 48;
      return [xPos, yPos]; //position top right of mouse cursor
    },
    formatter: (items) => {
      if (items && items.length > 0) {
        const header = `<table>`;
        const footer = `</table>`;
        let content = "";
        items
          .filter((item) => item.value !== "")
          .sort((a, b) =>
            (Array.isArray(a.value) ? a.value[1] : a.value) >
            (Array.isArray(b.value) ? b.value[1] : b.value)
              ? -1
              : 1
          )
          .forEach((item) => {
            const value = Array.isArray(item.value) ? item.value[1] : item.value;
            if (typeof value !== "number" || isNaN(value)) {
              return;
            }
            content += `<tr style="text-align:left;">
                          <td>${getSeriesTooltipDot(item.color)}</td>
                          <td>${item.seriesName}</td>
                          <td><span style="font-weight:700;color:#666;padding-left:10px;float:right;text-align:right;font-family: var(--fontMono);">${
                            item.seriesName !== "Well Count" &&
                            item.seriesName !== "Facility Count"
                              ? value.toFixed(getPrecision(getUnit(yAxisTitle)))
                              : value
                          }</span></td>
                          </tr>`;
          });
        return `${header}${content}${footer}`;
      }
      return "";
    }
  };
}
