// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { mdiChartAreaspline, mdiChartLine } from "@mdi/js";
import { Tooltip } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { createFeatureEntities } from "entities/charts/eva/features";
import { selectChartType, updateChart } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { getChartType, noop } from "utils";

import { BaseMenu, BaseTooltip } from "components/base";
import BoxPlot from "components/icons/BoxPlot";
import CrossPlot from "components/icons/CrossPlot";
import CumTime from "components/icons/CumTime";
import DeclineRate from "components/icons/DeclineRate";
import MaterialBalanceSheet from "components/icons/MaterialBalanceSheet";
import Mosaic from "components/icons/Mosaic";
import PieChart from "components/icons/PieChart";
import Probit from "components/icons/Probit";
import RateCum from "components/icons/RateCum";
import RateDate from "components/icons/RateDate";
import RateTime from "components/icons/RateTime";
import StackedBar from "components/icons/StackedBar";
import TotalRateCum from "components/icons/TotalRateCum";
import TotalRateDate from "components/icons/TotalRateDate";
import TrendDate from "components/icons/TrendDate";

import {
  CHART_TYPE_LABELS,
  ChartType,
  DEFAULT_CHART_PRODUCT
} from "../../../../constants";
import ChartTypeHeader from "../ChartTypeHeader";
import "./ChartSelector.scss";
import { ChartTypeButton, ChartTypeMenuContainer } from "./ChartSelector.styles";

const chartTypeIcons = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <RateDate key="rate-date" />,
  <TotalRateDate key="total-rate-date" />,
  <CumTime key="cum-time" />,
  <CrossPlot key="cross-plot" />,
  <Probit key="probit" />,
  <BoxPlot key="box-plot" />,
  <StackedBar key="stacked-bar" />,
  <PieChart key="pie-chart" />,
  <Mosaic key="mosaic" />,
  <MaterialBalanceSheet key="material-balance-sheet" />,
  <TotalRateCum key="total-rate-cum" />,
  <Icon path={mdiChartLine} size={1.8} key="cagr" />,
  <DeclineRate key="base-decline-rate" />,
  <TrendDate key="trend-date" />,
  <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
];

export const chartTypeIconsGrouped = [
  [<RateCum key="rate-cum" />, <RateTime key="rate-time" />, <CumTime key="cum-time" />],
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <TotalRateCum key="total-rate-cum" />
  ],
  [
    <CrossPlot key="cross-plot" />,
    <Probit key="probit" />,
    <BoxPlot key="box-plot" />,
    <StackedBar key="stacked-bar" />,
    <PieChart key="pie-chart" />,
    <Mosaic key="mosaic" />,
    <MaterialBalanceSheet key="material-balance-sheet" />,
    <Icon path={mdiChartLine} size={1.8} key="cagr" />,
    <DeclineRate key="base-decline-rate" />,
    <TrendDate key="trend-date" />
  ]
];
export const midstreamChartTypeIconsGrouped = [
  [
    <RateDate key="rate-date" />,
    <TotalRateDate key="total-rate-date" />,
    <Icon path={mdiChartAreaspline} size={1.8} key="well-contribution-cum-date" />
  ],
  [<CrossPlot key="cross-plot" />]
];
// Multi product
export const chartTypeIconsGroupedMultiProduct = [
  <RateCum key="rate-cum" />,
  <RateTime key="rate-time" />,
  <CumTime key="cum-time" />,
  <RateDate key="rate-date" />,
  <RateDate key="trend-date" />
];
// End Multi product

export function ChartSelector() {
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const dispatch = useDispatch();

  const typeIdx = CHART_TYPE_LABELS.findIndex((x) => x === chartType);
  if (!chartType) {
    dispatch(
      updateChart({
        id,
        chartType: EvaChart.RateCum,
        features: createFeatureEntities(EvaChart.RateCum)
      })
    );
  }

  useHotkeys("tab", noop, []);

  const availableChartTypes = [];

  return (
    <div className="charts-selector flex">
      {/* use Base components because antd would automatically open and popover location was not desirable, discovered in EVA-4042 */}
      <BaseTooltip text={chartType}>
        <BaseMenu
          trigger={
            <IconDropdown data-testid={"chartTypeSelector"}>
              {chartTypeIcons[typeIdx]}
            </IconDropdown>
          }>
          {({ closeMenu }) => (
            <ChartTypeMenuContainer>
              {(availableChartTypes?.length > 0 ? availableChartTypes : ChartType).map(
                (header, hIdx) => (
                  <ChartTypeHeader key={header.name} header={header.name}>
                    {header.items.map((ct, tIdx) => {
                      return (
                        <Tooltip key={ct} title={getChartType(ct)?.tooltip ?? ""}>
                          <ChartTypeButton
                            key={ct}
                            ellipses={false}
                            onClick={() => {
                              closeMenu();
                              const sessionData = JSON.parse(
                                sessionStorage.getItem(`chart::${id}`)
                              );
                              const overrides = createFeatureEntities(ct, {
                                ...sessionData?.features
                              });
                              dispatch(
                                updateChart({
                                  id,
                                  chartType: ct,
                                  // TODO chart: set different default for midstream
                                  product: sessionData?.product ?? DEFAULT_CHART_PRODUCT,
                                  features: overrides
                                })
                              );
                            }}
                            appearance=""
                            className={`${ct === chartType ? "isSelected" : null}`}>
                            {/* {entityKind === EntityKind.Well
                              ? chartTypeIconsGrouped[hIdx][tIdx]
                              : midstreamChartTypeIconsGrouped[hIdx][tIdx]} TODO chart: switch chart types for midstream*/}
                            {chartTypeIconsGrouped[hIdx][tIdx]}
                            <span>{ct}</span>
                          </ChartTypeButton>
                        </Tooltip>
                      );
                    })}
                  </ChartTypeHeader>
                )
              )}
            </ChartTypeMenuContainer>
          )}
        </BaseMenu>
      </BaseTooltip>
    </div>
  );
}

const IconDropdown = styled.div`
  color: #a2aaad;
  transition: color var(--duration-short);
  cursor: pointer;
  display: flex;
  &:hover {
    color: var(--color-primary);
  }
  margin-left: 21px;
`;
