import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Popconfirm, Table } from "antd";
import {
  setActiveSetting,
  setWorkspaceId
} from "store/features/userSettings/userSettingsSlice";
import styled from "styled-components";

import { useUser } from "hooks";

import { DELETE_WORKSPACE, WORKSPACES, WorkspaceClientName } from "api/workspace";

import { Workspace, WorkspaceQuery } from "models/workspace";

import { BaseDivider, Heading, Tooltip } from "components/base";

export default function WorkspacesList() {
  const dispatch = useDispatch();
  const { user, isReadonly, isAtLeastPowerUser } = useUser();

  const {
    data: workspacesData,
    loading,
    refetch: refetchWorkspaces
  } = useQuery<WorkspaceQuery>(WORKSPACES, {
    context: {
      clientName: WorkspaceClientName
    },
    notifyOnNetworkStatusChange: true
  });
  const [deleteWorkspace] = useMutation<WorkspaceQuery>(DELETE_WORKSPACE);

  // Take action when a workspace is permanently deleted.
  const handleDeleteWorkspace = useCallback(
    (workspaceId: string) => {
      deleteWorkspace({
        variables: {
          id: workspaceId
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: () => {
          refetchWorkspaces();
          toast.success("Workspace deleted");
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          toast.error("Failed to delete dashboard");
        }
      });
    },
    [deleteWorkspace, refetchWorkspaces]
  );

  useEffect(() => {
    refetchWorkspaces();
  }, [refetchWorkspaces]);

  // Take action when "edit workspace" is selected.
  const goToWorkspace = (id: string) => {
    dispatch(setWorkspaceId(id));
    dispatch(setActiveSetting("WorkspaceManagement"));
  };

  //function that displays a table with a list of all workspaces
  const WorkspacesDataTable = () => {
    const columns = [
      {
        title: "Name",
        dataIndex: "title",
        defaultSortOrder: "ascend" as const
      },
      {
        title: "Type",
        dataIndex: "type"
      },
      {
        title: "Owner",
        dataIndex: "ownerName"
      },
      {
        title: "# Dashboards",
        dataIndex: "dashboards",
        render: (_, record: Workspace) => record.dashboards?.length ?? 0
      },
      {
        key: "workspaceId",
        dataIndex: "workspaceId",
        render: (workspaceId, record: Workspace) => (
          <>
            <Actions>
              <Tooltip placement="top" title="Edit Workspace">
                <ActionButton onClick={() => goToWorkspace(workspaceId)}>
                  <Edit fontSize="large" />
                </ActionButton>
              </Tooltip>

              {!(
                record.type === "Personal" &&
                (record.title === "Explore" || record.title === "Midstream")
              ) &&
                (isAtLeastPowerUser || record.owner === user.id) && (
                  <Popconfirm
                    title={`Are you sure you want to delete the "${record.title}" workspace?`}
                    okText="Delete"
                    okType="danger"
                    onConfirm={(evt) => {
                      handleDeleteWorkspace(workspaceId);
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                    onCancel={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}>
                    <Tooltip placement="top" title="Delete Workspace">
                      <ActionButton danger>
                        <Delete fontSize="large" />
                      </ActionButton>
                    </Tooltip>
                  </Popconfirm>
                )}
            </Actions>
          </>
        )
      }
    ];

    return (
      <>
        <TableContainer>
          <Table
            columns={columns}
            sortDirections={["ascend", "descend", "ascend"]}
            dataSource={workspacesData?.workspaces}
            loading={loading}
            pagination={{
              pageSize: 24,
              showSizeChanger: false,
              hideOnSinglePage: true
            }}
          />
        </TableContainer>
      </>
    );
  };

  return (
    <>
      <RootContainer>
        <HeaderContainer>
          <Heading element="h4">Workspaces</Heading>
          {!isReadonly && (
            <HeaderActionContainer
              onClick={() => dispatch(setActiveSetting("WorkspaceManagement"))}>
              <AddIcon /> Add Workspace
            </HeaderActionContainer>
          )}
        </HeaderContainer>
        <ContentContainer>
          <BaseDivider />
          <SectionBody>
            <WorkspacesDataTable />
          </SectionBody>
        </ContentContainer>
      </RootContainer>
    </>
  );
}

//Styled  Components
const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border: 0;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;

const HeaderActionContainer = styled.button`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border: 0;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  color: var(--color-primary);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--color-text);
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const ContentContainer = styled.div`
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const RootContainer = styled.div`
  gap: 10px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: left;
  padding: 32px 100px;
  overflow: auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .section {
    padding: 33px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }
`;

const SectionBody = styled.div`
  padding: 33px 0;
`;

const Actions = styled.div`
  display: flex;
  opacity: 0;
`;

const TableContainer = styled.div`
  .ant-table-row:hover {
    ${Actions} {
      opacity: 1;
    }
  }
  .ant-table-thead > tr > th {
    font-weight: var(--fontWeightBold);
  }
`;
