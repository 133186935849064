import { memo } from "react";

function OutOfNetworkInlet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
      fill="currentColor"
      transform="scale(0.85)"
      height="40"
      width="40">
      <g transform="scale(0.8) translate(0, -2)">
        {/* fire */}
        <path
          fill="currentColor"
          transform="translate(10.8,9.6) scale(0.5)"
          d="M13.8165 11.8429C13.5771 7.9784 6.80651 0 6.80651 0C5.27508 6.87459 -0.00611427 7.65789 5.31389e-06 12.1833C0.00383005 15.237 1.38839 17.0101 3.11564 17.9518C2.5687 16.1228 2.79053 14.0001 4.43288 12.4656C7.46513 9.63222 7.79865 7.72674 7.79865 7.72674C10.9357 13.2963 10.1662 16.796 8.98432 18.5607C11.8621 17.7659 14.036 15.3808 13.8165 11.8429"
        />
        {/* circle */}
        <path
          transform="scale(0.8) translate(5.6,6)"
          d="M22 12A10 10 0 0 1 2.46 15H4.59A8 8 0 1 0 4.59 9H2.46A10 10 0 0 1 22 12Z"
        />

        <rect x="6.8" y="11.3" width="2" height="6.5" fill="white" stroke="white" />

        {/* arrow */}
        <path
          transform="scale(0.4) translate(5.5, 24)"
          d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z"
        />

        {/* dashed box */}
        <path
          transform="translate(-1.6,-1.4) scale(1.3)"
          d="M2.4,4C2.4,3.3 3.1,2.4 4.2,2.4H6.6V4H4.2V6.6H2.4V4M21.6,4V6.6H20V4H17.4V2.4H20A1.6,1.6 0 0,1 21.6,4M20,20V17.4H21.6V20C21.6,20.9 20.9,21.6 20,21.6H17.4V20H20M2.4,20V17.4H4.2V20H6.6V21.6H4.2A1.6,1.6 0 0,1 2.4,20M10.4,2.4H13.6V4H10.4V2.4M10.4,20H13.6V21.6H10.4V20M20,10H21.66V13.6H20V16Z"
        />
      </g>
    </svg>
  );
}

export default memo(OutOfNetworkInlet);
