import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { selectChartTypeWellsFeature, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import { UserArps } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const TypewellsToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const typeWellStates = useSelector((state: RootState) =>
    selectChartTypeWellsFeature(state, id)
  );
  const isEnabled = typeWellStates.enabled;
  const isActive = typeWellStates.active;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.typewells.active`,
        value: active
      })
    );
  };
  return (
    <ToolbarButton
      active={isActive}
      icon={<UserArps />}
      overflowLabel="Typewells"
      tooltipText="Type Wells"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(TypewellsToggle);
