import { memo, useState } from "react";
import { useSelector } from "react-redux";

import { Popover } from "antd";
import { useChartsContext } from "contexts/ChartContext";
import { selectChartNormalizationFeature } from "store/features";
import { RootState } from "store/rootReducer";

import CollapseVerticalIcon from "components/icons/CollapseVertical";

import ToolbarButton from "../../../base/ToolbarButton";
import NormalizeBySettings from "./NormalizeBySettings";

const NormalizeByToggle = () => {
  const [visible, setVisible] = useState(false);
  const { id } = useChartsContext();
  const isNormalizeActive = useSelector(
    (state: RootState) => selectChartNormalizationFeature(state, id)?.active
  );

  return (
    <Popover
      arrowPointAtCenter
      content={<NormalizeBySettings />}
      onOpenChange={(v) => setVisible(v)}
      open={visible}
      overlayClassName="normalize-popover"
      placement="bottomRight"
      trigger="click">
      <ToolbarButton
        active={isNormalizeActive}
        data-testid={
          isNormalizeActive ? "normalizationToggleActive" : "normalizationToggleInactive"
        }
        icon={<CollapseVerticalIcon />}
        isGlobalSetting={true}
        isMenuButton={true}
        overflowLabel="Normalize"
        tooltipText="Normalize"
      />
    </Popover>
  );
};

export default memo(NormalizeByToggle);
