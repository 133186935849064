import { useState } from "react";

import PaletteIcon from "@material-ui/icons/Palette";
import { Popover } from "antd";

import ToggleButton from "../ToggleButton";
import ColorPaletteList from "./ColorPaletteList";

function ColorPaletteToggle({ activePalette, onPaletteSelected, showBorder = false }) {
  const [visible, setVisible] = useState(false);

  const handlePaletteSelection = () => (p) => {
    onPaletteSelected(p);
    setVisible(false);
  };

  return (
    <Popover
      content={
        <ColorPaletteList selected={activePalette} onSelect={handlePaletteSelection()} />
      }
      overlayClassName="color-palette"
      placement="rightTop"
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToggleButton
        data-testid="colorPaletteToggleButton"
        tooltipText="Select Colour Palette"
        value={visible}
        onClick={() => setVisible(true)}
        showBorder={showBorder}>
        <PaletteIcon fontSize="large" />
      </ToggleButton>
    </Popover>
  );
}

export default ColorPaletteToggle;
