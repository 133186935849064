import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { translateLegendsToParams } from "entities/charts";
import {
  selectChartForecastFeature,
  selectChartResponse,
  selectChartSpaghettiFeature,
  selectChartType
} from "store/features";
import { RootState } from "store/rootReducer";
import { ChartActionType, SeriesType } from "types/echarts";

import { EntityKind } from "models/entityKind";

import { useChartDependencies } from "../useChartDependencies";
import {
  resetHighlights,
  resetHighlightsByChartType,
  updateTargetDataEmphasis,
  updateTargetSeriesEmphasis
} from "./utils";

export function useChartHighlights(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const chartResponse = useSelector((state: RootState) => selectChartResponse(state, id));
  const forecast = useSelector((state: RootState) =>
    selectChartForecastFeature(state, id)
  )?.active;
  const spaghetti = useSelector((state: RootState) =>
    selectChartSpaghettiFeature(state, id)
  )?.active;
  const {
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups
  } = useChartDependencies(EntityKind.Well);

  useEffect(() => {
    if (!chartInstanceRef.current) return;

    if (hoverLegendItem || selectedGroups.length > 0) {
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      const selectionIndex = translateLegendsToParams({
        type: chartType,
        states: {
          hoverLegendItem,
          hoverLegendGroup,
          hoverLegendId,
          attentionWells,
          selectedGroups,
          ...chartResponse
        },
        forecast: forecast,
        series: seriesArray
      });
      if (!selectionIndex) return;

      const { targetIndex, seriesIndex, seriesType } = selectionIndex;

      if (!hoverLegendItem) {
        resetHighlightsByChartType(
          chartInstanceRef.current,
          chartType,
          chartOptions.series
        );
        seriesArray.forEach((_, seriesIndex) => {
          chartInstanceRef.current.dispatchAction({
            type: ChartActionType.HideTip,
            seriesIndex
          });
        });
      }

      if (seriesType === SeriesType.Line && spaghetti && targetIndex) {
        updateTargetSeriesEmphasis(chartInstanceRef.current, targetIndex);
      }

      const highlightPoint =
        seriesType === SeriesType.Scatter && hoverLegendGroup?.length > 0;
      chartInstanceRef.current.dispatchAction({
        type: ChartActionType.Highlight,
        seriesIndex: seriesIndex,
        dataIndex: highlightPoint ? targetIndex : undefined
      });

      if (seriesType === SeriesType.Scatter) {
        updateTargetDataEmphasis(chartInstanceRef.current, seriesIndex, targetIndex);
        chartInstanceRef.current.dispatchAction({
          type: ChartActionType.ShowTip,
          seriesIndex: seriesIndex,
          dataIndex: targetIndex
        });
      }
    } else {
      resetHighlights(chartInstanceRef.current, chartType);
    }
  }, [
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups,
    chartType,
    chartResponse,
    forecast,
    spaghetti,
    chartInstanceRef.current
  ]);
}
