import { memo, useState } from "react";
import { useSelector } from "react-redux";

import { Popover } from "antd";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartAverageFeature,
  selectChartP10Feature,
  selectChartP50Feature,
  selectChartP90Feature
} from "store/features";
import { RootState } from "store/rootReducer";

import StatsIcon from "components/icons/Stats";

import ToolbarButton from "../../../base/ToolbarButton";
import StatisticsOptions from "./StatisticsOptions";

const StatisticsToggle = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { id } = useChartsContext();
  const averageState = useSelector((state: RootState) =>
    selectChartAverageFeature(state, id)
  );
  const p10State = useSelector((state: RootState) => selectChartP10Feature(state, id));
  const p50State = useSelector((state: RootState) => selectChartP50Feature(state, id));
  const p90State = useSelector((state: RootState) => selectChartP90Feature(state, id));

  const isActive =
    averageState?.active || p10State?.active || p50State?.active || p90State?.active;
  const isDisabled = !(
    averageState?.enabled ||
    p10State?.enabled ||
    p50State?.enabled ||
    p90State?.enabled
  );

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={isVisible}
      onOpenChange={(v) => setIsVisible(v)}
      content={<StatisticsOptions isVisible={isVisible} />}>
      <ToolbarButton
        data-testid={isActive ? "statisticsToggleActive" : "statisticsToggleInactive"}
        active={isActive}
        icon={<StatsIcon />}
        isMenuButton={true}
        overflowLabel={"Statistics"}
        tooltipText={"Show Statistics"}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(StatisticsToggle);
