import { BinSize, MaxBinsSortOrderEnum, mBinSize } from "models/binSize";

export function sanitizeBinValues(bin: mBinSize): BinSize {
  if (!bin) return;

  const defaultValues = {
    BinSize: 0,
    MinSize: null,
    MaxBins: null,
    GreaterThan: null,
    LessThan: null,
    MaxBinsSortOrder: MaxBinsSortOrderEnum.WellCount,
    BinType: null
  };

  const sanitizedBin = {
    ...defaultValues,
    ...Object.keys(bin).reduce((acc, key) => {
      acc[key] = bin[key] === "" ? defaultValues[key] : bin[key];
      return acc;
    }, {})
  };

  return sanitizedBin;
}
