import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartOptions,
  selectChartRequest,
  selectChartResponse,
  selectChartScreenshotFeature,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { useScreenshotContext, useScreenshotDispatch } from "components/screenshot/hooks";

import { Portal } from "../../ui";

type ScreenshotT = {
  containerId: string;
};

const Screenshot: FC<ScreenshotT> = ({ containerId }) => {
  const dispatch = useDispatch();

  const screenshotDispatch = useScreenshotDispatch();
  const { id } = useChartsContext();
  const request = useSelector((state: RootState) => selectChartRequest(state, id));
  const response = useSelector((state: RootState) => selectChartResponse(state, id));
  const screenshot = useSelector((state: RootState) =>
    selectChartScreenshotFeature(state, id)
  );
  const options = useSelector((state: RootState) => selectChartOptions(state, id));
  const { settings, widget } = useScreenshotContext();

  // ref
  const overlayRef = useRef<HTMLDivElement>(null);

  // derived state
  const screenshotContainer = document.getElementById(containerId);

  useEffect(() => {
    const screenshotActive = !!id && widget?.widgetId === id;

    const next = {
      ...screenshot,
      visible: screenshotActive,
      active: screenshotActive,
      properties: { preset: settings }
    };
    dispatch(
      updateChartByPath({
        id,
        path: `features.screenshot`,
        value: next
      })
    );
    dispatch(
      updateChartByPath({
        id,
        path: `features.fullScreen.active`,
        value: screenshotActive
      })
    );
  }, [dispatch, id, settings, widget]);

  // Update the widgets state.
  useEffect(() => {
    const screenshotActive = !!id && widget?.widgetId === id;

    if (screenshotActive) {
      // Copy the options to prevent mutation.
      const optionsCopy = JSON.parse(options);
      screenshotDispatch({
        payload: {
          widgetState: {
            request,
            response,
            options: optionsCopy
          }
        }
      });
    }
  }, [request, response, options, screenshotDispatch, id, widget]);
  if (!screenshot.active || !screenshotContainer) return null;
  // TODO chart: add toast messages
  // Dismiss toast messages when screenshot first gets toggled
  // toast.dismiss(`sync-${id}`);
  // toast.dismiss(`type-well-only-${id}`);
  // toast.dismiss(`well-norm-${id}`);
  // toast.dismiss(`type-well-norm-${id}`);

  // Update the overlay.
  screenshotDispatch({
    payload: {
      screenshotOverlay: overlayRef.current
    }
  });
  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  return (
    <Portal container={screenshotContainer}>
      <Overlay
        className="screenshot-overlay"
        ref={overlayRef}
        width={screenshotBounds.width}
        height={screenshotBounds.height}>
        {/* {settings?.legendVisible && (
          <ChartLegend
            parentDimensions={screenshotBounds}
            id={containerId + "chart-legend"}
            disableDragging={false}
            showLegendBorder={settings?.legendBorderVisible}
            width={250}
          />
        )} */}
      </Overlay>
    </Portal>
  );
};

export default Screenshot;

const Overlay = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border: 2px solid rgb(255, 208, 0);
  z-index: 1;
  pointer-events: none;
`;
