import { Switch } from "antd";
import styled from "styled-components";

import InputCollectionHeader from "components/base/InputCollectionHeader";
import { useChartSettings } from "components/multiphase-chart/context";
import {
  setCombineSameUnitAxis,
  setMbblPosition,
  setMmcfPosition
} from "components/multiphase-chart/context/reducer";

// This component is used to select the X-Axis position for the monthly rate cumulative chart type
// TODO: Create input component to reduce repitions
const XAxisSelector = (): JSX.Element => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { currentPreset, isViewMode } = chartSettings;

  if (currentPreset.chartType !== "monthlyRateCum") {
    return null;
  }

  return (
    <>
      <InputCollectionHeader key={"x-axis"} header={"X-Axis"}>
        <label>Mbbl</label>
        <label>
          <input
            disabled={isViewMode ? true : false}
            style={{ marginRight: "4px" }}
            type="radio"
            name="mBbl"
            value="top"
            checked={
              (!isViewMode && currentPreset.mBblPosition === "top") ||
              (isViewMode && currentPreset.mBblPosition === "top")
            }
            className="form-check-input"
            onChange={(a) => {
              setMbblPosition(chartSettingsDispatch, a.target.value);
            }}
          />
          Top
        </label>
        <label>
          <input
            disabled={isViewMode ? true : false}
            style={{ marginRight: "4px" }}
            type="radio"
            name="mBbl"
            value="bottom"
            checked={
              (!isViewMode && currentPreset.mBblPosition === "bottom") ||
              (isViewMode && currentPreset.mBblPosition === "bottom")
            }
            className="form-check-input"
            onChange={(a) => setMbblPosition(chartSettingsDispatch, a.target.value)}
          />
          Bottom
        </label>

        <label>MMcf</label>
        <label>
          <input
            disabled={isViewMode ? true : false}
            style={{ marginRight: "4px" }}
            type="radio"
            name="mMcf"
            value="top"
            className="form-check-input"
            checked={
              (!isViewMode && currentPreset.mMcfPosition === "top") ||
              (isViewMode && currentPreset.mMcfPosition === "top")
            }
            onChange={(a) => setMmcfPosition(chartSettingsDispatch, a.target.value)}
          />
          Top
        </label>
        <label>
          <input
            disabled={isViewMode ? true : false}
            style={{ marginRight: "4px" }}
            type="radio"
            name="mMcf"
            value="bottom"
            checked={
              (!isViewMode && currentPreset.mMcfPosition === "bottom") ||
              (isViewMode && currentPreset.mMcfPosition === "bottom")
            }
            className="form-check-input"
            onChange={(a) => setMmcfPosition(chartSettingsDispatch, a.target.value)}
          />
          Bottom
        </label>
      </InputCollectionHeader>
      <SwitchContainer>
        <label>Always combine same unit axis</label>
        <Switch
          disabled={isViewMode}
          checked={currentPreset.combineSameUnitAxis || false}
          onChange={(checked) => setCombineSameUnitAxis(chartSettingsDispatch, checked)}
          size="small"
        />
      </SwitchContainer>
    </>
  );
};

const SwitchContainer = styled.div`
  padding: 10px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default XAxisSelector;
