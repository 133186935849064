import booleanIntersect from "@turf/boolean-intersects";
import { lineString, polygon } from "@turf/turf";
import { SeriesOption } from "echarts";
import { LassoSelectionParams } from "types/lasso";

/**
 * Filters scatter items based on lasso selection.
 * @param params - The lasso selection parameters.
 * @param series - Array of series options.
 * @param uwiList - An array of items (one per datum); adjust type as needed.
 * @returns An array of filtered items.
 */
export function getScatterFilteredItems(
  params: LassoSelectionParams,
  series: SeriesOption[],
  uwiList = []
) {
  const selected = params.batch[0]?.selected;
  const filtered = selected?.filter((s) => s.dataIndex.length);

  const list = [];
  if (!filtered?.length) {
    return [];
  }

  // filter brushed wells
  filtered.forEach((item) => {
    const { seriesIndex, dataIndex } = item;
    const seriesData = series[seriesIndex]?.data;

    if (!seriesData) {
      // eslint-disable-next-line no-console
      console.warn("No series data found for seriesIndex: ", seriesIndex);
      return;
    }

    // get uwi list for every dataIndex
    const uwis = dataIndex.map((index) => {
      const uwiIndex = seriesData[index][2];
      return uwiList[uwiIndex];
    });

    list.push(...uwis);
  });

  return list;
}

export function getLineChartFilteredItems(params: LassoSelectionParams, series) {
  const list = [];
  const lassoCoordinates = params.batch[0]?.areas[0]?.coordRange;
  if (lassoCoordinates) {
    const firstCoordinate = lassoCoordinates[0];
    lassoCoordinates.push(firstCoordinate);
    const lassoPolygon = polygon([lassoCoordinates], { name: "lasso" });
    series.forEach((s) => {
      if (!s.data) return;

      const data = s.data.map((list) => list.slice(0, 2));

      if (data.length < 2) return [];
      const line = lineString(data, { name: "line" });
      const intersects = booleanIntersect(line, lassoPolygon);
      if (!intersects) return;

      const seriesName = s.name;
      if (seriesName.includes("Forecast")) {
        const resultString = seriesName.split(" Forecast")[0];
        list.push(resultString, resultString);
      } else {
        list.push(s.name);
      }
    });
  }

  return list;
}
