import { RateDto } from "arps_wasm";
import { ChartTypeLabels } from "constants/chart.constants";
import { TimeStep } from "constants/charts.enums";
import dayjs from "dayjs";

import { getFractionalMonths, isNextMonth } from "./dates";
import { scaleDataBasedOnYAxisUnits } from "./scaleDataBasedOnYAxisUnits";

const TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP = 100;

export function calculateTimeForTimeSeriesCharts(
  yAxisTitle: string,
  rateCum: RateDto[],
  chartType: "Rate Time" | "Cum Time",
  timeStep: TimeStep
): [number, number, number | undefined][] {
  const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
  const { series } = rateCum.reduce(
    (acc, rc) => {
      const currentDate = new Date(Date.UTC(rc.year, rc.month - 1, rc.day));
      const incrementMonth =
        timeStep == TimeStep.Day || isNextMonth(acc.lastDate, currentDate);
      if (incrementMonth || acc.lastDate == null) {
        acc.monthIdx++;
      }
      acc.lastDate = currentDate;
      const nextTimeValue =
        timeStep == TimeStep.Day
          ? acc.monthIdx
          : getFractionalMonths(dayjs.utc(currentDate), acc.monthIdx);

      acc.series.push([
        nextTimeValue,
        chartType == "Rate Time" ? rc.rate : rc.cum * scale,
        undefined
      ]);

      return acc;
    },
    { series: [], lastDate: null, monthIdx: -1 } as {
      series: [number, number, number | undefined][];
      lastDate: Date | null;
      monthIdx: number;
    }
  );

  return series;
}

export function getTypeWellSeries(
  chartType: ChartTypeLabels,
  rateCum: RateDto[],
  yAxisTitle: string,
  timeStep: TimeStep
): [number | string, number, number | undefined][] {
  if (chartType == "Rate Cum") {
    return rateCum.map((rc) => {
      return [rc.cum * 0.001, rc.rate, undefined];
    });
  }

  if (chartType == "Rate Time" || chartType == "Cum Time") {
    return calculateTimeForTimeSeriesCharts(yAxisTitle, rateCum, chartType, timeStep);
  }

  if (chartType == "Rate Date") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    const series = [];

    const date = new Date();
    const maxYear = date.getFullYear() + TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP;

    rateCum
      .filter((rc, idx) => {
        return (
          idx > 0 &&
          (rc.year < maxYear || (rc.year === maxYear && rc.month === 1 && rc.day === 1))
        );
      })
      .forEach((rc) => {
        series.push([
          rc.year +
            "-" +
            rc.month.toString().padStart(2, "0") +
            "-" +
            rc.day.toString().padStart(2, "0") +
            "T00:00:00",
          rc.rate * scale,
          undefined
        ]);
      });

    return series;
  }
}
