import { useEffect } from "react";

import debounce from "lodash-es/debounce";

export function useResizeObserver(
  chartRef: React.RefObject<HTMLDivElement | null>,
  chartInstanceRef: React.RefObject<echarts.ECharts>,
  onResize: () => void,
  debounceDelay = 100
) {
  useEffect(() => {
    if (!chartRef.current) return;

    const handleResize = debounce(() => {
      if (chartInstanceRef.current && chartRef.current) {
        chartInstanceRef.current.resize();
        onResize();
      }
    }, debounceDelay);

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(chartRef.current);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      resizeObserver.disconnect();
      // Cancel any pending debounced calls
      if (handleResize.cancel) {
        handleResize.cancel();
      }
    };
  }, [chartRef, chartInstanceRef, onResize, debounceDelay]);
}
