import { getChartTypeAlias, getChartTypeId } from "utils";

import { IChartResult } from "models/model";

import { tabs } from "../../constants/shared.constants";
import {
  IChartPreset,
  IMultiphaseChartSettings,
  IProduct,
  ISeriesStyle
} from "../../models/shared.models";
import {
  ChartSettingsDispatchT,
  IChartSettingsState,
  ScreenshotT,
  initialPresetState
} from "../ChartSettingsContext";

type ChartReducerActionsT =
  | { type: "add preset" }
  | { type: "add product"; product: IProduct }
  | { type: "change preset"; preset: IChartPreset }
  | { type: "reset preset" }
  | { type: "on add preset success"; preset: IChartPreset }
  | { type: "on delete preset success"; nextPreset: IChartPreset }
  | { type: "on inital load preset"; preset: IChartPreset }
  | { type: "on leaving dirty form" }
  | { type: "set chartType"; chartType: string }
  | { type: "set currentTab"; tab: string }
  | { type: "set currentProductTab"; tab: string }
  | { type: "set error"; error: string }
  | { type: "set mBblPosition"; position: string }
  | { type: "set mMcfPosition"; position: string }
  | { type: "set name"; name: string }
  | { type: "set products"; products: IProduct[] }
  | { type: "set roleType"; roleType: string }
  | { type: "set style"; style: ISeriesStyle[] }
  | { type: "toggle isFetching"; isFetching: boolean }
  | { type: "toggle isSettingsOpen"; isSettingsOpen: boolean }
  | { type: "toggle isViewMode"; isViewMode: boolean }
  | { type: "toggle isMouseOver"; isMouseOver: boolean }
  | { type: "change settings"; settings: IMultiphaseChartSettings }
  | { type: "toggle isFullscreen"; isFullscreen: boolean }
  | { type: "change screenshot"; screenshot: ScreenshotT }
  | { type: "change id"; id: string }
  | { type: "change chart data"; data: IChartResult }
  | { type: "change chart bounds"; bounds: DOMRect | null }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: "change legend"; legend: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: "change axis minmax"; legend: any }
  | { type: "overflow"; index: number }
  | { type: "set combineSameUnitAxis"; index: boolean };
const chartSettingsReducer = (
  draft: IChartSettingsState,
  action: ChartReducerActionsT
) => {
  switch (action.type) {
    case "add preset": {
      draft.currentPreset = { ...initialPresetState };
      draft.isViewMode = false;
      draft.currentTab = tabs.chartSettings;
      break;
    }
    case "add product": {
      draft.currentPreset.products.push(action.product);
      break;
    }
    case "change preset": {
      const chartTypeId = getChartTypeId(getChartTypeAlias(action.preset.chartType));
      draft.error = "";
      draft.storedPreset = { ...action.preset, chartTypeId };
      draft.currentPreset = { ...action.preset, chartTypeId };
      draft.currentTab = tabs.chartSettings;
      break;
    }
    case "on add preset success": {
      const chartTypeId = getChartTypeId(getChartTypeAlias(action.preset.chartType));

      draft.storedPreset = { ...action.preset, chartTypeId };
      draft.currentPreset = { ...action.preset, chartTypeId };
      draft.currentTab = tabs.chartSettings;
      draft.isViewMode = true;
      draft.error = "";
      break;
    }
    case "on delete preset success": {
      const chartTypeId = getChartTypeId(getChartTypeAlias(action.nextPreset.chartType));

      draft.currentPreset = { ...action.nextPreset, chartTypeId };
      draft.storedPreset = { ...action.nextPreset, chartTypeId };
      draft.error = "";
      break;
    }
    case "on inital load preset": {
      const chartTypeId = getChartTypeId(getChartTypeAlias(action.preset.chartType));
      draft.error = "";
      draft.storedPreset = { ...action.preset, chartTypeId };
      draft.currentPreset = { ...action.preset, chartTypeId };
      draft.currentTab = tabs.chartSettings;
      break;
    }
    case "on leaving dirty form": {
      draft.currentPreset = { ...draft.storedPreset };
      draft.isViewMode = true;
      break;
    }
    case "set chartType": {
      const chartTypeId = getChartTypeId(getChartTypeAlias(action.chartType));
      draft.currentPreset.chartType = action.chartType;
      draft.currentPreset.chartTypeId = chartTypeId;

      break;
    }
    case "set currentTab": {
      draft.currentTab = action.tab;
      break;
    }
    case "set currentProductTab": {
      draft.currentProductTab = action.tab;
      break;
    }
    case "set error": {
      draft.error = action.error;
      break;
    }
    case "set name": {
      draft.currentPreset.name = action.name;
      break;
    }
    case "set mBblPosition": {
      draft.currentPreset.mBblPosition = action.position;
      break;
    }
    case "set mMcfPosition": {
      draft.currentPreset.mMcfPosition = action.position;
      break;
    }
    case "set products": {
      draft.currentPreset.products = action.products;
      break;
    }
    case "set roleType": {
      draft.currentPreset.roleType = action.roleType;
      break;
    }
    case "set style": {
      draft.currentPreset.style = action.style;
      break;
    }
    case "toggle isViewMode": {
      draft.isViewMode = action.isViewMode;
      break;
    }
    case "toggle isSettingsOpen": {
      draft.isSettingsOpen = action.isSettingsOpen;

      draft.isSettingsOpen
        ? (draft.isViewMode = true)
        : (draft.currentPreset = { ...draft.storedPreset });

      break;
    }
    case "reset preset": {
      draft.currentPreset = initialPresetState;
      break;
    }
    case "toggle isMouseOver": {
      draft.isMouseOver = action.isMouseOver;
      break;
    }
    case "change settings": {
      draft.settings = action.settings;
      break;
    }
    case "toggle isFullscreen": {
      draft.isFullscreen = action.isFullscreen;
      break;
    }
    case "change screenshot": {
      draft.screenshot = action.screenshot;
      break;
    }
    case "change id": {
      draft.id = action.id;
      break;
    }
    case "change chart data": {
      draft.data = action.data;
      break;
    }
    case "change chart bounds": {
      draft.bounds = action.bounds;
      break;
    }
    case "change legend": {
      draft.legend = action.legend;
      break;
    }
    case "change axis minmax": {
      draft.axisMinMax = action.legend;
      break;
    }
    case "overflow": {
      draft.overflowIndex = action.index;
      break;
    }
    case "set combineSameUnitAxis": {
      draft.currentPreset.combineSameUnitAxis = action.index;
      break;
    }
    default: {
      throw new Error("invalid chart settings reducer action");
    }
  }
};

// module functions
const addPreset = (dispatch: ChartSettingsDispatchT) => dispatch({ type: "add preset" });

const addProduct = (dispatch: ChartSettingsDispatchT, product: IProduct) =>
  dispatch({ type: "add product", product });

const changePreset = (dispatch: ChartSettingsDispatchT, preset: IChartPreset) =>
  dispatch({ type: "change preset", preset });

const resetPreset = (dispatch: ChartSettingsDispatchT) =>
  dispatch({ type: "reset preset" });

const onAddPresetSuccess = (dispatch: ChartSettingsDispatchT, preset: IChartPreset) =>
  dispatch({ type: "on add preset success", preset });

const onDeletePresetSuccess = (
  dispatch: ChartSettingsDispatchT,
  nextPreset: IChartPreset
) => dispatch({ type: "on delete preset success", nextPreset });

const onInitialLoadPreset = (dispatch: ChartSettingsDispatchT, preset: IChartPreset) =>
  dispatch({ type: "on inital load preset", preset });

const onLeavingDirtyForm = (dispatch: ChartSettingsDispatchT) =>
  dispatch({ type: "on leaving dirty form" });

const setChartType = (dispatch: ChartSettingsDispatchT, chartType: string) =>
  dispatch({ type: "set chartType", chartType });

const setCurrentTab = (dispatch: ChartSettingsDispatchT, tab: string) =>
  dispatch({ type: "set currentTab", tab });

const setCurrentProductTab = (dispatch: ChartSettingsDispatchT, tab: string) =>
  dispatch({ type: "set currentProductTab", tab });

const setError = (dispatch: ChartSettingsDispatchT, error) =>
  dispatch({ type: "set error", error });

const setMbblPosition = (dispatch: ChartSettingsDispatchT, position: string) =>
  dispatch({ type: "set mBblPosition", position });

const setMmcfPosition = (dispatch: ChartSettingsDispatchT, position: string) =>
  dispatch({ type: "set mMcfPosition", position });

const setName = (dispatch: ChartSettingsDispatchT, name: string) =>
  dispatch({ type: "set name", name });

const setProducts = (dispatch: ChartSettingsDispatchT, products: IProduct[]) =>
  dispatch({ type: "set products", products });

const setRoleType = (dispatch: ChartSettingsDispatchT, roleType: string) =>
  dispatch({ type: "set roleType", roleType });

const setStyle = (dispatch: ChartSettingsDispatchT, style: ISeriesStyle[]) =>
  dispatch({ type: "set style", style });

const toggleIsFetching = (dispatch: ChartSettingsDispatchT, isFetching: boolean) =>
  dispatch({ type: "toggle isFetching", isFetching });

const toggleIsSettingsOpen = (
  dispatch: ChartSettingsDispatchT,
  isSettingsOpen: boolean
) => dispatch({ type: "toggle isSettingsOpen", isSettingsOpen });

const toggleIsViewMode = (dispatch: ChartSettingsDispatchT, isViewMode: boolean) =>
  dispatch({ type: "toggle isViewMode", isViewMode });

const toggleIsMouseOver = (dispatch: ChartSettingsDispatchT, isMouseOver: boolean) =>
  dispatch({ type: "toggle isMouseOver", isMouseOver });

const changeSettings = (
  dispatch: ChartSettingsDispatchT,
  settings: IMultiphaseChartSettings
) => dispatch({ type: "change settings", settings });

const toggleIsFullscreen = (dispatch: ChartSettingsDispatchT, isFullscreen: boolean) =>
  dispatch({ type: "toggle isFullscreen", isFullscreen });

const changeScreenshot = (dispatch: ChartSettingsDispatchT, screenshot: ScreenshotT) =>
  dispatch({ type: "change screenshot", screenshot });

const changeId = (dispatch: ChartSettingsDispatchT, id: string) =>
  dispatch({ type: "change id", id });

const changeChartData = (dispatch: ChartSettingsDispatchT, data?: IChartResult) =>
  dispatch({ type: "change chart data", data });

const changeChartBounds = (dispatch: ChartSettingsDispatchT, bounds: DOMRect | null) =>
  dispatch({ type: "change chart bounds", bounds });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const changeLegend = (dispatch: ChartSettingsDispatchT, legend: any) =>
  dispatch({ type: "change legend", legend });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const changeAxisMinMax = (dispatch: ChartSettingsDispatchT, legend: any) =>
  dispatch({ type: "change axis minmax", legend });

const changeChartOverflowIndex = (dispatch: ChartSettingsDispatchT, index: number) =>
  dispatch({ type: "overflow", index });

const setCombineSameUnitAxis = (dispatch: ChartSettingsDispatchT, index: boolean) =>
  dispatch({ type: "set combineSameUnitAxis", index });

export {
  addPreset,
  addProduct,
  changePreset,
  resetPreset,
  onAddPresetSuccess,
  onDeletePresetSuccess,
  onInitialLoadPreset,
  onLeavingDirtyForm,
  setChartType,
  setError,
  setCurrentTab,
  setCurrentProductTab,
  setMbblPosition,
  setMmcfPosition,
  setName,
  setProducts,
  setRoleType,
  setStyle,
  toggleIsFetching,
  toggleIsSettingsOpen,
  toggleIsViewMode,
  toggleIsMouseOver,
  changeSettings,
  chartSettingsReducer,
  toggleIsFullscreen,
  changeScreenshot,
  changeId,
  changeChartData,
  changeChartBounds,
  changeLegend,
  changeAxisMinMax,
  changeChartOverflowIndex,
  setCombineSameUnitAxis
};

export type { ChartReducerActionsT };
