import { BetaFeatures } from "hooks/useBetaFeatures";

import {
  ChartAxisType,
  IAllChartTypes,
  IAllProductTypes,
  IChartSettings
} from "models/chart";
import { EntityKind } from "models/entityKind";

import { StateT, defaultChartState } from "components/chart/context";
import AcidGasDisposition from "components/icons/AcidGasDisposition";
import AcidGasShrinkage from "components/icons/AcidGasShrinkage";
import CasingPressure from "components/icons/CasingPressure";
import FFVRatioIcon from "components/icons/FFVRatioIcon";
import LiquidsShrinkage from "components/icons/LiquidsShrinkage";
import OilCond from "components/icons/OilCond";
import TubingPressure from "components/icons/TubingPressure";

import { CalculationMethod } from "../components/chart/components/stacked-bar-options/StackedBarCalculation";
import {
  C2,
  C3,
  C4,
  C5,
  CO2,
  Flared,
  Fuel,
  GPWater,
  GasEnergy,
  GasSales,
  GasShrinkage,
  HeatingValue,
  InNetworkInlet,
  InNetworkOutlet,
  InletUtilization,
  NglMix,
  NglMix234,
  OilCondC5,
  OutOfNetworkInlet,
  PolymerInjection,
  RatioIcon,
  SalesLiquids,
  SalesOutlet,
  TotalInlet,
  TotalOutlet,
  Vented
} from "../components/icons";
import AcidGasInjection from "../components/icons/AcidGasInjection";
import CO2Injection from "../components/icons/CO2Injection";
import FFV from "../components/icons/FFV";
import GasInjection from "../components/icons/GasInjection";
import OilCut from "../components/icons/OilCut";
import OnTimePercent from "../components/icons/OnTimePercent";
import ProductBoe from "../components/icons/ProductBoe";
import ProductGas from "../components/icons/ProductGas";
import ProductWater from "../components/icons/ProductWater";
import RatioCGR from "../components/icons/RatioCGR";
import RatioCSOR from "../components/icons/RatioCSOR";
import RatioCWB from "../components/icons/RatioCWB";
import RatioGOR from "../components/icons/RatioGOR";
import RatioISOR from "../components/icons/RatioISOR";
import RatioIWB from "../components/icons/RatioIWB";
import RatioOnePlusWOR from "../components/icons/RatioOnePlusWOR";
import RatioWGR from "../components/icons/RatioWGR";
import RatioWOR from "../components/icons/RatioWOR";
import SolventInjection from "../components/icons/SolventInjection";
import SteamInjection from "../components/icons/SteamInjection";
import Sulphur from "../components/icons/Sulphur";
import TotalFluid from "../components/icons/TotalFluid";
import TotalGasInjection from "../components/icons/TotalGasInjection";
import TotalLiquidInjection from "../components/icons/TotalLiquidInjection";
import WaterCut from "../components/icons/WaterCut";
import WaterInjection from "../components/icons/WaterInjection";
import { IGroupBy } from "../models";
import { IChartResult } from "../models/model";
import { EvaSource, TimeStep } from "./charts.enums";

export const DEFAULT_TITLE_FONT_SIZE = 14;
export const DEFAULT_AXIS_FONT_SIZE = 12;
export const DEFAULT_AXIS_FONT_WEIGHT = 400;
export const DEFAULT_LEGEND_FONT_SIZE = 12;
export const DEFAULT_AXIS_LABEL_FONT_SIZE = 12;
export const DEFAULT_DOT_OPACITY = 1;
export const DEFAULT_DOT_SIZE = 5;
export const DEFAULT_AXIS_SPLIT_NUMBER = 9;
export const BLACK_HEX = "#000000";
export const DEFAULT_FONT_WEIGHT = 500;
export const CHART_DEFAULT_MIN_LOG_VALUE = 0.1;
export const DEFAULT_MOVING_AVG_DAYS = 0;
export const DEFAULT_LINE_THICKNESS = 3;
export const DEFAULT_NORMALIZE_BY_FIELD = "Well_Design.HzLength_m";
export const DEFAULT_NORMALIZE_BY_DISPLAY_FIELD = "Hz Length (m)";
export const DEFAULT_NORMALIZE_BY_PER = 100;
export const DEFAULT_NORMALIZE_BY_HIGHER_SCALER = 1;
export const DEFAULT_NORMALIZE_BY_LOWER_SCALER = 1;
export const DEFAULT_NORMALIZE_BY_UNIT = "m";
export const DEFAULT_NORMALIZE_BY_THRESHOLD = 0;
export const DEFAULT_CHART_PRODUCT = "Oil + Cond";

export const AXIS_TYPE: { [key: string]: ChartAxisType } = Object.freeze({
  x: "x",
  y: "y"
});

const hasPlantLiquids = (hasFeature: (feature: BetaFeatures) => boolean): boolean => {
  return hasFeature("Plant Liquids");
};

const hasMidstream = (hasFeature: (feature: BetaFeatures) => boolean): boolean => {
  return hasFeature("Facility") || hasFeature("Facility Volumes");
};

// To change chart title and axis labels go to ProductType.cs in eva-chart-svc
export const ALL_PRODUCT_TYPES: IAllProductTypes = {
  // primary
  Oil: {
    key: "Oil",
    label: "Oil + Cond",
    id: 0,
    icon: <OilCond key="oil-cond" />,
    unit: "bbl"
  },
  Gas: {
    key: "Gas",
    label: "Gas",
    id: 1,
    icon: <ProductGas key="product-gas" />,
    unit: "mcf"
  },
  Water: {
    key: "Water",
    label: "Water",
    id: 3,
    icon: <ProductWater key="product-water" />,
    unit: "bbl"
  },
  TotalFluid: {
    key: "Total Fluid",
    label: "Total Fluid",
    id: 4,
    icon: <TotalFluid key="total-fluid" />,
    unit: "bbl"
  },
  BOE: {
    key: "BOE",
    label: "BOE",
    id: 12,
    icon: <ProductBoe key="product-boe" />,
    unit: "boe"
  },
  WaterInj: {
    key: "Water Inj",
    label: "Water Inj",
    id: 15,
    icon: <WaterInjection key="water-injection" />,
    unit: "bbl"
  },
  GasInj: {
    key: "Gas Inj",
    label: "Gas Inj",
    id: 14,
    icon: <GasInjection key="gas-injection" />,
    unit: "mcf"
  },
  SteamInj: {
    key: "Steam Inj",
    label: "Steam Inj",
    id: 13,
    icon: <SteamInjection key="steam-injection" />,
    unit: "bbl"
  },
  PolymerInj: {
    key: "Polymer Inj",
    label: "Polymer Inj",
    id: 20,
    icon: <PolymerInjection key="polymer-injection" />
  },
  CO_2Inj: {
    key: "CO2 Inj",
    label: "CO₂ Inj",
    id: 24,
    icon: <CO2Injection key="co2-injection" />,
    unit: "mcf"
  },
  AcidGasInj: {
    key: "Acid Gas Inj",
    label: "Acid Gas Inj",
    id: 25,
    icon: <AcidGasInjection key="acid-gas-injection" />,
    unit: "mcf"
  },
  SolventInj: {
    key: "Solvent Inj",
    label: "Solvent Inj",
    id: 26,
    icon: <SolventInjection key="solvent-injection" />,
    unit: "mcf"
  },
  TotalLiquidInj: {
    key: "Total Liquid Inj",
    label: "Total Liquid Inj",
    id: 27,
    icon: <TotalLiquidInjection key="total-liquid-injection" />,
    unit: "bbl"
  },
  TotalGasInj: {
    key: "Total Gas Inj",
    label: "Total Gas Inj",
    id: 28,
    icon: <TotalGasInjection key="total-gas-injection" />,
    unit: "mcf"
  },
  // ratio
  CGR: {
    key: "CGR",
    label: "(O+C)GR",
    id: 6,
    lock: 1000,
    icon: <RatioCGR key="ratio-cgr" />,
    unit: "bbl/mmcf"
  },
  GOR: {
    key: "GOR",
    label: "GOR",
    id: 5,
    lock: 20000,
    icon: <RatioGOR key="ratio-gor" />,
    unit: "scf/bbl"
  },
  WGR: {
    key: "WGR",
    label: "WGR",
    id: 7,
    icon: <RatioWGR key="ratio-wgr" />,
    unit: "bbl/mmcf"
  },
  WOR: {
    key: "WOR",
    label: "WOR",
    id: 18,
    icon: <RatioWOR key="ratio-wor" />,
    unit: "bbl/bbl"
  },
  OnePlusWOR: {
    key: "1+WOR",
    label: "1+WOR",
    id: 19,
    icon: <RatioOnePlusWOR key="ratio-one-plus-wor" />,
    unit: "bbl/bbl"
  },
  ISOR: {
    key: "ISOR",
    label: "ISOR",
    id: 9,
    lock: 10,
    icon: <RatioISOR key="ratio-isor" />,
    unit: "bbl/bbl"
  },
  CSOR: {
    key: "CSOR",
    label: "CSOR",
    id: 10,
    lock: 10,
    icon: <RatioCSOR key="ratio-csor" />,
    unit: "bbl/bbl"
  },
  IWB: {
    key: "IWB",
    label: "IWB",
    id: 16,
    icon: <RatioIWB key="ratio-iwb" />,
    unit: "%"
  },
  CWB: {
    key: "CWB",
    label: "CWB",
    id: 17,
    icon: <RatioCWB key="ratio-cwb" />,
    unit: "%"
  },
  // percent
  OilCut: {
    key: "Oil Cut",
    label: "Oil Cut",
    id: 8,
    icon: <OilCut key="oil-cut" />,
    unit: "%"
  },
  WaterCut: {
    key: "Water Cut",
    label: "Water Cut",
    id: 11,
    icon: <WaterCut key="water-cut" />,
    unit: "%"
  },
  OnTime: {
    key: "OnTime",
    label: "On-Time",
    id: 20,
    icon: <OnTimePercent key="on-time" />,
    unit: "%"
  },
  Sulphur: {
    id: 21,
    key: "Sulphur",
    label: "Sulphur",
    icon: <Sulphur key="sulphur" />,
    available: hasMidstream,
    tooltip: "Solid Sulphur processed from Gas"
  },
  SulphurYieldsOutlet: {
    id: 22,
    key: "Sulphur Yields Sales",
    label: "Sulphur Yields Outlet",
    icon: (
      <RatioIcon
        key="sales-sulphur"
        numeratorIcon={<Sulphur />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    available: hasMidstream,
    tooltip: "Sulphur divided by Total Outlet Gas"
  },
  SulphurYieldsInlet: {
    id: 23,
    key: "Sulphur Yields Raw",
    label: "Sulphur Yields Inlet",
    icon: (
      <RatioIcon
        key="raw-sulphur"
        numeratorIcon={<Sulphur />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    available: hasMidstream,
    tooltip: "Sulphur divided by Total Inlet Gas"
  },
  C2: {
    id: 32,
    key: "C2",
    label: "C₂",
    available: hasPlantLiquids,
    icon: <C2 key="c2" />,
    tooltip: "Sum of liquid C2-SP and C2-MX processed from Gas",
    unit: "bbl"
  },
  C2YieldsRaw: {
    id: 40,
    key: "C2 Yields Raw",
    label: "C₂ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c2" numeratorIcon={<C2 />} denominatorIcon={<ProductGas />} />
    ),
    tooltip: "Ethane divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  C2YieldsSales: {
    id: 48,
    key: "C2 Yields Sales",
    label: "C₂ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c2" numeratorIcon={<C2 />} denominatorIcon={<GasSales />} />
    ),
    tooltip: "Ethane divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  C3: {
    id: 33,
    key: "C3",
    label: "C₃",
    available: hasPlantLiquids,
    icon: <C3 key="c3" />,
    tooltip: "Sum of liquid C3-SP and C3-MX processed from Gas",
    unit: "bbl"
  },
  C3YieldsRaw: {
    id: 41,
    key: "C3 Yields Raw",
    label: "C₃ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c3" numeratorIcon={<C3 />} denominatorIcon={<ProductGas />} />
    ),
    tooltip: "Propane divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  C3YieldsSales: {
    id: 49,
    key: "C3 Yields Sales",
    label: "C₃ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c3" numeratorIcon={<C3 />} denominatorIcon={<GasSales />} />
    ),
    tooltip: "Propane divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  C4: {
    id: 34,
    key: "C4",
    label: "C₄",
    available: hasPlantLiquids,
    icon: <C4 key="c4" />,
    tooltip: "Sum of liquid C4-SP and C4-MX processed from Gas",
    unit: "bbl"
  },
  C4YieldsRaw: {
    id: 42,
    key: "C4 Yields Raw",
    label: "C₄ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c4" numeratorIcon={<C4 />} denominatorIcon={<ProductGas />} />
    ),
    tooltip: "Butane divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  C4YieldsSales: {
    id: 50,
    key: "C4 Yields Sales",
    label: "C₄ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c4" numeratorIcon={<C4 />} denominatorIcon={<GasSales />} />
    ),
    tooltip: "Butane divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  C5: {
    id: 35,
    key: "C5",
    label: "C₅₊",
    available: hasPlantLiquids,
    icon: <C5 key="c5" />,
    tooltip: "Sum of liquid C5+-SP and C5+-MX products processed from Gas",
    unit: "bbl"
  },
  C5YieldsRaw: {
    id: 43,
    key: "C5 Yields Raw",
    label: "C₅₊ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c5" numeratorIcon={<C5 />} denominatorIcon={<ProductGas />} />
    ),
    tooltip: "Pentane+ divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  C5YieldsSales: {
    id: 51,
    key: "C5 Yields Sales",
    label: "C₅₊ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c5" numeratorIcon={<C5 />} denominatorIcon={<GasSales />} />
    ),
    tooltip: "Pentane+ divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  LiquidsYieldsRaw: {
    id: 45,
    key: "Liquids Yields Raw",
    label: "Liquids Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<ProductGas />}
      />
    ),
    tooltip: "Liquids divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  LiquidsYieldsSales: {
    id: 52,
    key: "Liquids Yields Sales",
    label: "Liquids Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-liquid-ngl-mix"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<GasSales />}
      />
    ),
    tooltip: "Liquids divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  NGL_234: {
    id: 39,
    key: "NGL_234",
    label: "NGL₂₃₄",
    available: hasPlantLiquids,
    icon: <NglMix234 key="ngl-234" />,
    tooltip: "Sum of liquid C₂, C₃, and C₄ processed from Gas",
    unit: "bbl"
  },
  NGLMix: {
    id: 38,
    key: "NGL Mix",
    label: "NGL Mix",
    available: hasPlantLiquids,
    icon: <NglMix key="ngl-mix" />,
    tooltip: "Sum of liquid C₂, C₃, C₄, and C₅₊ processed from Gas",
    unit: "bbl"
  },
  NGLMixYieldsRaw: {
    id: 46,
    key: "NGL Mix Yields Raw",
    label: "NGL Mix Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<ProductGas />}
      />
    ),
    tooltip: "NGL Mix divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  NGLMixYieldsSales: {
    id: 54,
    key: "NGL Mix Yields Sales",
    label: "NGL Mix Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<GasSales />}
      />
    ),
    tooltip: "NGL Mix divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  NGL_234YieldsRaw: {
    id: 47,
    key: "NGL_234 Yields Raw",
    label: "NGL₂₃₄ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<ProductGas />}
      />
    ),
    tooltip: "NGL₂₃₄ divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  NGL_234YieldsSales: {
    id: 55,
    key: "NGL_234 Yields Sales",
    label: "NGL₂₃₄ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<GasSales />}
      />
    ),
    tooltip: "NGL₂₃₄ divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  OilCondC5: {
    id: 37,
    key: "Oil+Cond+C5",
    label: "Oil, Cond, C₅₊",
    available: hasPlantLiquids,
    icon: <OilCondC5 key="oil-cond-c5" />,
    tooltip: "Oil, Condensate, and Plant C₅₊ combined",
    unit: "bbl"
  },
  OilCondC5YieldsRaw: {
    id: 44,
    key: "Oil+Cond+C5 Yields Raw",
    label: "Oil, Cond, C₅₊ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<ProductGas />}
      />
    ),
    tooltip: "Oil, Cond, C₅₊ divided by Wellhead Gas",
    unit: "bbl/mmcf"
  },
  OilCondC5YieldsSales: {
    id: 53,
    key: "Oil+Cond+C5 Yields Sales",
    label: "Oil, Cond, C₅₊ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<GasSales />}
      />
    ),
    tooltip: "Oil, Cond, C₅₊ divided by Sales Gas",
    unit: "bbl/mmcf"
  },
  SalesGas: {
    id: 30,
    key: "Sales Gas",
    label: "Sales Gas",
    available: hasPlantLiquids,
    icon: <GasSales key="sales-gas" />,
    tooltip: "Plant outlet gas that's at sales spec.",
    unit: "mcf"
  },
  SalesLiquids: {
    id: 36,
    key: "Sales Liquids",
    label: "Sales Liquids",
    available: hasPlantLiquids,
    icon: <SalesLiquids key="yields-sales-liquids" />,
    tooltip: "Sum of C₂, C₃, C₄, stablized.",
    unit: "bbl"
  },
  Shrinkage: {
    id: 31,
    key: "Shrinkage",
    label: "Shrinkage",
    available: hasPlantLiquids,
    icon: <GasShrinkage key="shrinkage" />,
    tooltip: "Shrinkage is (1 - (Sales Gas/Raw Gas))",
    unit: "%"
  },
  TotalShrinkageInlet: {
    id: 86,
    key: "Total Shrinkage Inlet",
    label: "Total Shrinkage Inlet",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-oil-cond-c5"
        numeratorIcon={<GasShrinkage />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Total Gas difference from inlet to outlet divided by Total Inlet Gas"
  },
  TotalShrinkageOutlet: {
    id: 87,
    key: "Total Shrinkage Outlet",
    label: "Total Shrinkage Outlet",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-oil-cond-c5"
        numeratorIcon={<GasShrinkage />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Total Gas difference from inlet to outlet divided by Total Outlet Gas"
  },
  GasEnergy: {
    id: 56,
    key: "Gas Energy",
    label: "Gas Energy",
    available: hasPlantLiquids,
    icon: <GasEnergy key="gasEnergy" />,
    tooltip: "How much energy is stored in the Gas",
    unit: "mmbtu"
  },
  GasEnergyYieldsSales: {
    id: 57,
    key: "Gas Energy Yields Sales",
    label: "Heating Value",
    available: hasPlantLiquids,
    icon: <HeatingValue key="sales-gasEnergy" />,
    tooltip: "Amount of energy from the combustion of Gas",
    unit: "btu/scf"
  },
  SalesBOE: {
    id: 59,
    key: "Sales BOE",
    label: "Sales BOE",
    available: hasPlantLiquids,
    icon: <ProductBoe key="sales-boe" />,
    tooltip: "Barrel of Oil Equivalent Leaving the Plant Ready for Sale",
    unit: "boe"
  },
  GPWater: {
    id: 60,
    key: "GP Water",
    label: "GP Water",
    available: hasMidstream,
    icon: <GPWater key="gp-water" />,
    tooltip: "Water condensed from Gas at Gas Plants"
  },
  GPWaterYieldsOutlet: {
    id: 61,
    key: "GP Water Yields Sales",
    label: "GP Water Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="sales-gp-water"
        numeratorIcon={<GPWater />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "GP Water divided by the Total Outlet Gas"
  },
  GPWaterYieldsInlet: {
    id: 62,
    key: "GP Water Yields Raw",
    label: "GP Water Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="raw-gp-water"
        numeratorIcon={<GPWater />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "GP Water divided by the Total Inlet Gas"
  },
  CasingPressure: {
    id: 63,
    key: "Casing Pressure",
    label: "CSG Pressure",
    icon: <CasingPressure />,
    tooltip: "Pressure measured in the well’s casing"
  },
  TubingPressure: {
    id: 64,
    key: "Tubing Pressure",
    label: "TBG Pressure",
    icon: <TubingPressure />,
    tooltip: "Pressure measured in the tubing"
  },
  CO_2: {
    id: 65,
    key: "CO2",
    label: "CO₂",
    available: hasMidstream,
    icon: <CO2 key="co2" />,
    tooltip: "Estimated Carbon Dioxide equivalent emissions"
  },
  InletUtilization: {
    id: 66,
    key: "Inlet Utilization",
    label: "Inlet Utilization",
    available: hasMidstream,
    icon: <InletUtilization key="inlet-utilization" />,
    tooltip: "Total Inlet Gas divided by licensed capacity"
  },
  CO_2YieldsOutlet: {
    id: 67,
    key: "CO2 Yields Sales",
    label: "CO₂ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="sales-co2"
        numeratorIcon={<CO2 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "CO₂ divided by the Total Outlet Gas"
  },
  CO_2YieldsInlet: {
    id: 68,
    key: "CO2 Yields Raw",
    label: "CO₂ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="raw-co2" numeratorIcon={<CO2 />} denominatorIcon={<TotalInlet />} />
    ),
    tooltip: "CO₂ divided by the Total Inlet Gas"
  },
  LiquidsShrinkage: {
    id: 69,
    key: "Liquids Shrinkage",
    label: "Liquids Shrinkage",
    available: hasMidstream,
    icon: <LiquidsShrinkage key="liquids-shrinkage" />,
    tooltip: "Reduction in Gas due to Liquids extraction"
  },
  AcidGasShrinkage: {
    id: 70,
    key: "Acid Gas Shrinkage",
    label: "Acid Gas Shrinkage",
    available: hasMidstream,
    icon: <AcidGasShrinkage key="acid-gas-shrinkage" />,
    tooltip: "Reduction in Gas due to Sulphur or Acid Gas extraction"
  },
  AcidGasDisposition: {
    id: 71,
    key: "Acid Gas Disposition",
    label: "Acid Gas Disposition",
    available: hasMidstream,
    icon: <AcidGasDisposition key="acid-gas-disposition" />,
    tooltip: "Acid Gas Disposition from the facility"
  },
  LiquidsShrinkageInletRate: {
    id: 72,
    key: "Liquids Shrinkage Inlet Rate",
    label: "Liquids Shrinkage Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="liquids-shrinkage-inlet-rate"
        numeratorIcon={<LiquidsShrinkage />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Liquids Shrinkage divided by Total Inlet Gas"
  },
  AcidGasShrinkageInletRate: {
    id: 73,
    key: "Acid Gas Shrinkage Inlet Rate",
    label: "Acid Gas Shrinkage Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="acid-gas-shrinkage-inlet-rate"
        numeratorIcon={<AcidGasShrinkage />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Acid Gas Shrinkage divided by Total Inlet Gas"
  },
  AcidGasDispositionInletRate: {
    id: 74,
    key: "Acid Gas Disposition Inlet Rate",
    label: "Acid Gas Disposition Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="acid-gas-disposition-inlet-rate"
        numeratorIcon={<AcidGasDisposition />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Acid Gas Disposition divided by Total Inlet Gas"
  },
  LiquidsShrinkageOutletRate: {
    id: 75,
    key: "Liquids Shrinkage Outlet Rate",
    label: "Liquids Shrinkage Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="liquids-shrinkage-outlet-rate"
        numeratorIcon={<LiquidsShrinkage />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Liquids Shrinkage divided by Total Outlet Gas"
  },
  AcidGasShrinkageOutletRate: {
    id: 76,
    key: "Acid Gas Shrinkage Outlet Rate",
    label: "Acid Gas Shrinkage Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="acid-gas-shrinkage-outlet-rate"
        numeratorIcon={<AcidGasShrinkage />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Acid Gas Shrinkage divided by Total Outlet Gas"
  },
  AcidGasDispositionOutletRate: {
    id: 77,
    key: "Acid Gas Disposition Outlet Rate",
    label: "Acid Gas Disposition Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="acid-gas-disposition-outlet-rate"
        numeratorIcon={<AcidGasDisposition />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Acid Gas Disposition divided by Total Outlet Gas"
  },
  FuelInletRate: {
    id: 78,
    key: "Fuel Inlet Rate",
    label: "Fuel Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="fuel-inlet-rate"
        numeratorIcon={<Fuel />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Fuel divided by Total Inlet Gas"
  },
  FuelOutletRate: {
    id: 79,
    key: "Fuel Outlet Rate",
    label: "Fuel Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="fuel-outlet-rate"
        numeratorIcon={<Fuel />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Fuel divided by Total Outlet Gas"
  },
  FlaredInletRate: {
    id: 80,
    key: "Flared Inlet Rate",
    label: "Flared Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="flared-inlet-rate"
        numeratorIcon={<Flared />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Flared divided by Total Inlet Gas"
  },
  FlaredOutletRate: {
    id: 81,
    key: "Flared Outlet Rate",
    label: "Flared Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="flared-outlet-rate"
        numeratorIcon={<Flared />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Flared divided by Total Outlet Gas"
  },
  VentedInletRate: {
    id: 82,
    key: "Vented Inlet Rate",
    label: "Vented Inlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="vented-inlet-rate"
        numeratorIcon={<Vented />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Vented divided by Total Inlet Gas"
  },
  VentedOutletRate: {
    id: 83,
    key: "Vented Outlet Rate",
    label: "Vented Outlet Rate",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="vented-outlet-rate"
        numeratorIcon={<Vented />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Vented divided by Total Outlet Gas"
  },
  FFV: {
    id: 84,
    key: "FFV",
    label: "FFV",
    available: hasMidstream,
    icon: <FFV key="ffv" />,
    tooltip: "Sum of the Fuel, Flare, and Vented Gas"
  },
  FFVInletRate: {
    id: 85,
    key: "FFV Inlet Rate",
    label: "FFV Inlet Rate",
    available: hasMidstream,
    icon: <FFVRatioIcon key="ffv-inlet-rate" denominatorIcon={<TotalInlet />} />,
    tooltip: "FFV divided by Total Inlet Gas"
  },
  FFVOutletRate: {
    id: 86,
    key: "FFV Outlet Rate",
    label: "FFV Outlet Rate",
    available: hasMidstream,
    icon: <FFVRatioIcon key="ffv-outlet-rate" denominatorIcon={<TotalOutlet />} />,
    tooltip: "FFV divided by Total Outlet Gas"
  },
  InNetworkOutlet: {
    id: 89,
    key: "In-Network Outlet",
    label: "In-Network Outlet",
    available: hasMidstream,
    icon: <InNetworkOutlet key="in-network-outlet" />,
    tooltip: "Outlet to a facility within the royalty network"
  },
  SalesOutlet: {
    id: 90,
    key: "Sales Outlet",
    label: "Sales Outlet",
    available: hasMidstream,
    icon: <SalesOutlet key="sales-outlet" />,
    tooltip: "Outlet to a sales point outside the royalty network"
  },
  OutOfNetworkInlet: {
    id: 91,
    key: "Out-of-Network Inlet",
    label: "Out-of-Network Inlet",
    available: hasMidstream,
    icon: <OutOfNetworkInlet key="out-of-network-inlet" />,
    tooltip: "Gas entering a facility from outside of the royalty network"
  },
  InNetworkInlet: {
    id: 92,
    key: "In-Network Inlet",
    label: "In-Network Inlet",
    available: hasMidstream,
    icon: <InNetworkInlet key="in-network-inlet" />,
    tooltip: "Gas entering a facility from within the royalty network"
  },
  TotalOutlet: {
    id: 93,
    key: "Total Outlet",
    label: "Total Outlet",
    available: hasMidstream,
    icon: <TotalOutlet key="total-outlet" />,
    tooltip: "All Gas leaving the facility"
  },
  TotalInlet: {
    key: "Total Inlet",
    label: "Total Inlet",
    id: 94,
    available: hasMidstream,
    icon: <TotalInlet key="product-gas-inlet" />,
    tooltip: "All Gas entering the facility"
  },

  /*** midstream products **/
  H2S: {
    key: "H2S",
    label: "H2S",
    id: 102,
    icon: <ProductGas key="product-h2s" />,
    available: hasMidstream,
    tooltip: "Total amount of produced Sulphur"
  },
  Fuel: {
    key: "Fuel",
    label: "Fuel",
    id: 103,
    icon: <Fuel key="product-fuel" />,
    available: hasMidstream,
    tooltip: "Gas burned as fuel at the facility"
  },
  Flared: {
    key: "Flared",
    label: "Flared",
    id: 104,
    icon: <Flared key="product-flare" />,
    available: hasMidstream,
    tooltip: "Gas burned via flaring at the facility"
  },
  Vented: {
    key: "Vented",
    label: "Vented",
    id: 105,
    icon: <Vented key="product-vent" />,
    available: hasMidstream,
    tooltip: "Gas released to the atmosphere at the facility"
  },
  PlantShrinkage: {
    key: "Plant Shrinkage",
    label: "Plant Shrinkage",
    id: 105,
    icon: <GasShrinkage key="product-plant-shrinkage" />,
    available: hasMidstream,
    tooltip: "Percentage of volume or product lost within plant operations"
  },
  C2YieldsInlet: {
    id: 108,
    key: "C2 Yields Raw",
    label: "C₂ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c2" numeratorIcon={<C2 />} denominatorIcon={<TotalInlet />} />
    ),
    tooltip: "Ethane divided by Total Inlet Gas"
  },
  C2YieldsOutlet: {
    id: 109,
    key: "C2 Yields Sales",
    label: "C₂ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-c2"
        numeratorIcon={<C2 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Ethane divided by Total Outlet Gas"
  },
  C3YieldsInlet: {
    id: 110,
    key: "C3 Yields Raw",
    label: "C₃ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c3" numeratorIcon={<C3 />} denominatorIcon={<TotalInlet />} />
    ),
    tooltip: "Propane divided by Total Inlet Gas"
  },
  C3YieldsOutlet: {
    id: 111,
    key: "C3 Yields Sales",
    label: "C₃ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-c3"
        numeratorIcon={<C3 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Propane divided by Total Outlet Gas"
  },
  C4YieldsInlet: {
    id: 112,
    key: "C4 Yields Raw",
    label: "C₄ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c4" numeratorIcon={<C4 />} denominatorIcon={<TotalInlet />} />
    ),
    tooltip: "Butane divided by Total Inlet Gas"
  },
  C4YieldsOutlet: {
    id: 113,
    key: "C4 Yields Sales",
    label: "C₄ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-c4"
        numeratorIcon={<C4 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Butane divided by Total Outlet Gas"
  },
  C5YieldsInlet: {
    id: 114,
    key: "C5 Yields Raw",
    label: "C₅₊ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c5" numeratorIcon={<C5 />} denominatorIcon={<TotalInlet />} />
    ),
    tooltip: "Pentane+ divided by Total Inlet Gas"
  },
  C5YieldsOutlet: {
    id: 115,
    key: "C5 Yields Sales",
    label: "C₅₊ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-c5"
        numeratorIcon={<C5 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Pentane+ divided by Total Outlet Gas"
  },
  LiquidsYieldsInlet: {
    id: 116,
    key: "Liquids Yields Raw",
    label: "Liquids Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Total Liquids divided by Inlet Gas"
  },
  LiquidsYieldsOutlet: {
    id: 117,
    key: "Liquids Yields Sales",
    label: "Liquids Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Total Liquids divided by Outlet Gas"
  },
  NGLMixYieldsInlet: {
    id: 118,
    key: "NGL Mix Yields Raw",
    label: "NGL Mix Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "NGL Mix divided by Total Inlet Gas"
  },
  NGLMixYieldsOutlet: {
    id: 119,
    key: "NGL Mix Yields Sales",
    label: "NGL Mix Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "NGL Mix divided by Total Outlet Gas"
  },
  NGL_234YieldsInlet: {
    id: 120,
    key: "NGL_234 Yields Raw",
    label: "NGL₂₃₄ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "NGL₂₃₄ divided by Total Inlet Gas"
  },
  NGL_234YieldsOutlet: {
    id: 121,
    key: "NGL_234 Yields Sales",
    label: "NGL₂₃₄ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "NGL₂₃₄ divided by Total Outlet Gas"
  },
  OilCondC5YieldsInlet: {
    id: 122,
    key: "Oil+Cond+C5 Yields Raw",
    label: "Oil, Cond, C₅₊ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<TotalInlet />}
      />
    ),
    tooltip: "Oil, Cond, C₅₊ divided by Inlet Gas"
  },
  OilCondC5YieldsOutlet: {
    id: 123,
    key: "Oil+Cond+C5 Yields Sales",
    label: "Oil, Cond, C₅₊ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<TotalOutlet />}
      />
    ),
    tooltip: "Oil, Cond, C₅₊ divided by Outlet Gas"
  }
};

/**
 * It returns the label of the product that matches the key variable from the ALL_PRODUCT_TYPES object.
 * If no product matches the key variable, it returns the key variable itself.
 *
 * @param {string} key - The key of the product.
 * @returns {string} - The label of the matched product or the key if no product matches.
 */
export function getProductLabel(key: string, entityKind?: string): string {
  let product;
  const hydrocarbonDict = {
    C2: "C₂",
    C3: "C₃",
    C4: "C₄",
    "C5+": "C₅₊"
  };
  if (key in hydrocarbonDict) {
    return hydrocarbonDict[key];
  }
  if (entityKind === EntityKind.Well) {
    product = Object.values(ALL_PRODUCT_TYPES).find((prod) => prod.key === key);
  } else {
    product = Object.values(ALL_PRODUCT_TYPES).find(
      (prod) => prod.key === key && prod.available === hasMidstream
    );
    if (!product) {
      product = Object.values(ALL_PRODUCT_TYPES).find((prod) => prod.key === key);
    }
  }
  return product ? product.label : key;
}
export type ChartTypeLabels =
  | "Rate Cum"
  | "Rate Time"
  | "Rate Date"
  | "Total Rate Date"
  | "Cum Time"
  | "Cross Plot"
  | "Probit"
  | "Box Plot"
  | "Stacked Bar"
  | "Pie"
  | "Mosaic"
  | "Material Balance Time"
  | "Total Rate Cum"
  | "CAGR"
  | "Decline Rate"
  | "Trend Date"
  | "Well Contribution";

export const ALL_CHART_TYPES: IAllChartTypes = {
  RateCum: { label: "Rate Cum", id: 1 },
  RateTime: { label: "Rate Time", id: 2 },
  RateDate: { label: "Rate Date", id: 3 },
  TotalRateDate: { label: "Total Rate Date", id: 4 },
  CumTime: { label: "Cum Time", id: 10 },
  CrossPlot: { label: "Cross Plot", id: 0, lock: { x: true, y: true } },
  Probit: { label: "Probit", id: 5, lock: { x: true, y: true } },
  BoxPlot: { label: "Box Plot", id: 6, lock: { x: false, y: true } },
  StackedBar: { label: "Stacked Bar", id: 8, lock: { x: false, y: true } },
  Pie: { label: "Pie", id: 7, lock: { x: false, y: false } },
  Mosaic: { label: "Mosaic", id: 9, lock: { x: false, y: false } },
  MaterialBalanceTime: {
    label: "Material Balance Time",
    id: 11,
    lock: { x: true, y: true }
  },
  TotalRateCum: { label: "Total Rate Cum", id: 12 },
  CAGR: {
    label: "CAGR",
    id: 13,
    tooltip: "Compound Annual Growth Rate"
  },
  BaseDeclineRate: {
    label: "Decline Rate",
    id: 14,
    tooltip: "Base Decline Rate"
  },
  TrendDate: { label: "Trend Date", id: 15 },
  WellContribution: { label: "Well Contribution", id: 16 }
};

export type ChartTypeT = {
  name: string;
  items: ChartTypeLabels[];
};

export const ChartType: ChartTypeT[] = [
  {
    name: "Average",
    items: [
      ALL_CHART_TYPES.RateCum.label,
      ALL_CHART_TYPES.RateTime.label,
      ALL_CHART_TYPES.CumTime.label
    ]
  },
  {
    name: "Aggregate",
    items: [
      ALL_CHART_TYPES.RateDate.label,
      ALL_CHART_TYPES.TotalRateDate.label,
      ALL_CHART_TYPES.TotalRateCum.label
    ]
  },
  {
    name: "",
    items: [
      ALL_CHART_TYPES.CrossPlot.label,
      ALL_CHART_TYPES.Probit.label,
      ALL_CHART_TYPES.BoxPlot.label,
      ALL_CHART_TYPES.StackedBar.label,
      ALL_CHART_TYPES.Pie.label,
      ALL_CHART_TYPES.Mosaic.label,
      ALL_CHART_TYPES.MaterialBalanceTime.label,
      ALL_CHART_TYPES.CAGR.label,
      ALL_CHART_TYPES.BaseDeclineRate.label,
      ALL_CHART_TYPES.TrendDate.label
    ]
  }
];

export const rateDateCategories = [
  "Percent",
  "Ratio",
  "Yields Raw",
  "Yields Sales",
  "Heating Value",
  "Yields Inlet",
  "Yields Outlet",
  "Percent Inlet",
  "Percent Outlet",
  "Shrinkage"
];

export const yieldsAndRawCatagories = [
  "Yields Raw",
  "Yields Sales",
  "Yields Inlet",
  "Yields Outlet",
  "Percent Inlet",
  "Percent Outlet"
];

const getMidStreamAggregateItems = (hasFacilityVolumes: boolean): ChartTypeLabels[] => {
  const items: ChartTypeLabels[] = [
    ALL_CHART_TYPES.RateDate.label,
    ALL_CHART_TYPES.TotalRateDate.label
  ];
  if (!hasFacilityVolumes) {
    items.push(ALL_CHART_TYPES.WellContribution.label);
  }
  return items;
};

export const getMidstreamChartTypes = (hasFacilityVolumes: boolean): ChartTypeT[] => [
  {
    name: "Aggregate",
    items: getMidStreamAggregateItems(hasFacilityVolumes)
  },
  {
    name: "",
    items: [ALL_CHART_TYPES.CrossPlot.label]
  }
];

export const CHART_TYPE_LABELS = Object.keys(ALL_CHART_TYPES).map(
  (key) => ALL_CHART_TYPES[key].label
);

export const ChartProductTypes = [
  ALL_CHART_TYPES.RateCum.label,
  ALL_CHART_TYPES.RateTime.label,
  ALL_CHART_TYPES.RateDate.label,
  ALL_CHART_TYPES.TotalRateDate.label,
  ALL_CHART_TYPES.CumTime.label,
  ALL_CHART_TYPES.MaterialBalanceTime.label,
  ALL_CHART_TYPES.TotalRateCum.label,
  ALL_CHART_TYPES.CAGR.label,
  ALL_CHART_TYPES.BaseDeclineRate.label
];
export type ProductTypeT = {
  name: string;
  items: string[];
};
export const SalesProduct: ProductTypeT[] = [
  {
    name: "Liquids",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5.label,
      ALL_PRODUCT_TYPES.NGL_234.label,
      ALL_PRODUCT_TYPES.NGLMix.label,
      ALL_PRODUCT_TYPES.SalesLiquids.label,
      ALL_PRODUCT_TYPES.C2.label,
      ALL_PRODUCT_TYPES.C3.label,
      ALL_PRODUCT_TYPES.C4.label,
      ALL_PRODUCT_TYPES.C5.label,
      ALL_PRODUCT_TYPES.SalesBOE.label
    ]
  },
  {
    name: "Gas",
    items: [
      ALL_PRODUCT_TYPES.SalesGas.label,
      ALL_PRODUCT_TYPES.GasEnergy.label,
      ALL_PRODUCT_TYPES.GasEnergyYieldsSales.label,
      ALL_PRODUCT_TYPES.Shrinkage.label
    ]
  },
  {
    name: "Yields Raw",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsRaw.label,
      ALL_PRODUCT_TYPES.NGL_234YieldsRaw.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsRaw.label,
      ALL_PRODUCT_TYPES.LiquidsYieldsRaw.label,
      ALL_PRODUCT_TYPES.C2YieldsRaw.label,
      ALL_PRODUCT_TYPES.C3YieldsRaw.label,
      ALL_PRODUCT_TYPES.C4YieldsRaw.label,
      ALL_PRODUCT_TYPES.C5YieldsRaw.label
    ]
  },
  {
    name: "Yields Sales",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsSales.label,
      ALL_PRODUCT_TYPES.NGL_234YieldsSales.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsSales.label,
      ALL_PRODUCT_TYPES.LiquidsYieldsSales.label,
      ALL_PRODUCT_TYPES.C2YieldsSales.label,
      ALL_PRODUCT_TYPES.C3YieldsSales.label,
      ALL_PRODUCT_TYPES.C4YieldsSales.label,
      ALL_PRODUCT_TYPES.C5YieldsSales.label
    ]
  }
];

export const SalesProductList = SalesProduct.flatMap((category) => category.items);

export const CrossKindProduct = [
  {
    group: ALL_PRODUCT_TYPES.Oil.key,
    items: [ALL_PRODUCT_TYPES.OilCondC5]
  },
  {
    group: ALL_PRODUCT_TYPES.CGR.key,
    items: [ALL_PRODUCT_TYPES.OilCondC5YieldsRaw, ALL_PRODUCT_TYPES.OilCondC5YieldsSales]
  }
];

export const CrossKindProductList = CrossKindProduct.flatMap((category) =>
  category.items.map((item) => item.label)
);

export const ProductKind = {
  Summary: 0,
  Wellhead: 1,
  Sales: 2,
  Throughput: 3,
  Injection: 4,
  Shrinkage: 5
};

export const ChartProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.TotalFluid.label,
      ALL_PRODUCT_TYPES.BOE.label,
      ALL_PRODUCT_TYPES.CasingPressure.label,
      ALL_PRODUCT_TYPES.TubingPressure.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.CGR.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label
    ]
  },
  {
    name: "Percent",
    items: [
      ALL_PRODUCT_TYPES.OilCut.label,
      ALL_PRODUCT_TYPES.WaterCut.label,
      ALL_PRODUCT_TYPES.OnTime.label
    ]
  }
];

export const ProductForSelection: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.TotalFluid.label,
      ALL_PRODUCT_TYPES.BOE.label
    ]
  }
];

export const SummaryProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.BOE.label,
      ALL_PRODUCT_TYPES.SalesBOE.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsRaw.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label
    ]
  },
  {
    name: "Percent",
    items: [ALL_PRODUCT_TYPES.OilCut.label, ALL_PRODUCT_TYPES.WaterCut.label]
  }
];
export const ThroughputUniqueProduct: ProductTypeT[] = [
  {
    name: "Throughput",
    items: [
      ALL_PRODUCT_TYPES.TotalInlet.label,
      ALL_PRODUCT_TYPES.InNetworkInlet.label,
      ALL_PRODUCT_TYPES.OutOfNetworkInlet.label,
      ALL_PRODUCT_TYPES.InletUtilization.label,
      ALL_PRODUCT_TYPES.TotalOutlet.label,
      ALL_PRODUCT_TYPES.InNetworkOutlet.label,
      ALL_PRODUCT_TYPES.SalesOutlet.label,
      ALL_PRODUCT_TYPES.Fuel.label,
      ALL_PRODUCT_TYPES.Flared.label,
      ALL_PRODUCT_TYPES.Vented.label,
      ALL_PRODUCT_TYPES.PlantShrinkage.label,
      ALL_PRODUCT_TYPES.Sulphur.label,
      ALL_PRODUCT_TYPES.GPWater.label,
      ALL_PRODUCT_TYPES.CO_2.label
    ]
  }
];
export const ThroughputProduct: ProductTypeT[] = [
  {
    name: "Gas",
    items: [
      ALL_PRODUCT_TYPES.TotalInlet.label,
      ALL_PRODUCT_TYPES.InNetworkInlet.label,
      ALL_PRODUCT_TYPES.OutOfNetworkInlet.label,
      ALL_PRODUCT_TYPES.InletUtilization.label,
      ALL_PRODUCT_TYPES.TotalOutlet.label,
      ALL_PRODUCT_TYPES.InNetworkOutlet.label,
      ALL_PRODUCT_TYPES.SalesOutlet.label
    ]
  },
  {
    name: "Products",
    items: [
      ALL_PRODUCT_TYPES.NGL_234.label,
      ALL_PRODUCT_TYPES.NGLMix.label,
      ALL_PRODUCT_TYPES.C2.label,
      ALL_PRODUCT_TYPES.C3.label,
      ALL_PRODUCT_TYPES.C4.label,
      ALL_PRODUCT_TYPES.C5.label,
      ALL_PRODUCT_TYPES.Sulphur.label,
      ALL_PRODUCT_TYPES.GPWater.label,
      ALL_PRODUCT_TYPES.CO_2.label
    ]
  },
  {
    name: "Yields Inlet",
    items: [
      ALL_PRODUCT_TYPES.NGL_234YieldsInlet.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsInlet.label,
      ALL_PRODUCT_TYPES.C2YieldsInlet.label,
      ALL_PRODUCT_TYPES.C3YieldsInlet.label,
      ALL_PRODUCT_TYPES.C4YieldsInlet.label,
      ALL_PRODUCT_TYPES.C5YieldsInlet.label,
      ALL_PRODUCT_TYPES.SulphurYieldsInlet.label,
      ALL_PRODUCT_TYPES.GPWaterYieldsInlet.label,
      ALL_PRODUCT_TYPES.CO_2YieldsInlet.label
    ]
  },
  {
    name: "Yields Outlet",
    items: [
      ALL_PRODUCT_TYPES.NGL_234YieldsOutlet.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsOutlet.label,
      ALL_PRODUCT_TYPES.C2YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C3YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C4YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C5YieldsOutlet.label,
      ALL_PRODUCT_TYPES.SulphurYieldsOutlet.label,
      ALL_PRODUCT_TYPES.GPWaterYieldsOutlet.label,
      ALL_PRODUCT_TYPES.CO_2YieldsOutlet.label
    ]
  }
];

export const InjectionProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.WaterInj.label,
      ALL_PRODUCT_TYPES.GasInj.label,
      ALL_PRODUCT_TYPES.PolymerInj.label,
      ALL_PRODUCT_TYPES.TotalLiquidInj.label,
      ALL_PRODUCT_TYPES.CO_2Inj.label,
      ALL_PRODUCT_TYPES.AcidGasInj.label,
      ALL_PRODUCT_TYPES.SolventInj.label,
      ALL_PRODUCT_TYPES.TotalGasInj.label,
      ALL_PRODUCT_TYPES.SteamInj.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.ISOR.label,
      ALL_PRODUCT_TYPES.CSOR.label,
      ALL_PRODUCT_TYPES.IWB.label,
      ALL_PRODUCT_TYPES.CWB.label
    ]
  }
];

export const ShrinkageProduct: ProductTypeT[] = [
  {
    name: "Shrinkage Gas",
    items: [
      ALL_PRODUCT_TYPES.Fuel.label,
      ALL_PRODUCT_TYPES.Flared.label,
      ALL_PRODUCT_TYPES.Vented.label,
      ALL_PRODUCT_TYPES.FFV.label,
      ALL_PRODUCT_TYPES.LiquidsShrinkage.label,
      ALL_PRODUCT_TYPES.AcidGasShrinkage.label,
      ALL_PRODUCT_TYPES.AcidGasDisposition.label
    ]
  },
  {
    name: "Percent Inlet",
    items: [
      ALL_PRODUCT_TYPES.FuelInletRate.label,
      ALL_PRODUCT_TYPES.FlaredInletRate.label,
      ALL_PRODUCT_TYPES.VentedInletRate.label,
      ALL_PRODUCT_TYPES.FFVInletRate.label,
      ALL_PRODUCT_TYPES.LiquidsShrinkageInletRate.label,
      ALL_PRODUCT_TYPES.AcidGasShrinkageInletRate.label,
      ALL_PRODUCT_TYPES.AcidGasDispositionInletRate.label,
      ALL_PRODUCT_TYPES.TotalShrinkageInlet.label
    ]
  },
  {
    name: "Percent Outlet",
    items: [
      ALL_PRODUCT_TYPES.FuelOutletRate.label,
      ALL_PRODUCT_TYPES.FlaredOutletRate.label,
      ALL_PRODUCT_TYPES.VentedOutletRate.label,
      ALL_PRODUCT_TYPES.FFVOutletRate.label,
      ALL_PRODUCT_TYPES.LiquidsShrinkageOutletRate.label,
      ALL_PRODUCT_TYPES.AcidGasShrinkageOutletRate.label,
      ALL_PRODUCT_TYPES.AcidGasDispositionOutletRate.label,
      ALL_PRODUCT_TYPES.TotalShrinkageOutlet.label
    ]
  }
];

export const MaterialBalanceTimeChartProducts = [
  ALL_PRODUCT_TYPES.Oil.label,
  ALL_PRODUCT_TYPES.Gas.label,
  ALL_PRODUCT_TYPES.Water.label,
  ALL_PRODUCT_TYPES.TotalFluid.label,
  ALL_PRODUCT_TYPES.BOE.label
];

export const PERFORMANCE_PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil,
  ALL_PRODUCT_TYPES.Gas,
  ALL_PRODUCT_TYPES.Water,
  ALL_PRODUCT_TYPES.TotalFluid,
  ALL_PRODUCT_TYPES.BOE,
  ALL_PRODUCT_TYPES.CGR,
  ALL_PRODUCT_TYPES.GOR,
  ALL_PRODUCT_TYPES.WGR,
  ALL_PRODUCT_TYPES.WOR,
  ALL_PRODUCT_TYPES.ISOR,
  ALL_PRODUCT_TYPES.CSOR,
  ALL_PRODUCT_TYPES.IWB,
  ALL_PRODUCT_TYPES.CWB,
  ALL_PRODUCT_TYPES.SteamInj,
  ALL_PRODUCT_TYPES.GasInj,
  ALL_PRODUCT_TYPES.WaterInj,
  ALL_PRODUCT_TYPES.PolymerInj,
  ALL_PRODUCT_TYPES.CO_2Inj,
  ALL_PRODUCT_TYPES.AcidGasInj,
  ALL_PRODUCT_TYPES.SolventInj,
  ALL_PRODUCT_TYPES.TotalLiquidInj,
  ALL_PRODUCT_TYPES.TotalGasInj,
  ALL_PRODUCT_TYPES.OilCut,
  ALL_PRODUCT_TYPES.WaterCut,
  ALL_PRODUCT_TYPES.SalesGas,
  ALL_PRODUCT_TYPES.GasEnergy,
  ALL_PRODUCT_TYPES.GasEnergyYieldsSales,
  ALL_PRODUCT_TYPES.Shrinkage,
  ALL_PRODUCT_TYPES.TotalShrinkageInlet,
  ALL_PRODUCT_TYPES.TotalShrinkageOutlet,
  ALL_PRODUCT_TYPES.C2,
  ALL_PRODUCT_TYPES.C3,
  ALL_PRODUCT_TYPES.C4,
  ALL_PRODUCT_TYPES.C5,
  ALL_PRODUCT_TYPES.SalesLiquids,
  ALL_PRODUCT_TYPES.SalesBOE,
  ALL_PRODUCT_TYPES.OilCondC5,
  ALL_PRODUCT_TYPES.NGLMix,
  ALL_PRODUCT_TYPES.NGL_234,
  ALL_PRODUCT_TYPES.C2YieldsRaw,
  ALL_PRODUCT_TYPES.C3YieldsRaw,
  ALL_PRODUCT_TYPES.C4YieldsRaw,
  ALL_PRODUCT_TYPES.C5YieldsRaw,
  ALL_PRODUCT_TYPES.OilCondC5YieldsRaw,
  ALL_PRODUCT_TYPES.LiquidsYieldsRaw,
  ALL_PRODUCT_TYPES.NGLMixYieldsRaw,
  ALL_PRODUCT_TYPES.NGL_234YieldsRaw,
  ALL_PRODUCT_TYPES.C2YieldsSales,
  ALL_PRODUCT_TYPES.C3YieldsSales,
  ALL_PRODUCT_TYPES.C4YieldsSales,
  ALL_PRODUCT_TYPES.C5YieldsSales,
  ALL_PRODUCT_TYPES.LiquidsYieldsSales,
  ALL_PRODUCT_TYPES.OilCondC5YieldsSales,
  ALL_PRODUCT_TYPES.NGLMixYieldsSales,
  ALL_PRODUCT_TYPES.NGL_234YieldsSales,
  ALL_PRODUCT_TYPES.Fuel,
  ALL_PRODUCT_TYPES.FuelInletRate,
  ALL_PRODUCT_TYPES.FuelOutletRate,
  ALL_PRODUCT_TYPES.Flared,
  ALL_PRODUCT_TYPES.FlaredInletRate,
  ALL_PRODUCT_TYPES.FlaredOutletRate,
  ALL_PRODUCT_TYPES.Vented,
  ALL_PRODUCT_TYPES.VentedInletRate,
  ALL_PRODUCT_TYPES.VentedOutletRate,
  ALL_PRODUCT_TYPES.PlantShrinkage,
  ALL_PRODUCT_TYPES.Sulphur,
  ALL_PRODUCT_TYPES.SulphurYieldsInlet,
  ALL_PRODUCT_TYPES.SulphurYieldsOutlet,
  ALL_PRODUCT_TYPES.GPWater,
  ALL_PRODUCT_TYPES.GPWaterYieldsInlet,
  ALL_PRODUCT_TYPES.GPWaterYieldsOutlet,
  ALL_PRODUCT_TYPES.CO_2,
  ALL_PRODUCT_TYPES.CO_2YieldsInlet,
  ALL_PRODUCT_TYPES.CO_2YieldsOutlet,
  ALL_PRODUCT_TYPES.InletUtilization,
  ALL_PRODUCT_TYPES.LiquidsShrinkage,
  ALL_PRODUCT_TYPES.LiquidsShrinkageInletRate,
  ALL_PRODUCT_TYPES.LiquidsShrinkageOutletRate,
  ALL_PRODUCT_TYPES.AcidGasShrinkage,
  ALL_PRODUCT_TYPES.AcidGasShrinkageInletRate,
  ALL_PRODUCT_TYPES.AcidGasShrinkageOutletRate,
  ALL_PRODUCT_TYPES.AcidGasDisposition,
  ALL_PRODUCT_TYPES.AcidGasDispositionInletRate,
  ALL_PRODUCT_TYPES.AcidGasDispositionOutletRate,
  ALL_PRODUCT_TYPES.FFV,
  ALL_PRODUCT_TYPES.FFVInletRate,
  ALL_PRODUCT_TYPES.FFVOutletRate,
  ALL_PRODUCT_TYPES.TotalInlet,
  ALL_PRODUCT_TYPES.InNetworkInlet,
  ALL_PRODUCT_TYPES.OutOfNetworkInlet,
  ALL_PRODUCT_TYPES.TotalOutlet,
  ALL_PRODUCT_TYPES.InNetworkOutlet,
  ALL_PRODUCT_TYPES.SalesOutlet
];

export const FORECAST_PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil,
  ALL_PRODUCT_TYPES.Gas,
  ALL_PRODUCT_TYPES.Water
];

export const PRODUCT_COLOR = {
  condensate: "#1bce0c",
  gas: "#fd0e1a",
  oil: "#186b2d",
  water: "#025aff"
};

export const PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil.label,
  ALL_PRODUCT_TYPES.Gas.label,
  ALL_PRODUCT_TYPES.Water.label,
  ALL_PRODUCT_TYPES.TotalFluid.label,
  ALL_PRODUCT_TYPES.GOR.label,
  ALL_PRODUCT_TYPES.CGR.label,
  ALL_PRODUCT_TYPES.WGR.label,
  ALL_PRODUCT_TYPES.OilCut.label,
  ALL_PRODUCT_TYPES.WaterCut.label
];

// ** Start Multiproduct Constants ** //

export const ChartTypeMultiProduct = [
  {
    name: "Chart Type",
    items: [
      ALL_CHART_TYPES.RateCum.label,
      ALL_CHART_TYPES.RateTime.label,
      ALL_CHART_TYPES.CumTime.label,
      ALL_CHART_TYPES.RateDate.label
    ]
  }
];

export const ProductsForMultiProductChart = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.TotalFluid.label,
      ALL_PRODUCT_TYPES.BOE.label,
      ALL_PRODUCT_TYPES.WaterInj.label,
      ALL_PRODUCT_TYPES.GasInj.label,
      ALL_PRODUCT_TYPES.SteamInj.label,
      ALL_PRODUCT_TYPES.PolymerInj.label,
      ALL_PRODUCT_TYPES.CO_2Inj.label,
      ALL_PRODUCT_TYPES.AcidGasInj.label,
      ALL_PRODUCT_TYPES.SolventInj.label,
      ALL_PRODUCT_TYPES.TotalLiquidInj.label,
      ALL_PRODUCT_TYPES.TotalGasInj.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.CGR.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label,
      ALL_PRODUCT_TYPES.ISOR.label,
      ALL_PRODUCT_TYPES.CSOR.label,
      ALL_PRODUCT_TYPES.IWB.label,
      ALL_PRODUCT_TYPES.CWB.label
    ]
  },
  {
    name: "Percent",
    items: [ALL_PRODUCT_TYPES.OilCut.label, ALL_PRODUCT_TYPES.WaterCut.label]
  }
];

// ** End Multiproduct Constants ** //

export const CHART_ACTION = Object.freeze({
  brushStart: {
    type: "takeGlobalCursor",
    key: "brush",
    brushOption: { brushType: "polygon" }
  },
  brushClear: {
    type: "brush",
    command: "clear",
    areas: []
  },
  brushEnd: {
    type: "takeGlobalCursor",
    key: "brush",
    brushOption: {
      brushType: false,
      brushMode: "single"
    }
  }
});
export const ORIGIN = { x: 0, y: 0 };
// chart component events
export const CHART_EVENT = Object.freeze({
  snapshotDownloadClick: "onSnapshotDownloadClicked",
  fullscreenToggle: "onFullscreenToggle"
});

// x and y-axis range defaults for fluids
export const CHART_RANGE = Object.freeze({
  oil: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  water: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  gas: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  cond: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  gor: { xMin: 0, xMax: "", yMin: 0, yMax: 10000 },
  wor: { xMin: 0, xMax: "", yMin: 0, yMax: 99 },
  ogr: { xMin: 0, xMax: "", yMin: 0, yMax: 500 },
  cgr: { xMin: 0, xMax: "", yMin: 0, yMax: 500 },
  wgr: { xMin: 0, xMax: "", yMin: 0, yMax: 250 },
  totalFluid: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  waterCut: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  oilCut: { xMin: 0, xMax: "", yMin: 0, yMax: "" }
});

// Object of plotly scale values
export const PLOTLY_SCALE = Object.freeze({
  default: "-",
  log: "log",
  linear: "linear",
  date: "date",
  category: "category",
  multi: "multicategory"
});

// Plotly default config object
export const PLOTLY_DEFAULT_CONFIG = {
  /*
    doubleClick: false, 'reset', 'autosize' or 'reset+autosize'
    Disable zoom out on double clicking axis
  */
  displaylogo: false,
  locale: "en",
  modeBarButtonsToRemove: ["autoScale2d", "lasso2d", "toImage", "toggleSpikelines"],
  responsive: true
};

export const SNAPSHOT_SIZE = Object.freeze({
  hr1: {
    label: "HR1",
    width: 1152,
    height: 681
  },
  hr2: {
    label: "HR2",
    width: 2416,
    height: 956
  },
  s1: {
    label: "S1",
    width: 1184,
    height: 938
  },
  vr1: {
    label: "VR1",
    width: 774,
    height: 938
  },
  custom: {
    label: "Custom",
    width: "",
    height: ""
  }
});

const Vintage = {
  id: "",
  property: "Dates.Vintage_Year",
  title: "Vintage",
  dataType: "Integer",
  canBin: true
};
const HzLength = {
  id: "",
  property: "Well_Design.HzLength_m",
  title: "Hz Length",
  dataType: "Number",
  canBin: true
};

export const DEFAULT_CHARTS: IChartSettings[] = [
  {
    showP10: false,
    showP50: false,
    showP90: false,
    id: "Chart 0",
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Rate Cum",
    chartTypeId: 1,
    product: "Oil + Cond",
    groupByListVisible: false,
    groupBy: null,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    forecast: true,
    average: false,
    typewells: true,
    producing: false,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length (m)",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    showActiveWellOnly: true,
    timeStep: TimeStep.Month,
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    sum: false,
    source: EvaSource.Public,
    cutoff: 50,
    shutInMonths: 3,
    survivorBias: true,
    useWeightedRatioAverage: true,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    stackedBarCalculation: CalculationMethod.Count,
    pressureChartSettings: {
      product: ALL_PRODUCT_TYPES.Oil.key,
      productivityIndex: false
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    movingAverageDays: 5,
    showAnnotations: false
  },
  {
    id: "Chart 1",
    showP10: false,
    showP50: false,
    showP90: false,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    screenshot: false,
    movingAverageDays: 5,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Mosaic",
    chartTypeId: 1,
    product: "Gas",
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    shutInMonths: 3,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length (m)",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: EvaSource.Public,
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: TimeStep.Month,
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    stackedBarCalculation: CalculationMethod.Count,
    pressureChartSettings: {
      product: ALL_PRODUCT_TYPES.Oil.key,
      productivityIndex: false
    },
    busy: false,
    range: { x: [], y: [] },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showAnnotations: false
  },
  {
    id: "Chart 2",
    showP10: false,
    showP50: false,
    showP90: false,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    movingAverageDays: 5,
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Rate Cum",
    chartTypeId: 1,
    product: "Water",
    shutInMonths: 3,
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length (m)",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: EvaSource.Public,
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: TimeStep.Month,
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    stackedBarCalculation: CalculationMethod.Count,
    pressureChartSettings: {
      product: ALL_PRODUCT_TYPES.Oil.key,
      productivityIndex: false
    },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showAnnotations: false
  },
  {
    id: "Chart 3",
    showP10: false,
    showP50: false,
    showP90: false,
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Total Rate Date",
    chartTypeId: 1,
    product: "Gas",
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    shutInMonths: 3,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length (m)",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: EvaSource.Public,
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: TimeStep.Month,
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    movingAverageDays: 5,
    stackedBarCalculation: CalculationMethod.Count,
    pressureChartSettings: {
      product: ALL_PRODUCT_TYPES.Oil.key,
      productivityIndex: false
    },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    showAnnotations: false
  }
];

export const CHART_SIZE_ERROR = "Error - Series Limit Exceeded";
export const CHART_WELL_ERROR = "Error - Well Count Exceeded";
export const CHART_EMPTY_ERROR = "noData";

export const PARAM_FIELD = {
  Oil12MCalTime: {
    id: "",
    property: "Performance.Oil.TwelveMCalTimeCum",
    title: "12M CalTime Cum (Mbbl)",
    dataType: "Number",
    canBin: true,
    product: "Oil",
    defaultUnit: "Mbbl"
  },
  Vintage_YearMonth: {
    id: "",
    property: "Dates.Vintage_YearMonth",
    title: "Vintage (Year-Month)",
    dataType: "Text",
    canBin: false
  },
  Vintage_Year: {
    id: "",
    property: "Dates.Vintage_Year",
    title: "Vintage (Year)",
    dataType: "Integer",
    canBin: true
  },
  HzLength: {
    id: "",
    property: "Well_Design.HzLength_m",
    title: "Hz Length (m)",
    dataType: "Number",
    canBin: true
  },
  ResourcePlay: {
    id: "",
    property: "Header.ResourcePlay",
    title: "Resource Play",
    dataType: "Text",
    canBin: false
  },
  FacilityStartDateYear: {
    id: "",
    property: "Header.FacilityStartDate_YearMonth",
    title: "Start Date (Year-Month)",
    dataType: "Text",
    canBin: false
  },
  Gas12MCalTime: {
    id: "",
    property: "Performance.Gas.TwelveMCalTimeCum",
    title: "12M CalTime Cum (MMcf)",
    dataType: "Number",
    canBin: true,
    product: "Gas",
    defaultUnit: "MMcf"
  },
  CurrentCalRate: {
    id: "",
    property: "Performance.Gas.CurrentCalRate",
    title: "Current Cal. Rate (Mcf/d)",
    dataType: "Number",
    canBin: true,
    product: "Gas",
    defaultUnit: "Mcf"
  }
};

export const DEFAULT_CHART_STATE: StateT = {
  ...defaultChartState,
  settings: DEFAULT_CHARTS[0]
};

// Plotly constants
const grid_color = "#000000";
const modebar_default_icon_color = "#abbace";
const modebar_active_icon_color = "#40546b";
const font_color = "#314155";
const transparent_hex = "#00000000";

// Plotly default layout object
export const PLOTLY_DEFAULT_LAYOUT = {
  /* TODO: set colorway */
  autosize: true,
  title: {
    text: "Plot Title"
  },
  font: {
    color: font_color,
    size: 12
  },
  margin: {
    l: 46,
    r: 16,
    b: 54,
    t: 25
  },
  showlegend: false,
  legend: {
    font: {
      family: "apercu"
    }
  },
  modebar: {
    activecolor: modebar_active_icon_color,
    color: modebar_default_icon_color,
    bgcolor: transparent_hex,
    orientation: "v"
  },
  /* Hovermode: "x" | "y" | "closest" | false */
  hovermode: "closest",
  /* Dragmode:  "zoom" | "pan" | "select" | "lasso" | "orbit" | "turntable" | false */
  hoverlabel: {
    font: {
      family: "apercu",
      size: 14
    }
  },
  dragmode: "zoom",
  /* No color for paper and plot */
  plot_bgcolor: transparent_hex,
  paper_bgcolor: transparent_hex,
  xaxis: {
    automargin: true,
    color: grid_color,
    gridcolor: grid_color,
    tickformat: "yyyy-dd",
    showdividers: false,
    showline: false,
    zeroline: false
  },
  yaxis: {
    automargin: true,
    color: grid_color,
    gridcolor: grid_color,
    showdividers: false,
    showline: false,
    zeroline: false
  },
  datarevision: 1
};

export const ForecastRatios = [
  { label: "GOR", value: 5 },
  { label: "CGR", value: 6 },
  { label: "WGR", value: 7 },
  { label: "OGR", value: 8 },
  { label: "WOR", value: 9 }
];

export const ForecastProducts = [
  { label: "Oil", value: 1 },
  { label: "Gas", value: 2 },
  { label: "Water", value: 3 },
  ...ForecastRatios
];

export const EMPTY_CHART_RESULT = {
  title: "",
  messages: [
    {
      message: "",
      messageType: CHART_EMPTY_ERROR,
      entities: []
    }
  ],
  errorMessage: "",
  messageType: CHART_EMPTY_ERROR,
  errorEntities: [],
  series: [],
  layout: {
    xAxis: {
      title: "",
      defaultAxisType: "linear",
      labels: [],
      min: 0,
      max: 0,
      interval: 0,
      data: [],
      logMin: null,
      yScaleToX: 1,
      axisIsDate: false,
      isSecondaryYAxis: false
    },
    yAxis: {
      title: "",
      defaultAxisType: "linear",
      labels: [],
      min: 0,
      max: 0,
      interval: 0,
      data: [],
      logMin: null,
      yScaleToX: 1,
      axisIsDate: false,
      isSecondaryYAxis: false
    },
    yAxis2: [],
    xAxis2: []
  },
  requestId: "",
  uwis: [],
  uwiCoordinates: {},
  segments: [],
  constants: [],
  wellSources: {
    productionDataSources: [],
    forecastSources: []
  },
  productionSources: "",
  syncStatus: {
    isForecastSynced: false,
    isProductionSynced: false,
    syncedSchema: "",
    warningMessage: ""
  },
  normalizeData: null
} as IChartResult;

export const WellOperatorGroupBy: IGroupBy = {
  title: "Operator",
  property: "Header.Operator_Short",
  groupByField: "Header.Operator_Short",
  pdenSource: "Public",
  canBin: false,
  dataType: "Text",
  categoryId: 1,
  tooltip: "",
  display: "",
  entityKind: EntityKind.Well,
  bin: {
    BinSize: "",
    MinSize: "",
    GreaterThan: "",
    BinType: "BinSize",
    LessThan: ""
  }
};
