import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CHART_ACTION } from "constants/chart.constants";
import { useChartsContext } from "contexts/ChartContext";
import { getBrushSelectList } from "entities/charts/eva/lasso";
import debounce from "lodash/debounce";
import {
  selectChartLassoFeature,
  selectChartResponse,
  selectChartType,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import { ChartActionType } from "types/echarts";

export function useChartLasso(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const chartResponse = useSelector((state: RootState) => selectChartResponse(state, id));
  const lassoStates = useSelector((state: RootState) =>
    selectChartLassoFeature(state, id)
  );
  const active = lassoStates.active;

  function updateBrushEnd(value: boolean) {
    dispatch(
      updateChartByPath({
        id,
        path: `features.lasso.properties.brushEnd`,
        value
      })
    );
  }
  useEffect(() => {
    if (!chartInstanceRef.current) return;

    const chartInstance = chartInstanceRef.current;
    if (active) {
      chartInstance.dispatchAction(CHART_ACTION.brushStart);
      chartInstance.setOption({
        tooltip: { show: false, showContent: false }
      });
    } else {
      chartInstance.dispatchAction(CHART_ACTION.brushClear);
      chartInstance.dispatchAction(CHART_ACTION.brushEnd);
      chartInstance.setOption({
        tooltip: { show: true, showContent: true }
      });
      updateBrushEnd(false);
    }
  }, [chartInstanceRef.current, active]);

  useEffect(() => {
    if (!chartInstanceRef.current) return;
    const chartInstance = chartInstanceRef.current;

    const brushEndHandler = (params) => {
      if (params.areas.length) {
        updateBrushEnd(true);
      }
    };

    const handleBrushSelected = (params) => {
      const chartOptions = chartInstance.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;

      const list = getBrushSelectList({
        chartType,
        params,
        series: seriesArray,
        uwiList: chartResponse.uwis
      });

      dispatch(
        updateChartByPath({
          id,
          path: `features.lasso.properties.list`,
          value: list
        })
      );
    };

    const debouncedHandleBrushSelected = debounce(handleBrushSelected, 600);

    chartInstance.on(ChartActionType.BrushSelected, debouncedHandleBrushSelected);
    chartInstance.on(ChartActionType.BrushEnd, brushEndHandler);

    return () => {
      debouncedHandleBrushSelected.cancel();
      chartInstance.off(ChartActionType.BrushSelected, debouncedHandleBrushSelected);
      chartInstance.off(ChartActionType.BrushEnd, brushEndHandler);
    };
  }, [chartInstanceRef.current, chartType, chartResponse, dispatch, id]);
}
