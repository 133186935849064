import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { translateTooltipOptions } from "entities/charts/eva/tooltips";
import { translateToOptions } from "entities/charts/translators";
import { selectChartResponse, selectChartType, updateChart } from "store/features";
import { RootState } from "store/rootReducer";

export function useChartOptions(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const apiResponse = useSelector((state: RootState) => selectChartResponse(state, id));

  const prevApiResponse = useRef<string | null>(null);

  useEffect(() => {
    if (chartInstanceRef.current && apiResponse) {
      // TODO chart: adjust logic based on active widget
      // This is to prevent the option from reseting during highlighting and lassoing
      if (
        prevApiResponse.current === JSON.stringify(apiResponse) &&
        chartInstanceRef.current.getOption()
      )
        return;

      const options = translateToOptions({
        type: chartType,
        states: { ...apiResponse }
      });
      const tooltipOptions = translateTooltipOptions({
        type: chartType,
        states: { ...apiResponse }
      });
      chartInstanceRef.current.setOption({ ...options, tooltip: tooltipOptions }, true); // true to replace previous options

      dispatch(
        updateChart({
          id,
          options: JSON.stringify(options)
        })
      );
    }
    prevApiResponse.current = JSON.stringify(apiResponse);
  }, [chartInstanceRef.current, apiResponse]);
}
