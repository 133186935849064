import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useChartsContext } from "contexts/ChartContext";
import {
  selectChartRequest,
  selectChartResponse,
  selectChartScreenshotFeature,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";

import { IChartScreenshotSettings } from "models/chart";

import { useScreenshotDispatch } from "components/screenshot/hooks";

import ToolbarButton from "../../../base/ToolbarButton";

const ScreenshotToggle = () => {
  const dispatch = useDispatch();
  const screenshotDispatch = useScreenshotDispatch();
  const { id } = useChartsContext();
  const request = useSelector((state: RootState) => selectChartRequest(state, id));
  const response = useSelector((state: RootState) => selectChartResponse(state, id));
  const screenshot = useSelector((state: RootState) =>
    selectChartScreenshotFeature(state, id)
  );
  // update screenshot state when legend is toggled
  const toggle = (value) => {
    const widget = value
      ? {
          widgetId: id,
          widgetComponentType: "chart"
        }
      : null;

    const widgetState = value
      ? {
          request,
          response
        }
      : null;
    const next = { ...screenshot, visible: value };
    screenshotDispatch({
      payload: {
        settings: next?.properties?.preset as IChartScreenshotSettings,
        widget,
        widgetState
      }
    });
    dispatch(
      updateChartByPath({
        id,
        path: `features.screenshot.active`,
        value: value
      })
    );
  };

  return (
    <ToolbarButton
      key="screenshot"
      data-testid="screenshot-toggle"
      active={screenshot?.active}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle}
    />
  );
};

export default memo(ScreenshotToggle);
