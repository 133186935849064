import { EvaSeriesKind } from "constants/charts.enums";
import { SeriesOption } from "echarts";
import { curry } from "lodash";
import {
  BaseLegendStates,
  DataPointParams,
  MouseSelectionStates,
  SelectedParams
} from "types";
import { ChartActionType, SeriesType } from "types/echarts";
import { EvaChartStates } from "types/factory";
import { allTrue } from "utils/fp";

export const UNDEFINED_DATA_POINT: SelectedParams = {
  targetIndex: undefined,
  seriesIndex: undefined,
  seriesType: SeriesType.None
};

function getSeriesIndexFromParams({ params }: Partial<MouseSelectionStates>): number {
  if (
    (params.type === ChartActionType.Hover ||
      params.type === ChartActionType.MouseOver) &&
    typeof params.seriesIndex === "number"
  ) {
    return params.seriesIndex;
  } else if (params.type === ChartActionType.Highlight && Array.isArray(params.batch)) {
    return params.batch[0].seriesIndex;
  } else if (
    params.type === ChartActionType.Highlight &&
    Array.isArray(params.seriesIndex)
  ) {
    return params.seriesIndex[0];
  } else if (
    params.type === ChartActionType.Highlight &&
    typeof params.seriesIndex === "number"
  ) {
    return params.seriesIndex;
  }

  return -1;
}

function isTypeWell(series: SeriesOption) {
  return "kind" in series && series.kind === EvaSeriesKind.TypeWell;
}

export function getSeriesName({
  params,
  series,
  forecast
}: Partial<MouseSelectionStates>): string {
  const seriesIndex = getSeriesIndexFromParams({ params });
  if (seriesIndex < 0) return "";
  const targetSeries = series[seriesIndex];
  if (!targetSeries) return "";
  const seriesName = targetSeries.name?.toString() ?? "";

  if (forecast) {
    return seriesName.replace(/Forecast *\(?MCD\)?/gi, "").trim();
  }
  return seriesName;
}

function getSeriesId(series: SeriesOption) {
  const seriesId = (series.id as string) ?? "";
  return seriesId;
}

export function getGroupName({ params, series }: Partial<MouseSelectionStates>): string {
  const seriesIndex = getSeriesIndexFromParams({ params });
  if (seriesIndex < 0) return "";
  const targetSeries = series[seriesIndex];
  if (!targetSeries) return "";
  const seriesId = getSeriesId(targetSeries);
  if (isTypeWell(targetSeries)) return "";
  const groupName = seriesId.split(",")[0].trim();
  return groupName;
}

export function getLegendId({ params, series }: Partial<MouseSelectionStates>): string {
  const seriesIndex = getSeriesIndexFromParams({ params });
  if (seriesIndex < 0) return "";
  const targetSeries = series[seriesIndex];
  if (!targetSeries) return "";
  if (!isTypeWell(targetSeries)) return "";
  const seriesId = getSeriesId(targetSeries);
  const typeWellId = seriesId.split(",")[1];

  return typeWellId;
}

export function isValidChartLegendStates({ states }: BaseLegendStates) {
  const { hoverLegendItem, attentionWells, selectedGroups } = states;
  const isInvalid = allTrue(
    !hoverLegendItem,
    attentionWells?.length === 0,
    selectedGroups?.length === 0
  );
  return isInvalid;
}

export function getSeriesIndex({ states, series }: BaseLegendStates) {
  const { hoverLegendItem, hoverLegendId } = states;

  if (hoverLegendId?.length > 0) {
    const seriesIndex = series.findIndex((series) => {
      const seriesId = getSeriesId(series);
      return seriesId.includes(hoverLegendId);
    });
    return [seriesIndex];
  } else {
    return series.reduce((indices: number[], series, index) => {
      const seriesId = getSeriesId(series);
      if (seriesId.includes(hoverLegendItem)) {
        indices.push(index);
      }
      return indices;
    }, []);
  }
}

export function getSeriesIndexFromSelectedGroups({ states, series }: BaseLegendStates) {
  const { selectedGroups } = states;
  if (selectedGroups?.length === 0) return [];
  return series.reduce((indices: number[], series, index) => {
    const seriesId = getSeriesId(series);
    if (selectedGroups.some((group) => seriesId.includes(group))) {
      indices.push(index);
    }
    return indices;
  }, []);
}

export function getAttentionWellIndex({ states, series }: BaseLegendStates) {
  const { attentionWells } = states;

  return series.reduce((indices: number[], series, index) => {
    const seriesId = getSeriesId(series);
    if (seriesId.includes(attentionWells[0])) {
      indices.push(index);
    }
    return indices;
  }, []);
}

export function getDataPoint({ states, series, wellName, groupName }: DataPointParams) {
  const { uwis } = states;
  const wellIndex = uwis.indexOf(wellName);
  if (wellIndex === -1) return UNDEFINED_DATA_POINT;

  let candidate = UNDEFINED_DATA_POINT;
  for (let seriesIndex = 0; seriesIndex < series.length; seriesIndex++) {
    const currentSeries = series[seriesIndex];
    if (currentSeries.name !== groupName) continue;

    const dataIndex = (currentSeries.data as PointCoordinatesWithUwiIndex[]).findIndex(
      (point) => point[2] === wellIndex
    );

    if (dataIndex !== -1) {
      candidate = { seriesIndex, targetIndex: dataIndex };
      break;
    }
  }
  return candidate;
}

export const getDataPointCurry = curry(
  (
    states: Partial<EvaChartStates>,
    series: SeriesOption[],
    wellName: string,
    groupName: string
  ) => {
    return getDataPoint({ states, series, wellName, groupName });
  }
);
