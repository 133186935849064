import { useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  makeVar
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ConfigProvider } from "antd";
import { RootState } from "store/rootReducer";
import { checkTokenExpire } from "utils";

import { refreshToken } from "api/auth";

import { ShapefileProvider } from "components/project/shapefiles/context";
import { PostProvider } from "components/user-documentation";
import { UserProvider } from "components/user/context";

ConfigProvider.config({
  theme: {
    primaryColor: "#041C2C"
  }
});

type AppProviderPropsT = {
  // currentJwtToken: string;
  children: React.ReactNode | React.ReactNode[] | null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

// Create a reactive variable to store the JWT token
export const jwtTokenVar = makeVar<string | null>(null);

const AppProvider = ({
  children = null
}: // currentJwtToken
AppProviderPropsT): JSX.Element => {
  const currentJwtToken = useSelector((state: RootState) => state.auth.jwtToken);
  const user = useSelector((state: RootState) => state.auth.user);
  const tokenRefreshing = useSelector((state: RootState) => state.auth.tokenRefreshing);
  useEffect(() => {
    jwtTokenVar(currentJwtToken); // Update the reactive variable with the latest JWT token
  }, [currentJwtToken]);

  const waitForNewToken = async (oldToken) => {
    let newToken = jwtTokenVar();
    const maxWaitTime = 60000; // 1 minute in milliseconds
    const startTime = Date.now();

    while (newToken === oldToken && Date.now() - startTime < maxWaitTime) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100ms
      newToken = jwtTokenVar();
    }

    return newToken;
  };

  const apolloClient = useMemo(() => {
    //useMemo to make sure apolloClient only created once

    const userArpsLink = createHttpLink({
      uri: process.env.REACT_APP_TYPE_WELL_GQL_SERVICE
    });

    const savedFiltersLink = createHttpLink({
      uri: process.env.REACT_APP_SAVED_FILTER_GQL_SERVICE
    });

    const workspacesLink = createHttpLink({
      uri: process.env.REACT_APP_WORKSPACE_SERVICE
    });

    const authLink = setContext(async (_, { headers }) => {
      const jwtToken = jwtTokenVar();

      if (!jwtToken) {
        return { headers };
      }

      if (!checkTokenExpire(jwtToken)) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${jwtToken}`
          }
        };
      } else {
        try {
          if (tokenRefreshing) {
            // wait here until the new token is set
            const newTokenFromOtherTab = await waitForNewToken(jwtToken);
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${newTokenFromOtherTab}`
              }
            };
          }

          const newToken = await refreshToken(user?.secret);
          if (newToken === null) {
            // wait here until the new token is set
            const newTokenFromOtherTab = await waitForNewToken(jwtToken);
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${newTokenFromOtherTab}`
              }
            };
          }

          return {
            headers: {
              ...headers,
              authorization: `Bearer ${newToken}`
            }
          };
        } catch {
          throw new Error("Token refresh failed");
        }
      }
    });

    const splitLink = authLink.split(
      (operation) => operation.getContext().clientName === "saved-filters",
      savedFiltersLink,
      authLink.split(
        (operation) => operation.getContext().clientName === "workspaces",
        workspacesLink,
        userArpsLink
      )
    );

    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache()
    });
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <UserProvider>
            <ShapefileProvider>
              <PostProvider>{children}</PostProvider>
            </ShapefileProvider>
          </UserProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default AppProvider;
