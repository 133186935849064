import { FunctionComponent, useRef } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import { useChartsContext } from "contexts/ChartContext";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { visibilityToggleStyles } from "../../shared/SharedStyles";
import useGlobalNormalizeBy from "../hooks/useGlobalNormalizeBy";
import useGlobalPdenSourceSettings from "../hooks/useGlobalPdenSourceSettings";
import {
  DataSourceToggle,
  DevDebugToggle,
  ForecastToggle,
  FullscreenToggle,
  HistogramToggle,
  JitterPlotToggle,
  LassoBrush,
  LegendToggle,
  NormalizeByToggle,
  OverflowToggle,
  Popout,
  ProducingToggle,
  ScreenshotToggle,
  SettingsToggle,
  SlopeLinesToggle,
  SpaghettiToggle,
  StatisticsToggle,
  TimeStepToggle,
  TypewellsToggle
} from "../toggles";
import AddReferenceLineToggle from "../toggles/AddReferenceLineToggle";
import AnnotationToggle from "../toggles/AnnotationToggle";
import ProbitDataSourceToggle from "../toggles/ProbitDataSourceToggle";
import ChartFocusToggle from "../toggles/focus/ChartFocusToggle";
import ChartHeader from "./ChartHeader";

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;

export const components = [
  <AnnotationToggle key="annotation" />,
  <ChartFocusToggle key="focus" />,
  <DataSourceToggle key="source" />,
  <TimeStepToggle key="timestep" />,
  <NormalizeByToggle key="normalize" />,
  <Divider key="globalSettingsDivider" />,
  <DevDebugToggle key="debugMode" />,
  <SettingsToggle key="settings" />,
  <JitterPlotToggle key="jitterPlotToggle" />,
  <ProbitDataSourceToggle key="probitDataSource" />,
  <HistogramToggle key="histogramToggle" />,
  <LassoBrush key="lasso" />,
  <ProducingToggle key="producing" />,
  <ForecastToggle key="forecast" />,
  <StatisticsToggle key="statistics" />,
  <SlopeLinesToggle key="slopeLines" />,
  <TypewellsToggle key="typewells" />,
  <ScreenshotToggle key="screenshot" />,
  <LegendToggle key="legend" />,
  <SpaghettiToggle key="individual" />,
  <AddReferenceLineToggle key="referenceLines" />,
  <Popout key="popout" />,
  <FullscreenToggle key="fullscreen" />
];

// TODO chart: optimization: use portal to outportal overflowing components
const ChartToolbar: FunctionComponent = () => {
  const ref = useRef<HTMLDivElement>(null);
  const visibleComponents = components.slice(0, 12);
  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const { id } = useChartsContext();
  useGlobalNormalizeBy();
  useGlobalPdenSourceSettings();

  const isHover = widgetHoverMap?.[id] ?? false;
  const visible = isHover;
  const wrapperClassnames = classnames({ visible });

  return (
    <Wrapper ref={ref} className={wrapperClassnames}>
      <ChartHeader />

      {/* visible toolbar icons */}
      <GapWrapper>{visibleComponents}</GapWrapper>

      {/* more-toggle for overflowing toolbar icons */}
      <OverflowToggle />
    </Wrapper>
  );
};

export default ChartToolbar;

const GapWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--chart-toolbar-height);
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 14px 16px 0 rgb(45 53 63 / 10%), inset 0 7px 8px -10px rgb(45 53 63 / 15%);
  z-index: 10;
  padding-right: 4px;
`;
