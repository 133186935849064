import { EvaChart } from "constants/charts.enums";
import { LegendStates, MouseSelectionStates, SelectedParams } from "types";

import { ChartDependencies } from "hooks/charts";

import { crossPlotHoverLegends, crossPlotSelectionParams } from "./crossPlot";
import { cumTimeHoverLegends } from "./cumTime";
import { rateCumHoverLegends, rateCumSelectionParams } from "./rateCum";
import { rateTimeHoverLegends, rateTimeSelectionParams } from "./rateTime";

const PARAM_TRANSLATOR_MAP = {
  [EvaChart.RateCum]: rateCumSelectionParams,
  [EvaChart.CrossPlot]: crossPlotSelectionParams,
  [EvaChart.CumTime]: rateCumSelectionParams,
  [EvaChart.RateTime]: rateTimeSelectionParams
};

export function translateLegendsToParams({
  type,
  states,
  series,
  forecast
}: LegendStates): SelectedParams {
  const translator = PARAM_TRANSLATOR_MAP[type];
  if (!translator) {
    throw new Error(`Unknown chart type: ${type} to translate legends to options`);
  }
  return translator({ states, series, forecast });
}

const LEGEND_TRANSLATOR_MAP = {
  [EvaChart.RateCum]: rateCumHoverLegends,
  [EvaChart.CrossPlot]: crossPlotHoverLegends,
  [EvaChart.CumTime]: cumTimeHoverLegends,
  [EvaChart.RateTime]: rateTimeHoverLegends
};

export function translateSelectionToLegends({
  type,
  params,
  series,
  response,
  forecast
}: MouseSelectionStates): Partial<ChartDependencies> {
  const translator = LEGEND_TRANSLATOR_MAP[type];
  if (!translator) {
    throw new Error(`Unknown chart type: ${type} to translate selection to legends`);
  }
  return translator({ params, series, response, forecast });
}
