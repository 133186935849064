import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "../../../contexts";
import { useChartEntities } from "../../../hooks/charts";
import { updateChartByPath } from "../../../store/features";
import { RootState } from "../../../store/rootReducer";

const useGlobalPdenDataSourceSettings = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { source, timeStep } = useChartEntities(id);

  const { source: globalSource, timeStep: globalTimeStep } = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  useEffect(() => {
    if (globalSource !== source) {
      dispatch(
        updateChartByPath({
          id,
          path: "features.source.properties.value",
          value: globalSource
        })
      );
    }
    if (globalTimeStep !== timeStep) {
      dispatch(
        updateChartByPath({
          id,
          path: "features.timeStep.properties.value",
          value: globalTimeStep
        })
      );
    }
  }, [globalTimeStep, globalSource]);
};

export default useGlobalPdenDataSourceSettings;
