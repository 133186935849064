import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import AllInboxIcon from "@material-ui/icons/AllInbox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Button, Divider, Popover, Space, Switch, Typography } from "antd";
import axios from "axios";
import {
  selectChartEntityKind,
  selectChartFocusFeature,
  selectChartType,
  updateChartByPath
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BinType, IGroupBy, QuantileState } from "models";
import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";

import { BinSettings } from "components/filter";
import { ColorPaletteListToggle } from "components/filter/focus";
import GroupBy from "components/groupBy";
// TODO chart: multi product selector for total rate date chart, commenting out for now
// import { IconButton } from "components/forecasting/Shared";
// import { PolymerInjection } from "components/icons";
// import ProductBoe from "components/icons/ProductBoe";
// import TotalFluid from "components/icons/TotalFluid";
import { IColorPalette } from "components/user-settings/models";
import { useUserDefaults } from "components/user/hooks";

import { WellOperatorGroupBy } from "../../../../constants";
import { EvaChart } from "../../../../constants/charts.enums";
import { useChartsContext } from "../../../../contexts";

const { Text } = Typography;
const dataRoot = process.env.REACT_APP_DATA_ROOT;

const ChartFocusSettings = () => {
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const userDefaults = useUserDefaults();

  const entityKind = useSelector((state: RootState) => selectChartEntityKind(state, id));
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const globalFacilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const globalPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  ) as IColorPalette;
  const chartFocusFeature = useSelector((state: RootState) =>
    selectChartFocusFeature(state, id)
  );

  const chartFocusGroupBy = chartFocusFeature?.properties?.groupBy;
  const bin = chartFocusFeature?.properties?.groupBy?.bin;
  const colorPalette = chartFocusFeature?.properties?.colorPalette;
  const isChartFocusActive = chartFocusFeature?.active;

  const isWellContributionChart = chartType === EvaChart.WellContribution;
  const focusEntities = isWellContributionChart ? EntityKind.Well : entityKind;

  const [localBin, setLocalBin] = useState<mBinSize>(
    isChartFocusActive ? bin : globalGroupBy.bin
  );

  // TODO chart: multi product selector for total rate date chart, commenting out for now
  // const isTotalRateDateChart = chartType === EvaChart.TotalRateDate;
  // const [useMultiProductFocus, setUseMultiProductFocus] = useState(false);

  const handleToggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.chartFocus.active",
        value: active
      })
    );
    setLocalBin({
      ...localBin,
      UseDynamicBins: userDefaults.binSettings?.useDynamicBins ?? false
    });
  };
  const handleGroupByChange = (groupBy: Partial<IGroupBy>) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.chartFocus.properties.groupBy`,
        value: groupBy
      })
    );
  };

  function handleLocalBinChange(val: mBinSize) {
    const newBin = Object.assign({}, localBin, val);
    setLocalBin(newBin);
  }

  const handleColorPaletteChange = (colorPalette: Partial<IColorPalette>) => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.chartFocus.properties.colorPalette",
        value: colorPalette
      })
    );
  };

  const handleFocusChange = (
    groupBy,
    isForecastToggleOn,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedPdenSource
  ) => {
    setLocalBin({
      ...localBin,
      UseDynamicBins: userDefaults.binSettings?.useDynamicBins ?? false
    });
    const gb = {
      ...groupBy,
      pdenSource: selectedPdenSource,
      entityKind: focusEntities,
      groupByField: groupBy.property,
      bin: { ...localBin }
    };
    handleGroupByChange(gb);
  };

  const { refetch } = useQuery(
    ["bin-size", id],
    () => {
      return axios.get(
        `${dataRoot}/api/v1/data/bin-size/${filterId.id}/${btoa(
          encodeURIComponent(chartFocusGroupBy.property)
        )}?normalizeField=%27%27&per=0&useDynamicBins=${localBin?.UseDynamicBins}`
      );
    },
    {
      enabled: false,
      // need to wait for the default bin response
      onSuccess: (data) => {
        if (data?.data?.isValid) {
          // updates the chart focus for values that can be binned
          const newBin = data.data;
          let binned: mBinSize = {
            LessThan: newBin.lessThan,
            GreaterThan: newBin.greaterThan,
            MinSize: newBin.minSize,
            BinSize: newBin.binSize,
            BinType: "BinSize",
            Quantile: null,
            UseDynamicBins: localBin.UseDynamicBins ?? false
          };
          if (
            userDefaults.binSettings?.type === "Quantile" &&
            (chartFocusGroupBy.dataType.toLowerCase() === "number" ||
              chartFocusGroupBy.dataType.toLowerCase() === "integer")
          ) {
            binned = {
              ...binned,
              BinType: "Quantile",
              Quantile: {
                quantileType: userDefaults.binSettings.quantile.quantileType,
                numQuantiles: userDefaults.binSettings.quantile.numQuantiles
              }
            };
          }
          setLocalBin(binned);
          const groupBy = {
            ...chartFocusGroupBy,
            groupByField: chartFocusGroupBy.property,
            bin: binned
          };
          handleGroupByChange(groupBy);
          handleColorPaletteChange(colorPalette);
        } else {
          // updates the chart focus for values without binning
          let defaultBin: mBinSize = {
            LessThan: "",
            GreaterThan: "",
            MinSize: "",
            BinSize: "",
            BinType: "BinSize",
            Quantile: null,
            UseDynamicBins: localBin.UseDynamicBins ?? false
          };
          if (
            userDefaults.binSettings?.type === "Quantile" &&
            (chartFocusGroupBy.dataType.toLowerCase() === "number" ||
              chartFocusGroupBy.dataType.toLowerCase() === "integer")
          ) {
            defaultBin = {
              ...defaultBin,
              BinType: "Quantile",
              Quantile: {
                quantileType: userDefaults.binSettings.quantile.quantileType,
                numQuantiles: userDefaults.binSettings.quantile.numQuantiles
              }
            };
          }
          setLocalBin(defaultBin);
          const groupBy = {
            ...chartFocusGroupBy,
            groupByField: chartFocusGroupBy.property,
            bin: defaultBin
          };
          handleGroupByChange(groupBy);
          handleColorPaletteChange(colorPalette);
        }
      }
    }
  );

  function updateBin(binType: BinType, quantile: QuantileState, useDynamicBins: boolean) {
    let newBinType = binType;
    // Show type as dynamic but function as bin size besides useDynamicBins
    if (binType === "Dynamic") {
      newBinType = "BinSize";
    }
    const newBin = { ...localBin };
    newBin.BinType = newBinType;
    newBin.Quantile = quantile;
    newBin.UseDynamicBins = useDynamicBins;
    setLocalBin(newBin);
    const updatedFocus = {
      ...chartFocusGroupBy,
      groupByField: chartFocusGroupBy.property,
      bin: newBin
    };
    handleGroupByChange(updatedFocus);
    handleColorPaletteChange(colorPalette);
  }

  // keeps the chart focus the same as the global focus when not being used
  useEffect(() => {
    if (!isChartFocusActive) {
      if (entityKind === EntityKind.Facility) {
        if (isWellContributionChart) {
          handleGroupByChange(WellOperatorGroupBy);
        } else {
          handleGroupByChange(globalFacilityGroupBy);
        }
      } else {
        handleGroupByChange(globalGroupBy);
      }
    }
  }, [globalGroupBy, globalFacilityGroupBy, isChartFocusActive, isWellContributionChart]);

  // keeps the chart colour palette the same as the global colour palette
  // when chart focus is not being used
  useEffect(() => {
    if (!isChartFocusActive) {
      handleColorPaletteChange(globalPalette);
    }
  }, [globalPalette, isChartFocusActive]);

  // updates the chart focus
  useEffect(() => {
    if (isChartFocusActive) {
      // TODO chart: multi product selector for total rate date chart, commenting out for now
      // removeOtherProducts();

      // need to obtain/set bin settings if using chart-specific focus
      refetch();
      return;
    }
    handleGroupByChange(
      isChartFocusActive
        ? //override entityKind to well if well contribution chart
          //because group by is well fields not facility fields
          {
            ...chartFocusGroupBy,
            groupByField: chartFocusGroupBy.property,
            entityKind: focusEntities
          }
        : globalGroupBy
    );
  }, [isChartFocusActive, chartFocusGroupBy.property]);

  // re-fetch bins when switching from dynamic to static bins
  useEffect(() => {
    if (isChartFocusActive) {
      refetch();
    }
  }, [localBin.UseDynamicBins]);

  // set the chart focus to well operator for well contribution chart,
  // and facility focus when switching back to other midstream charts
  useEffect(() => {
    if (isChartFocusActive && isWellContributionChart) {
      handleGroupByChange(WellOperatorGroupBy);
    } else if (isChartFocusActive && entityKind === EntityKind.Facility) {
      handleGroupByChange(globalFacilityGroupBy);
    }
  }, [isWellContributionChart]);

  // TODO chart: multi product selector for total rate date chart, commenting out for now
  // const handleMultiProductFocusChange = (products: MultiProducts) => {
  //   const nextValue = { ...settings, otherProducts: products.products };
  //   dispatch({ type: "settings", payload: nextValue });
  // };
  // const removeOtherProducts = useCallback(() => {
  //   const nextValue = { ...settings, otherProducts: [] };
  //   dispatch({ type: "settings", payload: nextValue });
  // }, [settings, dispatch]);
  //
  // const handleUseMultiProductFocusChange = useCallback(
  //   (use: boolean, products: MultiProducts) => {
  //     setUseMultiProductFocus(use);
  //     if (use) {
  //       setUseChartFocus(false);
  //       if (products) {
  //         handleMultiProductFocusChange(products);
  //       }
  //     } else {
  //       removeOtherProducts();
  //     }
  //   },
  //   [removeOtherProducts]
  // );

  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <HeaderWrapper>
        <Text strong>Chart Focus</Text>
        <StyledSwitch
          data-testid="chartFocusSettingsSwitch"
          size="small"
          checked={isChartFocusActive}
          onChange={(checked: boolean) => {
            handleToggle(checked);
            if (checked) {
              // TODO chart: multi product selector for total rate date chart, commenting out for now
              // setUseMultiProductFocus(false);
              handleLocalBinChange({
                ...localBin,
                UseDynamicBins: userDefaults.binSettings?.useDynamicBins ?? false
              });
            }
          }}
        />
      </HeaderWrapper>
      {isChartFocusActive && (
        <FocusArea>
          <GroupBy
            data-testId="chartFocusGroupBy"
            onChange={handleFocusChange}
            value={chartFocusGroupBy}
            //well contribution chart groups by well fields not facility fields
            entityKind={focusEntities}>
            <FocusButton block>
              {chartFocusGroupBy.title}
              <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
            </FocusButton>
          </GroupBy>
          <Popover
            trigger="click"
            placement="topLeft"
            content={
              <FocusBinContainer>
                <BinSettings
                  bin={localBin}
                  entityKind={focusEntities}
                  canEditBin={chartFocusGroupBy.canBin}
                  onReset={refetch}
                  onBinChange={(v) => handleLocalBinChange(v)}
                  onBinSettingChange={updateBin}
                  isMinCountWarningVisible={false}
                />
              </FocusBinContainer>
            }>
            <FocusBinButton
              data-testid="chartFocusBinSettings"
              icon={<AllInboxIcon style={{ fontSize: 18 }} />}
            />
          </Popover>
          <ColorPaletteSelector>
            <ColorPaletteListToggle
              activePalette={colorPalette}
              onPaletteSelected={handleColorPaletteChange}
              showBorder={true}
            />
          </ColorPaletteSelector>
        </FocusArea>
      )}
      {/*TODO chart: multi product selector for total rate date chart, commenting out for now*/}
      {/*{isTotalRateDateChart && (*/}
      {/*  <MultiProductFocusSelector*/}
      {/*    useMultiProductFocus={useMultiProductFocus}*/}
      {/*    handleUseMultiProductFocusChange={handleUseMultiProductFocusChange}*/}
      {/*    handleMultiProductFocusChange={handleMultiProductFocusChange}*/}
      {/*  />*/}
      {/*)}*/}
    </StyledSpace>
  );
};

// TODO chart: multi product selector for total rate date chart, commenting out for now
// interface MultiProductFocusSelectorProps {
//   useMultiProductFocus: boolean;
//   handleUseMultiProductFocusChange?: (use: boolean, products: MultiProducts) => void;
//   handleMultiProductFocusChange?: (products: MultiProducts) => void;
// }
//
// interface MultiProducts {
//   name: string;
//   icon: ReactNode;
//   tooltipTitle: string;
//   products: ("Oil" | "Gas" | "Water Inj" | "Polymer Inj" | "Water")[];
// }
// const availableMultiProducts: MultiProducts[] = [
//   {
//     name: "BOE",
//     products: ["Oil", "Gas"],
//     icon: <ProductBoe key="product-boe" stroke="lightgray" />,
//     tooltipTitle: "BOE: Stacked Oil + Cond and Gas"
//   },
//   {
//     name: "Total Fluid",
//     products: ["Oil", "Water"],
//     icon: <TotalFluid key="total-fluid-product" />,
//     tooltipTitle: "Total Fluid: Stacked Oil + Cond and Water"
//   },
//   {
//     name: "Polymer Inj",
//     products: ["Polymer Inj", "Water Inj"],
//     icon: <PolymerInjection key="product-polymer-injection" />,
//     tooltipTitle: "Polymer Inj: Stacked Polymer Injection and Water Injection"
//   }
// ];

// const MultiProductFocusSelector: FC<MultiProductFocusSelectorProps> = ({
//   useMultiProductFocus,
//   handleUseMultiProductFocusChange,
//   handleMultiProductFocusChange
// }) => {
//   const [selectedMultiProduct, setSelectedMultiProduct] = useState<MultiProducts>();
//   const renderMultiProducts = (products: MultiProducts[]) => {
//     return products.map((product) => (
//       <MultiProductItemWrapper key={product.name}>
//         <Tooltip title={product.tooltipTitle}>
//           <MultiProductButton
//             data-testid={product.tooltipTitle}
//             icon={product.icon}
//             isSelected={selectedMultiProduct === product}
//             type="ghost"
//             onClick={() => {
//               setSelectedMultiProduct(product);
//               handleMultiProductFocusChange?.(product);
//             }}
//           />
//         </Tooltip>
//       </MultiProductItemWrapper>
//     ));
//   };
//
//   return (
//     <MultiProductRootContainer>
//       <HeaderWrapper>
//         <Text strong>Multi Product Focus</Text>
//         <StyledSwitch
//           data-testid="multi-product-focus-switch"
//           size="small"
//           checked={useMultiProductFocus}
//           onChange={(use: boolean) => {
//             handleUseMultiProductFocusChange?.(use, selectedMultiProduct);
//           }}
//         />
//       </HeaderWrapper>
//       {useMultiProductFocus && (
//         <MultiProductItemContainer>
//           {renderMultiProducts(availableMultiProducts)}
//         </MultiProductItemContainer>
//       )}
//     </MultiProductRootContainer>
//   );
// };

// const MultiProductRootContainer = styled.div``;
//
// const MultiProductItemContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap: 10px;
//   padding-top: 5px;
// `;
// interface MultiProductButtonProps {
//   isSelected: boolean;
// }
// const MultiProductButton = styled(IconButton)<MultiProductButtonProps>`
//   background-color: ${(props: MultiProductButtonProps) =>
//     props.isSelected ? "var(--color-primary)" : "transparent"};
//   color: ${(props: MultiProductButtonProps) => (props.isSelected ? "white" : "#a2aaad")};
//
//   &:focus {
//     background-color: ${(props: MultiProductButtonProps) =>
//       props.isSelected ? "var(--color-primary)" : "transparent"};
//     color: ${(props: MultiProductButtonProps) =>
//       props.isSelected ? "white" : "#a2aaad"};
//   }
// `;
//
// const MultiProductItemWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

const HeaderWrapper = styled.div`
  width: 100%;
  min-width: 0;
  display: inline-flex;
  align-items: center;
  gap: 6px;

  .ant-switch-checked:focus {
    box-shadow: none;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-left: auto;
`;

const StyledSpace = styled(Space)`
  display: flex;
  min-width: 200px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const FocusButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  text-align: left;

  & > span {
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const FocusArea = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
`;

const FocusBinContainer = styled.div`
  width: 36rem;
  background-color: var(--color-text-06);
  display: grid;
  gap: 1px;
`;

const FocusBinButton = styled(Button)`
  background: transparent;
  color: #a2aaad;
  display: grid;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--color-selection);
  }

  &:focus {
    color: var(--color-selection);
  }
`;

const ColorPaletteSelector = styled.div`
  border: none;
  height: 32px;
  width: 32px;

  &:hover {
    border-color: var(--color-selection);
    color: var(--color-selection);
  }

  &:focus {
    border-color: var(--color-selection);
    color: var(--color-selection);
  }
`;
export default ChartFocusSettings;
