import { EvaSeriesKind } from "constants/charts.enums";
import { EChartsOption, LineSeriesOption } from "echarts";
import { TypeWellSeriesData, TypeWellSeriesType } from "types/charts/typeWellSeries";

export const updateOptionsWithTypeWellsSeries = (
  options: EChartsOption,
  typeWells: TypeWellSeriesData
): EChartsOption => {
  // return if options.series is not an array
  if (!Array.isArray(options.series)) {
    return options;
  }
  const existingTypeWellSeries = options.series.filter(
    (series: LineSeriesOption) =>
      (series as TypeWellSeriesType).kind === EvaSeriesKind.TypeWell
  );
  const firstExistingTypeWellSeriesData =
    (existingTypeWellSeries?.[0]?.data as Array<[number, number, number]>) ?? [];
  const firstTypeWellSeriesData =
    (typeWells?.[0]?.data as Array<[number, number, number]>) ?? [];
  const differingNumberOfTypeWellsLengths =
    existingTypeWellSeries?.length !== typeWells?.length;
  const differentSeriesDataLengths =
    firstExistingTypeWellSeriesData.length !== firstTypeWellSeriesData.length;
  const differingLastElementOfSeriesData =
    firstExistingTypeWellSeriesData[firstExistingTypeWellSeriesData.length - 1] !==
    firstTypeWellSeriesData[firstTypeWellSeriesData.length - 1];

  const typeWellsMap = {};
  for (const tw of existingTypeWellSeries) {
    typeWellsMap[tw.id] = tw;
  }

  // Check for typeWells length, typeWells.data length as well as last element of typeWells.data
  // if any of that is different, then tw has different data
  let hasDifferentData =
    differingNumberOfTypeWellsLengths ||
    differentSeriesDataLengths ||
    differingLastElementOfSeriesData;

  for (const tw of typeWells) {
    if (!typeWellsMap[tw.id]) {
      hasDifferentData = true;
    }
  }

  //only update the options if the typewell data is different
  //otherwise if we zoom or do anything on the chart it will reset
  return hasDifferentData
    ? Object.assign({}, options, {
        refresh: true,
        series: options.series
          .filter(
            (s: LineSeriesOption) =>
              (s as TypeWellSeriesType).kind !== EvaSeriesKind.TypeWell
          )
          .concat(typeWells)
      })
    : options;
};
