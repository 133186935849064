import { useEffect, useRef } from "react";

export function useChartInstance(domRef: React.RefObject<HTMLDivElement | null>) {
  const chartInstanceRef = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (!domRef.current) return;
    import("hd-echarts/echarts")
      .then((echarts) => {
        if (!domRef.current) return;
        const instance = echarts.init(domRef.current);
        chartInstanceRef.current = instance;
      })
      .catch((err) => {
        throw new Error(`Error initializing hd chart instance:\n ${err.message}`);
      });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.dispose();
        chartInstanceRef.current = null;
      }
    };
  }, [domRef]);

  return chartInstanceRef;
}
