import { EvaChart } from "constants/charts.enums";
import { EChartsOption } from "echarts";
import { EvaChartStates } from "types/factory";

import { translateRateCumResponseToOptions } from ".";
import { translateCrossPlotResponseToOptions } from "./eva/translators/crossPlot";
import { translateCumTimeResponseToOptions } from "./eva/translators/cumTime";
import { translateRateTimeResponseToOptions } from "./eva/translators/rateTime";

export const TRANSLATOR_MAP = {
  [EvaChart.RateCum]: translateRateCumResponseToOptions,
  [EvaChart.CrossPlot]: translateCrossPlotResponseToOptions,
  [EvaChart.CumTime]: translateCumTimeResponseToOptions,
  [EvaChart.RateTime]: translateRateTimeResponseToOptions
};

export function translateToOptions({
  type,
  states
}: {
  type: EvaChart;
  states: Partial<EvaChartStates>;
}): EChartsOption {
  const translator = TRANSLATOR_MAP[type];
  if (!translator) {
    throw new Error(`Unknown chart type: ${type} to translate`);
  }
  return translator(states);
}
