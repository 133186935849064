import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { translateSelectionToLegends } from "entities/charts";
import {
  selectChartForecastFeature,
  selectChartLassoFeature,
  selectChartResponse,
  selectChartType,
  updateHoverLegendGroup,
  updateHoverLegendId,
  updateHoverLegendItem
} from "store/features";
import { RootState } from "store/rootReducer";
import { ChartActionType } from "types/echarts";

export function useChartMouseInteractions(
  chartInstanceRef: React.RefObject<echarts.ECharts>
) {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const chartType = useSelector((state: RootState) => selectChartType(state, id));
  const chartResponse = useSelector((state: RootState) => selectChartResponse(state, id));
  const forecast = useSelector((state: RootState) =>
    selectChartForecastFeature(state, id)
  )?.active;
  const lassoStates = useSelector((state: RootState) =>
    selectChartLassoFeature(state, id)
  );
  const isLassoActive = lassoStates.active;

  useEffect(() => {
    if (!chartInstanceRef.current) return;

    const chartInstance = chartInstanceRef.current;

    function setHoverLegends(params) {
      if (isLassoActive) return;
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      const { hoverLegendItem, hoverLegendGroup, hoverLegendId } =
        translateSelectionToLegends({
          type: chartType,
          params,
          series: seriesArray,
          response: chartResponse,
          forecast: forecast
        });
      dispatch(updateHoverLegendItem(hoverLegendItem));
      dispatch(updateHoverLegendGroup(hoverLegendGroup));
      dispatch(updateHoverLegendId(hoverLegendId));
    }

    const handleHover = (params) => {
      setHoverLegends(params);
    };

    const handleMouseOver = (params) => {
      setHoverLegends(params);
    };

    const handleHighlight = () => {
      // TODO chart: handle forecast highlights here: https://turinganalytics.atlassian.net/browse/EVA-4246
    };

    const handleLineBlur = () => {
      dispatch(updateHoverLegendItem(""));
      dispatch(updateHoverLegendGroup(""));
      dispatch(updateHoverLegendId(""));
    };
    const handleMouseOut = (params) => {
      if (params.seriesName) {
        chartInstanceRef.current.dispatchAction({
          type: "downplay",
          seriesIndex: params.seriesIndex,
          dataIndex: params.dataIndex
        });
        dispatch(updateHoverLegendItem(""));
        dispatch(updateHoverLegendGroup(""));
        dispatch(updateHoverLegendId(""));
      }
    };

    const handleChartClick = () => {
      // manage the click event
    };

    chartInstance.on(ChartActionType.MouseOver, handleMouseOver);
    chartInstance.on(ChartActionType.Highlight, handleHighlight);
    chartInstance.on(ChartActionType.Hover, handleHover);
    chartInstance.on(ChartActionType.MouseOut, handleMouseOut);
    chartInstance.on(ChartActionType.Click, handleChartClick);
    chartInstance.on(ChartActionType.LineBlur, handleLineBlur);

    return () => {
      if (chartInstance && !chartInstance.isDisposed()) {
        chartInstance.off(ChartActionType.MouseOver, handleMouseOver);
        chartInstance.off(ChartActionType.Hover, handleHover);
        chartInstance.off(ChartActionType.MouseOut, handleMouseOut);
        chartInstance.off(ChartActionType.Click, handleChartClick);
        chartInstance.off(ChartActionType.Highlight, handleHighlight);
        chartInstance.off(ChartActionType.LineBlur, handleLineBlur);
      }
    };
  }, [
    chartInstanceRef.current,
    chartType,
    chartResponse,
    forecast,
    isLassoActive,
    dispatch
  ]);
}
