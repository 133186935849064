import { useEffect, useMemo, useRef, useState } from "react";

import axios from "axios";
import { ChartStatus, useChartsContext } from "contexts";
import debounce from "lodash/debounce";
import { ChartRequestType } from "types";

import { IChartResult } from "models/model";

const chartEndpoint = `${process.env.REACT_APP_CHART_SERVICE}/chart`;

const postChartData = async (
  postData: ChartRequestType,
  signal?: AbortSignal
): Promise<IChartResult> => {
  const { data } = await axios.post<IChartResult>(chartEndpoint, postData, { signal });
  return data;
};

export const usePostChartData = (postData: ChartRequestType) => {
  const { setStatus } = useChartsContext();
  const [latestData, setLatestData] = useState<IChartResult | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const debouncedFetch = useMemo(
    () =>
      debounce(async (postData: ChartRequestType) => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        const controller = new AbortController();
        abortControllerRef.current = controller;

        try {
          setLoading(true);
          const data = await postChartData(postData, controller.signal);
          setLatestData(data);
          setError(null);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  useEffect(() => {
    if (postData) {
      debouncedFetch(postData);
    }

    return () => {
      debouncedFetch.cancel();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [postData, debouncedFetch]);

  setStatus(loading ? ChartStatus.Loading : error ? ChartStatus.Error : ChartStatus.Idle);
  return { data: latestData, loading, error };
};
