import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";

import { Tabs as AntDTabs } from "antd";
import {
  ALL_PRODUCT_TYPES,
  ChartProduct,
  ChartProductTypes,
  ChartTypeLabels,
  InjectionProduct,
  ProductKind,
  ProductTypeT,
  SalesProduct,
  SalesProductList,
  ShrinkageProduct,
  SummaryProduct,
  ThroughputProduct,
  getProductLabel,
  yieldsAndRawCatagories
} from "constants/chart.constants";
import { EvaChart, TimeStep } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import styled from "styled-components";
import { getProductTooltip } from "utils";
import { getProductIcon } from "utils/chart/getProductIcon";
import { getShortenedProductName } from "utils/chart/productNameHelpers";

import { useChartEntities } from "hooks/charts/useChartEntities";
import useBetaFeatures from "hooks/useBetaFeatures";

import { WellProductGroups } from "models/chart";
import { EntityKind } from "models/entityKind";

import { BaseMenu, BaseTooltip, Tooltip } from "components/base";
import { useChartProductHandler } from "components/charts/hooks/useChartProductHandler";

import ProductHeader from "../ProductHeader";
import { ProductButton, ProductContainer } from "./ProductSelector.styles";
import { ChartProductGroupT } from "./ProductSelector.types";
import { LiquidYieldsProductHeader } from "./components/LiquidYieldsProductHeader";
import { ShrinkagePercentageProductHeader } from "./components/ShrinkagePercentageProductHeader";
import { getYieldsToggleLabels } from "./utils/getYieldsToggleLabels";
import { removeCO2Items } from "./utils/removeCO2Items";

export interface ProductSelectorProps {
  currentProduct: { name: string; items: string[] }[];
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  activeProductGroup: number; // or appropriate type
  setSelectedProductGroup: (group: number) => void;
  getLiquidYieldsProductHeader?: () => JSX.Element;
  getShrinkagePercentageProductHeader?: () => JSX.Element;
}

const tabs: { key: string; label: ChartProductGroupT }[] = Object.entries(ProductKind)
  .filter(([, value]) => typeof value === "number")
  .map(([key, value]) => ({
    key: value.toString(),
    label: key as ChartProductGroupT
  }));

// midstream has separate product groups
const availableChartProductGroups = WellProductGroups;

export function ProductSelector() {
  useChartProductHandler();
  const { hasFeature } = useBetaFeatures();

  const { id } = useChartsContext();
  const { chartType, product } = useChartEntities(id);
  const dispatch = useDispatch();
  if (!product) {
    dispatch(
      updateChartByPath({
        id,
        path: `product`,
        value: "Oil + Cond"
      })
    );
  }
  const { timeStep } = useChartEntities(id);
  // Current tab; no product selected yet. Can change multiple times.
  const [selectedProductGroup, setSelectedProductGroup] = useState(
    availableChartProductGroups.includes("Throughput")
      ? ProductKind.Throughput
      : SalesProductList.includes(product) && hasFeature("Plant Liquids")
      ? ProductKind.Sales
      : ProductKind.Wellhead
  );
  // Tab with selected product; reopens selector on this tab with active product.
  const [activeProductGroup, setActiveProductGroup] = useState(selectedProductGroup);
  const showProduct = ChartProductTypes.includes(chartType as ChartTypeLabels);

  const { rawLabel, salesLabel } = getYieldsToggleLabels(selectedProductGroup);

  const inletRateLabel = "Inlet Rate";
  const outletRateLabel = "Outlet Rate";

  const [yieldsType, setYieldsType] = useState(rawLabel);

  const onChangeType = () => {
    if (yieldsType === rawLabel) {
      setYieldsType(salesLabel);
    } else {
      setYieldsType(rawLabel);
    }
  };

  const setCloseProductMenuRef = useCallback((closeMenu) => {
    closeProductMenuRef.current = closeMenu;
  }, []);

  const closeProductMenuRef = useRef(null);

  const setProduct = useCallback(
    (product) => {
      setActiveProductGroup(selectedProductGroup);
      dispatch(
        updateChartByPath({
          id,
          path: `product`,
          value: product
        })
      );
      // TODO chart: link with chartSelector
      // setState & typeIdx is used on the ChartHeader to coordinate with the ChartSelector
      // see old ChartSelector and ChartHeader
      // setState({ chartType, product, typeIdx });
      if (closeProductMenuRef.current) {
        closeProductMenuRef.current();
      }
    },
    [chartType, selectedProductGroup] // settings
  );

  useEffect(() => {
    if (
      (product === ALL_PRODUCT_TYPES.CasingPressure.label ||
        product === ALL_PRODUCT_TYPES.TubingPressure.label) &&
      ((timeStep !== TimeStep.Hybrid && timeStep !== TimeStep.Day) ||
        (chartType !== EvaChart.RateCum && chartType !== EvaChart.RateTime))
    ) {
      setProduct(ALL_PRODUCT_TYPES.Oil.label);
    }
  }, [timeStep, product, chartType]);

  useHotkeys(
    "tab",
    () => {
      if (!hasFeature("Plant Liquids")) {
        return;
      }

      // Filters out the product groups that are not available for the current chart type.
      const filteredProductGroupValues = availableChartProductGroups
        .map((group) => ProductKind[group])
        .sort();

      // Cycles through the tabs when the 'tab' key is pressed.
      const numberOfTabs = filteredProductGroupValues.length;
      const currentIndex = filteredProductGroupValues.indexOf(selectedProductGroup);
      const nextIndex = (currentIndex + 1) % numberOfTabs;
      setSelectedProductGroup(filteredProductGroupValues[nextIndex]);
    },
    [selectedProductGroup]
  );

  let currentProduct: ProductTypeT[];
  if (selectedProductGroup === ProductKind["Wellhead"]) {
    currentProduct = ChartProduct;
  } else if (selectedProductGroup === ProductKind["Sales"]) {
    currentProduct = SalesProduct;
  } else if (selectedProductGroup === ProductKind["Throughput"]) {
    currentProduct = ThroughputProduct;
  } else if (selectedProductGroup === ProductKind["Shrinkage"]) {
    currentProduct = ShrinkageProduct;
  } else if (selectedProductGroup === ProductKind["Summary"]) {
    currentProduct = SummaryProduct;
  } else {
    currentProduct = InjectionProduct;
  }

  const hasPlantLiquids = hasFeature("Plant Liquids");
  const hasFacilityVolumes = hasFeature("Facility Volumes");

  if (hasFacilityVolumes) {
    currentProduct = removeCO2Items(currentProduct);
  }

  const productHeaderParams = {
    selectedProductGroup,
    yieldsType,
    rawLabel,
    salesLabel,
    onChangeType,
    setProduct,
    product,
    inletRateLabel,
    outletRateLabel
  };

  return (
    <div className={`product-selector_button-wrapper`}>
      {showProduct && (
        // use Base components because antd would automatically open and popover location was not desirable, discovered in EVA-4042
        <BaseTooltip
          text={
            product
              ? getProductLabel(product, EntityKind.Well).replace("Yields", "Yield")
              : ""
          }>
          <BaseMenu
            onOpen={() => setSelectedProductGroup(activeProductGroup)}
            trigger={<IconDropdown>{getProductIcon(product)}</IconDropdown>}>
            {({ closeMenu }) => (
              <ProductContainer>
                {hasPlantLiquids && (
                  <AntDTabs
                    items={tabs.filter((tab) =>
                      availableChartProductGroups.includes(tab.label)
                    )}
                    activeKey={selectedProductGroup.toString()}
                    onChange={(newTabKey: string) =>
                      setSelectedProductGroup(parseInt(newTabKey))
                    }
                  />
                )}
                {setCloseProductMenuRef(closeMenu)}
                {currentProduct.map((header) =>
                  !yieldsAndRawCatagories.includes(header.name) ? (
                    <ProductHeader key={header.name} header={header.name}>
                      {header.items
                        .filter(
                          (prod) =>
                            prod !== "Intra-Network Gas Inlet" &&
                            prod !== "Intra-Network Gas Outlet"
                        )
                        .map((prod) => {
                          if (
                            prod === ALL_PRODUCT_TYPES.CasingPressure.label ||
                            prod === ALL_PRODUCT_TYPES.TubingPressure.label
                          ) {
                            if (
                              (chartType !== EvaChart.RateCum &&
                                chartType !== EvaChart.RateTime) ||
                              (timeStep !== TimeStep.Hybrid && timeStep !== TimeStep.Day)
                            ) {
                              return null;
                            }
                          }

                          return (
                            <Tooltip
                              key={prod}
                              title={getProductTooltip(prod, selectedProductGroup)}>
                              <ProductButton
                                isDisabled={
                                  // chartType ===
                                  //   ALL_CHART_TYPES.MaterialBalanceTime.label &&
                                  // !MaterialBalanceTimeChartProducts.includes(prod)
                                  false
                                }
                                key={prod}
                                ellipses={false}
                                onClick={() => {
                                  // TODO chart: reset axisMinMax and reference lines
                                  setProduct(prod);
                                }}
                                appearance=""
                                className={`${prod === product ? "isSelected" : null}`}>
                                {getProductIcon(prod)}
                                <span>
                                  {getShortenedProductName(
                                    selectedProductGroup,
                                    getProductLabel(prod, EntityKind.Well),
                                    rawLabel,
                                    salesLabel,
                                    inletRateLabel,
                                    outletRateLabel
                                  )}
                                </span>
                              </ProductButton>
                            </Tooltip>
                          );
                        })}
                    </ProductHeader>
                  ) : null
                )}
                <ShrinkagePercentageProductHeader {...productHeaderParams} />
                <LiquidYieldsProductHeader {...productHeaderParams} />
              </ProductContainer>
            )}
          </BaseMenu>
        </BaseTooltip>
      )}
    </div>
  );
}

const IconDropdown = styled.div`
  color: #a2aaad;
  transition: color var(--duration-short);
  cursor: pointer;
  display: flex;
  &:hover {
    color: var(--color-primary);
  }
  margin-left: 21px;
`;
