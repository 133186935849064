import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { useProjectContext } from "components/project/projects/context";

import { useSetProjectLayerOrderMutation } from "../../mutations";
import {
  IProjectLayer,
  PROJECT_LAYER_LIST_QUERY_KEYS,
  fetchProjectLayerList,
  useProjectLayerListQuery
} from "../../queries";
import { createEditableLayerList, createReorderedLayerList } from "./utils";

export interface IEditableProjectLayer extends IProjectLayer {
  isChecked: boolean;
  dependantLayer?: string;
  isMoving: boolean;
  zoomLevel: number;
  originalOrder: number;
}

export type EditableProjectLayerListReturnT = {
  data: IEditableProjectLayer[];
  checkedShapefiles: Set<string>;
  checkedProjectShapefileIds: string[];
  checkedProjectLinkedShapefileIds: string[];
  error: string;
  warning: string;
  isLoadingLayers: boolean;
  isReorderingLayers: boolean;
  reorderLayerList: (params: {
    layerId: string;
    listOrder: number;
    order: number;
  }) => void;
};

export type LayerListFilterTypesT = "dependantLayers" | "unselectedLayers";

export type EditableProjectLayerListParamsT = {
  projectId: string;
  isAutoFetching?: boolean;
  filters?: LayerListFilterTypesT[];
};

export function useEditableProjectLayerList({
  projectId,
  isAutoFetching,
  filters
}: EditableProjectLayerListParamsT): EditableProjectLayerListReturnT {
  const queryClient = useQueryClient();

  // Layer list edge case:
  // Each row uses this hook, and as each row is added to the virtual list on scroll,
  // it will create a new query for each new row rendered and auto refetch, which just isn't needed.
  const nextIsAutoFetching =
    isAutoFetching === false
      ? !queryClient.getQueryData([
          PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
          projectId
        ])
      : undefined;

  // Queries
  const layersQuery = useProjectLayerListQuery({
    projectId: projectId,
    isAutoRefetching: nextIsAutoFetching
  });

  // Mutations
  const setLayerOrderMutation = useSetProjectLayerOrderMutation({
    projectId: projectId
  });

  // Context
  const projectContext = useProjectContext();

  // State
  const [layerList, setLayerList] = useState<IEditableProjectLayer[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);

  const [checkedShapefiles, setCheckedShapefiles] = useState<Set<string>>(
    new Set<string>()
  );

  const [checkedProjectShapefileIds, setCheckedProjectShapefileIds] = useState<string[]>(
    []
  );

  const checkForLayerOrderConflicts = (
    currentLayers: IEditableProjectLayer[],
    latestLayers: IProjectLayer[]
  ): boolean => {
    const currentLayerOrderMap = currentLayers.reduce((map, layer) => {
      map[layer.layerId] = layer.originalOrder;
      return map;
    }, {});

    const latestLayerOrderMap = latestLayers.reduce((map, layer) => {
      map[layer.layerId] = layer.order;
      return map;
    }, {});

    return currentLayers.some(
      (layer) =>
        latestLayerOrderMap[layer.layerId] !== currentLayerOrderMap[layer.layerId]
    );
  };

  const [checkedProjectLinkedShapefileIds, setCheckedProjectLinkedShapefileIds] =
    useState<string[]>([]);

  // Order 0 is the top of the list
  const reorderLayerList = async ({ layerId, listOrder, order }) => {
    setWarning(null);
    setError(null);
    const layerBeingRepositioned = layerList.find((layer) => layer.order === listOrder);

    if (layerBeingRepositioned?.layerId === layerId) {
      return;
    }

    const reorderedLayerList = createReorderedLayerList({
      layerList: layerList,
      layerId,
      order: listOrder
    });

    if (JSON.stringify(reorderedLayerList) === JSON.stringify(layerList)) {
      // eslint-disable-next-line no-console
      console.error(`The same list was returned, no change possible`);
      return;
    }

    setLayerList(reorderedLayerList);

    try {
      const response = await fetchProjectLayerList({
        queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
      });
      const latestData = response.data;

      if (!latestData || !Array.isArray(latestData)) {
        return;
      }

      if (checkForLayerOrderConflicts(layerList, latestData)) {
        setWarning("Another user has recently modified the layer order.");
      }

      setLayerOrderMutation.mutate({ projectId, layerId, order });
    } catch (error) {
      setError("Error reordering layers.");
    }
  };

  // Sync server data with local state
  useEffect(() => {
    const {
      list,
      checkedProjectLinkedShapefileIds,
      checkedProjectShapefileIds,
      checkedShapefiles
    } = createEditableLayerList({
      layerList: layersQuery.data,
      checkedKeys: projectContext.checkedLayerKeys,
      filters: filters
      // other state, cache, global, etc
    });

    // TODO BF: just make this a single state object
    setLayerList(list);
    setCheckedShapefiles(checkedShapefiles);
    setCheckedProjectShapefileIds(checkedProjectShapefileIds);
    setCheckedProjectLinkedShapefileIds(checkedProjectLinkedShapefileIds);
  }, [
    layersQuery.data,
    projectContext.checkedLayerKeys,
    projectContext.isHidingUnselectedLayers
  ]);

  return {
    data: layerList,
    checkedShapefiles,
    checkedProjectShapefileIds,
    checkedProjectLinkedShapefileIds,
    reorderLayerList: reorderLayerList,
    isLoadingLayers: layersQuery.isLoading,
    isReorderingLayers: setLayerOrderMutation.isLoading,
    error: error,
    warning: warning
  };
}
