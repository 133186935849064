import { EvaChart } from "constants/charts.enums";

import { checkIsProductInRateDateCategory } from "components/charts/components/ProductSelector/utils/checkIsProductInRateDateCategory";

export const checkIncompatibleProductWithTotalRateDate = (
  chartType: EvaChart,
  product: string
): boolean => {
  if (
    chartType === EvaChart.TotalRateDate &&
    (checkIsProductInRateDateCategory(product) ||
      product === "Heating Value" ||
      product === "Shrinkage" ||
      product === "Inlet Utilization")
  ) {
    return true;
  }
  return false;
};
