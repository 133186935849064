import { createStateWithDefaults } from "entities/utils";
import { ChartEntity } from "types";

export const UNDEFINED_CHART_FEATURES = {
  screenshot: undefined,
  fullScreen: undefined,
  datatable: undefined,
  spaghetti: undefined,
  forecast: undefined,
  source: undefined,
  debug: undefined,
  timeStep: undefined,
  isProducingRateType: undefined,
  cutoff: undefined,
  shutInMonths: undefined,
  movingAverageDays: undefined,
  survivorBias: undefined,
  useWeightedRatioAverage: undefined,
  lockUnits: undefined,
  lasso: undefined,
  typewells: undefined,
  p10: undefined,
  p50: undefined,
  p90: undefined,
  average: undefined,
  normalization: undefined,
  chartFocus: undefined
};

export const UNDEFINED_CHART_ENTITY: Partial<ChartEntity> = {
  id: "",
  chartType: undefined,
  entityKind: undefined,
  screenshot: {
    size: { width: undefined, height: undefined },
    editables: []
  },
  request: undefined,
  response: undefined,
  product: undefined,
  features: UNDEFINED_CHART_FEATURES,
  options: ""
};

export const generateChartEntity = createStateWithDefaults(UNDEFINED_CHART_ENTITY);
