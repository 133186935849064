import { EvaChart } from "constants/charts.enums";
import { EvaChartFeatures } from "types";

import { crossPlotFeatures } from "./crossPlot";
import { cumTimeFeatures } from "./cumTime";
import { rateCumFeatures } from "./rateCum";
import { rateTimeFeatures } from "./rateTime";

export const FEATURE_MAP = {
  [EvaChart.RateCum]: rateCumFeatures,
  [EvaChart.CrossPlot]: crossPlotFeatures,
  [EvaChart.CumTime]: cumTimeFeatures,
  [EvaChart.RateTime]: rateTimeFeatures
};

export function createFeatureEntities(
  type: EvaChart,
  overrides: Partial<EvaChartFeatures> = {}
): EvaChartFeatures {
  const features = FEATURE_MAP[type];
  if (!features) {
    throw new Error(`Unknown chart type: ${type} to create features`);
  }
  return features(overrides);
}
