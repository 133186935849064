import { LassoSelectionStates } from "types/lasso";

import { getScatterFilteredItems } from "./utils";

export function getCrossPlotList({
  params,
  series,
  uwiList
}: Partial<LassoSelectionStates>) {
  const list = getScatterFilteredItems(params, series, uwiList);
  return list;
}
