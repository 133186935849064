import { FC } from "react";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Button, Checkbox, InputNumber } from "antd";
import styled from "styled-components";

import { INormalizeBySetting } from "models";

import GroupBy from "components/groupBy";

import { calculateDisplayUnit } from "../util/calculateDisplayUnit";
import MultilinearNormalizeBySettings from "./MultilinearNormalizeBySettings";

type NormalizeSettingsT = {
  value: INormalizeBySetting;
  onChange: (v: INormalizeBySetting) => void;
};

const NormalizeBySettings: FC<NormalizeSettingsT> = ({ value, onChange }) => {
  // derived state
  const { display, field, per, unit, useMultilinearNormalization } = value;
  const displayUnit = calculateDisplayUnit(unit, field, display);

  const groupByValue = {
    property: field,
    title: display
  };

  // handlers
  function handlePerChange(nextPer: number) {
    const nextValue = { ...value, per: nextPer };
    onChange(nextValue);
  }

  function handleMultilinearNormalizationChange(newValue: boolean) {
    const nextValue = { ...value, useMultilinearNormalization: newValue };
    onChange(nextValue);
  }

  function handleFieldChange(groupBy) {
    const { property, title, defaultUnit, normalizeByDefault } = groupBy;
    const next: INormalizeBySetting = {
      display: title,
      field: property,
      per: normalizeByDefault,
      unit: defaultUnit,
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    };

    onChange(next);
  }

  return (
    <>
      <Row>
        <LeftField>
          <Label>Normalize Field</Label>
          <GroupBy
            value={groupByValue}
            dataTypeFilters={["Number", "Integer"]}
            editable={false}
            placement="rightTop"
            onChange={handleFieldChange}>
            <StyledButton block>
              {display}
              <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
            </StyledButton>
          </GroupBy>
        </LeftField>

        <RightField>
          <Label>Per ({displayUnit})</Label>
          <StyledNumberInput
            value={per}
            min={1}
            controls={false}
            onChange={handlePerChange}
          />
        </RightField>
      </Row>
      <Row>
        <Checkbox
          data-testid="normalize-multilinear-checkbox"
          checked={useMultilinearNormalization}
          onChange={(v) => handleMultilinearNormalizationChange(v.target.checked)}>
          Multilinear Normalization
        </Checkbox>
      </Row>
      {useMultilinearNormalization && (
        <MultilinearNormalizeBySettings value={value} onChange={onChange} />
      )}
    </>
  );
};

export default NormalizeBySettings;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5%;
  margin-bottom: 10px;
`;

const LeftField = styled.div`
  width: 62%;
`;

const RightField = styled.div`
  width: 33%;
`;

const Label = styled.span`
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  height: 30px;
  text-align: left;

  & > span {
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledNumberInput = styled(InputNumber)`
  width: 100%;
  height: 30px;
`;
