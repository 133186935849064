import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _zip from "lodash/zip";
import { EvaChartStates } from "types/factory";

import { updateOptionsWithTypeWellsSeries } from "./updateOptionsWithTypeWellSeries";

export function translateRateCumResponseToOptions(states: Partial<EvaChartStates>) {
  const { title, layout, series, debug, typeWellSeries = [] } = states;

  const yAxisTitle = layout?.yAxis?.title || "";

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.RateCum,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: yAxisTitle,
        nameGap: 43,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." }
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        name: layout.xAxis.title,
        nameGap: 27,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        useY: true // useY is managed at hd chart
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    series: series.map((location, index) => {
      const { label, style, x, y, groupTitle } = location;
      const data = _zip(x, y);
      const id =
        groupTitle !== label ? `${groupTitle}, ${label}` : `${groupTitle}, ${index}`;
      const lineSeries = createEntityState(ChartOption.LineSeries, {
        id,
        name: label,
        data: data,
        itemStyle: { color: style?.hexColor ?? "#000" },
        lineStyle: {
          width: style?.thickness ?? 2,
          color: style?.hexColor ?? "#000",
          type: location.isForecast ? "dashed" : "solid"
        },
        showSymbol: data.length > 1 ? false : true
      });

      return lineSeries;
    })
  });

  if (debug?.active) {
    // eslint-disable-next-line no-console
    console.log(`option=${JSON.stringify(options)}`);
  }

  if (typeWellSeries.length > 0) {
    options = updateOptionsWithTypeWellsSeries(options, typeWellSeries);
  }
  return options;
}
