import { useDispatch } from "react-redux";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Button, Checkbox, Divider, InputNumber, Space } from "antd";
import {
  DEFAULT_NORMALIZE_BY_HIGHER_SCALER,
  DEFAULT_NORMALIZE_BY_LOWER_SCALER,
  DEFAULT_NORMALIZE_BY_THRESHOLD
} from "constants/chart.constants";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import styled from "styled-components";

import { INormalizeBySetting } from "models";

import { LabeledSwitch } from "components/base";
import GroupBy from "components/groupBy";

import { useChartEntities } from "../../../../hooks/charts/useChartEntities";
import { calculateDisplayUnit } from "../../../filter/util/calculateDisplayUnit";

const NormalizeBySettings = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { normalizeBy } = useChartEntities(id);
  const normalizationSettings = normalizeBy.properties;

  const {
    displayName,
    field,
    per,
    unit,
    useMultilinearNormalization,
    useNormalizeBy,
    threshold,
    higherScalar
  } = normalizationSettings;

  const displayUnit = calculateDisplayUnit(
    unit as string,
    field as string,
    displayName as string
  );

  const groupByValue = {
    property: field as string,
    title: displayName as string
  };

  const handleNormalizationSettingsChange =
    (key: keyof INormalizeBySetting) => (value: string | number | boolean) => {
      dispatch(
        updateChartByPath({
          id,
          path: `features.normalization.properties.${key}`,
          value: value
        })
      );
    };

  const handleToggle = (active: boolean) => {
    handleNormalizationSettingsChange("useNormalizeBy")(active);

    dispatch(
      updateChartByPath({
        id,
        path: "features.normalization.active",
        value: active
      })
    );
  };

  const handleFieldChange = (groupBy): void => {
    const { property, title, defaultUnit, normalizeByDefault } = groupBy;
    const next: Partial<INormalizeBySetting> = {
      ...normalizationSettings,
      displayName: title,
      field: property,
      per: normalizeByDefault,
      unit: defaultUnit,
      useMultilinearNormalization: false,
      threshold: DEFAULT_NORMALIZE_BY_THRESHOLD,
      lowerScalar: DEFAULT_NORMALIZE_BY_LOWER_SCALER, //technically not able to edit this value anymore, leaving it as default
      higherScalar: DEFAULT_NORMALIZE_BY_HIGHER_SCALER
    };

    dispatch(
      updateChartByPath({
        id,
        path: "features.normalization.properties",
        value: next
      })
    );
  };

  return (
    <Wrapper>
      <StyledSpace direction="vertical">
        <LabeledSwitch
          testId="normalizeSwitch"
          switch={{
            isChecked: useNormalizeBy as boolean,
            onChange: handleToggle
          }}
          label={{
            value: "Normalize",
            isBold: true,
            isUppercase: true
          }}
        />
        {useNormalizeBy && (
          <>
            <StyledDivider />
            <Row>
              <LeftField>
                <Label>Normalize Field</Label>
                <GroupBy
                  value={groupByValue}
                  dataTypeFilters={["Number", "Integer"]}
                  editable={false}
                  placement="rightTop"
                  onChange={handleFieldChange}>
                  <StyledButton block>
                    {displayName}
                    <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
                  </StyledButton>
                </GroupBy>
              </LeftField>

              <RightField>
                <Label>Per ({displayUnit})</Label>
                <StyledNumberInput
                  data-testid="normalizePerInput"
                  value={per}
                  min={1}
                  controls={false}
                  onChange={handleNormalizationSettingsChange("per")}
                />
              </RightField>
            </Row>
            <Row>
              <Checkbox
                data-testid="normalizeMultilinearCheckbox"
                checked={useMultilinearNormalization as boolean}
                onChange={(v) =>
                  handleNormalizationSettingsChange("useMultilinearNormalization")(
                    v.target.checked
                  )
                }>
                Multilinear Normalization
              </Checkbox>
            </Row>
            {useMultilinearNormalization && (
              <Row>
                <CenterField>
                  <Label>Cut Off Threshold ({displayUnit})</Label>
                  <StyledNumberInput
                    data-testid="normalizeThresholdInput"
                    value={threshold}
                    controls={false}
                    min={0}
                    onChange={handleNormalizationSettingsChange("threshold")}
                  />
                </CenterField>
                <CenterField>
                  <Label>Beyond Threshold Scalar</Label>
                  <StyledNumberInput
                    data-testid="normalizeHigherScalarInput"
                    value={higherScalar}
                    controls={false}
                    min={0}
                    onChange={handleNormalizationSettingsChange("higherScalar")}
                  />
                </CenterField>
              </Row>
            )}
          </>
        )}
      </StyledSpace>
    </Wrapper>
  );
};

export default NormalizeBySettings;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5%;
  margin-bottom: 10px;
`;

const LeftField = styled.div`
  width: 62%;
`;

const RightField = styled.div`
  width: 33%;
`;

const Label = styled.span`
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  height: 30px;
  text-align: left;

  & > span {
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledNumberInput = styled(InputNumber)`
  width: 100%;
  height: 30px;
`;

const Wrapper = styled.div`
  width: 380px;
  max-width: 380px;
  background: var(--normalize-section-bg, #ffffff);
  font-weight: 500;
  padding: 20px 20px;
  text-align: left;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

const CenterField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
