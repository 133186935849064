import { toast } from "react-toastify";

import { IChartResult } from "../../../models/model";

const handleMissingChartDataToasts = (apiResponse: IChartResult, id: string) => {
  const isMissingEntities = (element: { message: string }) =>
    element.message === "Missing Entities";
  const isMissingTypeWells = (element: { message: string }) =>
    element.message === "Missing Type Wells";
  const missingEntitiesIndex = apiResponse.messages.findIndex(isMissingEntities);
  const missingTypeWellsIndex = apiResponse.messages.findIndex(isMissingTypeWells);
  if (missingEntitiesIndex != -1 && id) {
    const wellNormalizationIdWarn = `well-norm-warn-${id}`;
    toast.dismiss(wellNormalizationIdWarn);
    const message =
      apiResponse.errorEntities.length === 1
        ? `${apiResponse.errorEntities.length} entity without normalization data was removed from the chart.`
        : `${apiResponse.errorEntities.length} entities without normalization data were removed from the chart.`;
    toast.warning(message, {
      containerId: id,
      toastId: wellNormalizationIdWarn
    });
  }
  if (missingTypeWellsIndex != -1 && id) {
    const typeWellNormalizationIdWarn = `type-well-norm-warn-${id}`;
    toast.dismiss(typeWellNormalizationIdWarn);
    const message =
      apiResponse.messages[missingTypeWellsIndex].entities.length === 1
        ? `${apiResponse.messages[missingTypeWellsIndex].entities.length} type well without normalization data was removed from the chart. To allow normalizing, HZ Length, Stages Actual, and Proppant Total can be entered for the type well.`
        : `${apiResponse.messages[missingTypeWellsIndex].entities.length} type wells without normalization data were removed from the chart. To allow normalizing, HZ Length, Stages Actual, and Proppant Total can be entered for the type wells.`;
    toast.warning(message, {
      containerId: id,
      toastId: typeWellNormalizationIdWarn
    });
  }
};

export default handleMissingChartDataToasts;
