import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UNDEFINED_CHART_FEATURES } from "entities/charts/chart";
import {
  DEFAULT_CHART_FOCUS_ENTITY,
  DEFAULT_CUT_OFF_FEATURE_ENTITY,
  DEFAULT_DATA_TABLE_FEATURE_ENTITY,
  DEFAULT_FEATURE_ENTITY,
  DEFAULT_LASSO_FEATURE_ENTITY,
  DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY,
  DEFAULT_NORMALIZATION_ENTITY,
  DEFAULT_SCREENSHOT_FEATURE_ENTITY,
  DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY,
  DEFAULT_SOURCE_FEATURE_ENTITY,
  DEFAULT_TIME_STEP_FEATURE_ENTITY
} from "entities/charts/eva/features";
import { WritableDraft } from "immer/dist/internal";
import { ChartEntity } from "types";

export interface ChartSliceState {
  setting?: {
    overrideSizeLimit?: boolean;
  };
  chart: Record<string, ChartEntity>;
}

const initialState: ChartSliceState = {
  setting: {
    overrideSizeLimit: false
  },
  chart: {}
};

export const chartSlice = createSlice({
  name: "charts",
  initialState,
  reducers: (create) => ({
    updateOverrideSizeLimit: create.reducer((state, action: PayloadAction<boolean>) => {
      state.setting.overrideSizeLimit = action.payload;
    }),
    createChart: create.reducer((state, action: PayloadAction<Partial<ChartEntity>>) => {
      if (!state.chart[action.payload.id]) {
        Object.assign(state.chart, { [action.payload.id]: action.payload });
      }
    }),
    updateChart: create.reducer((state, action: PayloadAction<Partial<ChartEntity>>) => {
      if (state.chart[action.payload.id]) {
        Object.assign(state.chart[action.payload.id], action.payload);
      }
    }),
    removeChart: create.reducer((state, action: PayloadAction<string>) => {
      delete state.chart[action.payload];
    }),
    reset: create.reducer(() => initialState),
    updateChartByPath: create.reducer(
      (state, action: PayloadAction<{ id: string; path: string; value: Primitive }>) => {
        const chart = state.chart[action.payload.id];
        if (chart) {
          const keys = action.payload.path.split(".");
          let current = chart;
          for (let i = 0; i < keys.length - 1; i++) {
            if (!current[keys[i]]) {
              return;
            }

            current = current[keys[i]] as WritableDraft<ChartEntity>;
          }
          const lastKey = keys[keys.length - 1];
          if (lastKey in current) {
            current[lastKey] = action.payload.value;
          }
        }
      }
    ),
    updateChartSize: create.reducer(
      (state, action: PayloadAction<{ id: string; width: number; height: number }>) => {
        const chart = state.chart[action.payload.id];
        if (chart) {
          chart.screenshot.size = {
            width: action.payload.width,
            height: action.payload.height
          };
        }
      }
    )
  }),
  selectors: {
    selectChart: (state: ChartSliceState, id: string) => state.chart[id],
    selectChartOptions: (state: ChartSliceState, id: string) =>
      state.chart[id]?.options ?? "",
    selectChartType: (state: ChartSliceState, id: string) =>
      state.chart[id]?.chartType ?? undefined,
    selectChartProduct: (state: ChartSliceState, id: string) =>
      state.chart[id]?.product ?? undefined,
    selectChartFeatures: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features ?? UNDEFINED_CHART_FEATURES,
    selectChartResponse: (state: ChartSliceState, id: string) =>
      state.chart[id]?.response ?? undefined,
    selectChartRequest: (state: ChartSliceState, id: string) =>
      state.chart[id]?.request ?? undefined,
    selectChartBounds: (state: ChartSliceState, id: string) =>
      state.chart[id]?.bounds ?? undefined,
    selectChartByPath: (state: ChartSliceState, id: string, path: string) => {
      const chart = state.chart[id];
      if (!chart) return undefined;
      const keys = path.split(".");

      let current = chart;
      for (const key of keys) {
        if (!current || !(key in current)) {
          return undefined;
        }
        current = current[key] as ChartEntity;
      }

      return current;
    },
    selectChartEntityKind: (state: ChartSliceState, id: string) =>
      state.chart[id]?.entityKind ?? undefined,
    selectOverrideSizeLimit: (state: ChartSliceState) => state.setting.overrideSizeLimit,
    selectChartSpaghettiFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.spaghetti ?? DEFAULT_FEATURE_ENTITY,
    selectChartDebugFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.debug ?? DEFAULT_FEATURE_ENTITY,
    selectChartForecastFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.forecast ?? DEFAULT_FEATURE_ENTITY,
    selectChartSourceFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.source ?? DEFAULT_SOURCE_FEATURE_ENTITY,
    selectChartIsProducingRateTypeFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.isProducingRateType ?? DEFAULT_SOURCE_FEATURE_ENTITY,
    selectChartTimeStepFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.timeStep ?? DEFAULT_TIME_STEP_FEATURE_ENTITY,
    selectChartDataTableFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.datatable ?? DEFAULT_DATA_TABLE_FEATURE_ENTITY,
    selectChartCutoffFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.cutoff ?? DEFAULT_CUT_OFF_FEATURE_ENTITY,
    selectChartShutInMonthsFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.shutInMonths ?? DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY,
    selectChartMovingAverageDaysFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.movingAverageDays ??
      DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY,
    selectChartSurvivorBiasFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.survivorBias ?? DEFAULT_FEATURE_ENTITY,
    selectChartUseWeightedRatioAverageFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.useWeightedRatioAverage ?? DEFAULT_FEATURE_ENTITY,
    selectChartLockUnitsFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.lockUnits ?? DEFAULT_FEATURE_ENTITY,
    selectChartLassoFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.lasso ?? DEFAULT_LASSO_FEATURE_ENTITY,
    selectChartLassoList: (state: ChartSliceState, id: string) =>
      (state.chart[id]?.features?.lasso?.properties?.list as string[]) ?? [],
    selectChartLassoBrushEnd: (state: ChartSliceState, id: string) =>
      (state.chart[id]?.features?.lasso?.properties?.brushEnd ?? false) as boolean,
    selectChartLassoBrushPosition: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.lasso?.properties?.position as {
        x: number;
        y: number;
      },
    selectChartTypeWellsFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.typewells ?? DEFAULT_FEATURE_ENTITY,
    selectChartP10Feature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.p10 ?? DEFAULT_FEATURE_ENTITY,
    selectChartP50Feature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.p50 ?? DEFAULT_FEATURE_ENTITY,
    selectChartP90Feature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.p90 ?? DEFAULT_FEATURE_ENTITY,
    selectChartAverageFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.average ?? DEFAULT_FEATURE_ENTITY,
    selectChartScreenshotFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.screenshot ?? DEFAULT_SCREENSHOT_FEATURE_ENTITY,
    selectChartFullScreenFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.fullScreen ?? DEFAULT_FEATURE_ENTITY,
    selectChartNormalizationFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.normalization ?? DEFAULT_NORMALIZATION_ENTITY,
    selectChartFocusFeature: (state: ChartSliceState, id: string) =>
      state.chart[id]?.features?.chartFocus ?? DEFAULT_CHART_FOCUS_ENTITY
  }
});

export const {
  createChart,
  updateChart,
  removeChart,
  updateChartByPath,
  updateChartSize,
  reset,
  updateOverrideSizeLimit
} = chartSlice.actions;
export const {
  selectChartOptions,
  selectChartType,
  selectChartProduct,
  selectChartResponse,
  selectChartRequest,
  selectChartBounds,
  selectChart,
  selectChartFeatures,
  selectChartByPath,
  selectChartEntityKind,
  selectOverrideSizeLimit,
  selectChartDebugFeature,
  selectChartForecastFeature,
  selectChartSourceFeature,
  selectChartIsProducingRateTypeFeature,
  selectChartTimeStepFeature,
  selectChartCutoffFeature,
  selectChartShutInMonthsFeature,
  selectChartMovingAverageDaysFeature,
  selectChartSurvivorBiasFeature,
  selectChartUseWeightedRatioAverageFeature,
  selectChartLockUnitsFeature,
  selectChartSpaghettiFeature,
  selectChartDataTableFeature,
  selectChartLassoFeature,
  selectChartLassoList,
  selectChartLassoBrushEnd,
  selectChartLassoBrushPosition,
  selectChartTypeWellsFeature,
  selectChartP10Feature,
  selectChartP50Feature,
  selectChartP90Feature,
  selectChartAverageFeature,
  selectChartScreenshotFeature,
  selectChartFullScreenFeature,
  selectChartNormalizationFeature,
  selectChartFocusFeature
} = chartSlice.selectors;

export default chartSlice.reducer;
