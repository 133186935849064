import { LegendStates, MouseSelectionStates, SelectedParams } from "types";
import { SeriesType } from "types/echarts";
import { allTrue } from "utils/fp";

import {
  UNDEFINED_DATA_POINT,
  getDataPointCurry,
  getSeriesIndex,
  getSeriesIndexFromSelectedGroups,
  getSeriesName,
  isValidChartLegendStates
} from "./utils";

export function crossPlotHoverLegends({
  params,
  series,
  response
}: Partial<MouseSelectionStates>) {
  if (params.seriesIndex === undefined || params.dataIndex === undefined || !series)
    return { hoverLegendItem: undefined, hoverLegendGroup: undefined };
  const data = series[params.seriesIndex].data[params.dataIndex];
  const wellName = response.uwis[data[2]];
  const seriesName = params.seriesName || getSeriesName({ params, series });

  // Notes:
  // For focus fields, hoverLegendItem is expected to represent the group name for highlighting.
  // Currently, hovering over data points on the cross plot or  rate cum at spaghetti does not highlight the corresponding bins.
  // We have maintained this behavior in the new chart. To enable highlighting, change hoverLegendItem to seriesName.

  return {
    hoverLegendItem: wellName,
    hoverLegendGroup: seriesName
  };
}

export function crossPlotSelectionParams({
  states,
  series
}: Partial<LegendStates>): SelectedParams {
  const { hoverLegendGroup, hoverLegendItem, attentionWells, selectedGroups } = states;

  const isInvalid = isValidChartLegendStates({ states, series });
  if (isInvalid) return UNDEFINED_DATA_POINT;

  const isHoveringOnBins = allTrue(
    hoverLegendItem?.length > 0,
    !hoverLegendGroup,
    attentionWells?.length === 0
  );
  const isHoveringOnChart = allTrue(
    hoverLegendItem?.length > 0,
    hoverLegendGroup?.length > 0,
    hoverLegendGroup !== hoverLegendItem
  );

  const isHoveringOnMap = allTrue(
    hoverLegendItem?.length > 0,
    !hoverLegendGroup,
    attentionWells?.length > 0
  );

  const onlySelectedGroup = allTrue(
    !hoverLegendItem,
    !hoverLegendGroup,
    attentionWells?.length === 0,
    selectedGroups?.length > 0
  );

  const selectedIndices = getSeriesIndexFromSelectedGroups({ states, series });

  const curriedGetDataPoint = getDataPointCurry(states, series);

  const createResultForDataPoint = (candidate): SelectedParams => {
    const isValidDataPoint =
      typeof candidate?.seriesIndex === "number" &&
      typeof candidate?.targetIndex === "number";
    return {
      seriesIndex: candidate?.seriesIndex,
      targetIndex: candidate?.targetIndex,
      seriesType: isValidDataPoint ? SeriesType.Scatter : SeriesType.None
    };
  };

  const conditions = [
    {
      predicate: () => isHoveringOnBins,
      transform: () => ({
        seriesIndex: [...getSeriesIndex({ states, series }), ...selectedIndices],
        targetIndex: undefined,
        seriesType: SeriesType.Scatter
      })
    },
    {
      predicate: () => isHoveringOnChart,
      transform: () => {
        const candidate = curriedGetDataPoint(hoverLegendItem, hoverLegendGroup);
        return createResultForDataPoint(candidate);
      }
    },
    {
      predicate: () => isHoveringOnMap,
      transform: () => {
        const candidate = curriedGetDataPoint(attentionWells[0], hoverLegendItem);
        return createResultForDataPoint(candidate);
      }
    },
    {
      predicate: () => onlySelectedGroup,
      transform: () => ({
        seriesIndex: [...selectedIndices],
        targetIndex: undefined,
        seriesType: SeriesType.Scatter
      })
    }
  ];

  const result = conditions.find((condition) => condition.predicate())?.transform();

  return result ?? UNDEFINED_DATA_POINT;
}
