import { IS_DEV_ENV } from "constants/app.constants";
import { EntityType } from "constants/entities.enum";
import { createEntityState } from "entities/charts/factory";
import { createMergeWithDefaults } from "entities/utils";
import { EvaChartFeatures } from "types";

const CROSS_PLOT_FEATURES: Partial<EvaChartFeatures> = {
  screenshot: createEntityState(EntityType.ScreenshotFeature, {
    show: true,
    enabled: true
  }),
  fullScreen: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  datatable: createEntityState(EntityType.ChartDataTableFeature, {
    show: true,
    enabled: true
  }),
  spaghetti: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: false
  }),
  forecast: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  source: createEntityState(EntityType.SourceFeature, { show: true, enabled: true }),
  debug: createEntityState(EntityType.ChartsFeature, {
    show: IS_DEV_ENV,
    enabled: IS_DEV_ENV
  }),
  timeStep: createEntityState(EntityType.TimeStepFeature, {
    show: true,
    enabled: true
  }),
  isProducingRateType: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  cutoff: createEntityState(EntityType.CutOffChartFeature, {
    show: true,
    enabled: true
  }),
  shutInMonths: createEntityState(EntityType.ShutInMonthsChartFeature, {
    show: true,
    enabled: true
  }),
  movingAverageDays: createEntityState(EntityType.MovingAverageDaysChartFeature, {
    show: true,
    enabled: true
  }),
  survivorBias: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  useWeightedRatioAverage: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  lockUnits: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  lasso: createEntityState(EntityType.LassoFeature, {
    show: true,
    enabled: true
  }),
  typewells: createEntityState(EntityType.ChartsFeature, {
    show: true
  }),
  p10: createEntityState(EntityType.P10Feature, {
    show: false
  }),
  p50: createEntityState(EntityType.P50Feature, {
    show: false
  }),
  p90: createEntityState(EntityType.P90Feature, {
    show: false
  }),
  average: createEntityState(EntityType.AverageFeature, {
    show: false
  }),
  normalization: createEntityState(EntityType.NormalizationFeature, {
    show: true,
    enabled: false,
    active: false
  }),
  chartFocus: createEntityState(EntityType.ChartFocusFeature, {
    show: true,
    enabled: true
  })
};

export const crossPlotFeatures = createMergeWithDefaults(CROSS_PLOT_FEATURES);
