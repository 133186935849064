import styled from "styled-components";

import { BaseButton } from "components/base";

export const ProductContainer = styled.div`
  width: 375px;
  padding-bottom: 25px;
  max-height: 90vh;
  overflow-y: auto; /* Enable vertical scrolling */

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
    padding: 0 25px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0 0px;
    margin: 0;
  }
`;

export const ProductButton = styled(BaseButton)`
  min-height: 60px;
  height: auto;
  border-radius: 4px;
  color: #041c2c;
  background-color: unset;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;

  &.isSelected {
    background: rgba(var(--color-shadow-rgb), 0.3);
    color: var(--color-primary);

    svg {
      color: var(--color-primary);
    }
  }

  span {
    margin-top: 0;
  }

  .label {
    line-height: 18px;
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: rgba(var(--color-shadow-rgb), 0.3);

    svg {
      color: var(--color-primary);
    }
  }

  &.isSelected:hover {
    background: rgba(var(--color-shadow-rgb), 0.9);

    svg {
      color: var(--color-primary);
    }
  }

  svg {
    min-height: 40px;
    color: #a2aaad;
    transition: color var(--duration-short);
  }
`;
