import { lazy, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import classnames from "classnames";
import { DEFAULT_CHART_PRODUCT } from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { EntityType } from "constants/entities.enum";
import { useChartsContext } from "contexts/ChartContext";
import { createFeatureEntities } from "entities/charts/eva/features";
import { createEntityState } from "entities/charts/factory";
import {
  createChart,
  selectChartFullScreenFeature,
  selectChartScreenshotFeature
} from "store/features/charts/chartSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { noop, retry } from "utils";

import useChartSize from "hooks/charts/echart/useChartSize";

import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";

import ChartLegend from "../legend/Legend";
import ChartAxis from "./ChartAxis";
import ChartToolbar from "./ChartToolbar";
import "./ChartWrapper.scss";
import Screenshot from "./Screenshot";
import WellCountToggle from "./WellCountToggle";

const Chart = lazy(() => retry(() => import("./Chart")));
interface ChartWrapperT {
  className?: string;
  onFullscreen?: (b: boolean) => void;
  type?: "Well" | "Midstream";
}
function ChartWrapper({
  className = "",
  type = EntityKind.Well,
  onFullscreen = noop
}: ChartWrapperT) {
  const { id } = useChartsContext();
  const wrapperRef = useRef(null);

  const rootClassnames = classnames("chart-wrapper", className);
  const screenshotContainerId = `screenshot-container-${id}`;
  const dispatch = useDispatch();
  const isFullscreen = useSelector((state: RootState) =>
    selectChartFullScreenFeature(state, id)
  )?.active;
  const screenshotState = useSelector((state: RootState) =>
    selectChartScreenshotFeature(state, id)
  );

  // custom hooks
  useChartSize(id, wrapperRef);
  useEffect(() => onFullscreen(isFullscreen), [isFullscreen]);
  // read from session storage
  const sessionData = JSON.parse(sessionStorage.getItem(`chart::${id}`));
  const chartType = sessionData?.chartType ?? EvaChart.RateCum;
  const product = sessionData?.product ?? DEFAULT_CHART_PRODUCT;
  const features = createFeatureEntities(chartType, { ...sessionData?.features });
  const entityKind = EntityKind.Well; // TODO chart: will need to handle changing this to facility when working with midstream charts
  dispatch(
    createChart({
      id,
      ...createEntityState(EntityType.Chart, {
        id,
        chartType,
        product,
        features,
        entityKind
      })
    })
  );

  return (
    <ErrorBoundary>
      <Wrapper
        ref={wrapperRef} // for useChartSize
        className={rootClassnames}
        data-testid="chartWrapper"
        screenshot={screenshotState.active}>
        <ChartContainer screenshot={screenshotState?.active}>
          <Chart />
        </ChartContainer>

        {!screenshotState?.active && <ChartToolbar />}

        {false && <ChartLegend />}

        {false && (
          <>
            <ChartAxis />
            <ChartAxis />
          </>
        )}

        <Screenshot containerId={screenshotContainerId} />

        {false && <WellCountToggle type={type} />}

        {/* <ContextMenu
          allow={copyUWIContextMenuAllowed}
          visible={contextMenuVisible}
          position={contextMenuPosition}
          content={<CopyUWIButton uwi={uwiRef.current} />}
        /> */}
      </Wrapper>
    </ErrorBoundary>
  );
}

export default ChartWrapper;

// TODO chart: midstream background
// background: ${(props) =>
//   props.useGreyMidstreamChartBackground && !props.screenshot
//     ? MIDSTREAM_CHART_BACKGROUND_COLOUR
//     : ""};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  border-radius: var(--border-radius);
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  box-shadow: rgba(var(--color-shadow-rgb), 0.2) 0 0 1px;
  padding-top: 10px;
  z-index: 0;
  background: "";

  &.hovered {
    box-shadow: rgba(var(--color-shadow-rgb), 0.4) 0 0 1px;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--index-fullscreen);
  }
`;

const ChartContainer = styled.div`
  justify-self: ${(props) => (props.screenshot ? "center" : "auto")};

  &:hover {
    z-index: 1;
  }
`;
