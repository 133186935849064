import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";

import { INormalizeBySetting } from "models";

import { useChartEntities } from "../../../hooks/charts";

const useGlobalNormalizeBy = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { normalizeBy } = useChartEntities(id);
  const normalizationSettings = normalizeBy.properties;

  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const globalUseNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );

  const globalUseNormalizeByRef = useRef(globalUseNormalizeBy);

  useEffect(() => {
    let values: Partial<INormalizeBySetting>;
    const wasEnabled = globalUseNormalizeByRef.current;
    const isEnabled = globalUseNormalizeBy;
    // when global normalization is turned ON
    if (isEnabled && !wasEnabled) {
      values = {
        ...normalizationSettings,
        useNormalizeBy: true,
        ...globalNormalizeBy,
        displayName: globalNormalizeBy.display
      };
      dispatch(
        updateChartByPath({
          id,
          path: "features.normalization.active",
          value: true
        })
      );
    }

    // when global normalization settings are changed while already ON
    else if (isEnabled && wasEnabled) {
      values = {
        ...normalizationSettings,
        ...globalNormalizeBy,
        displayName: globalNormalizeBy.display
      };
    }

    // when global normalization is turned OFF
    else if (!isEnabled && wasEnabled) {
      values = { ...normalizationSettings, useNormalizeBy: false };
      dispatch(
        updateChartByPath({
          id,
          path: "features.normalization.active",
          value: false
        })
      );
    }

    if (values) {
      dispatch(
        updateChartByPath({
          id,
          path: "features.normalization.properties",
          value: values
        })
      );
    }

    globalUseNormalizeByRef.current = globalUseNormalizeBy;
  }, [globalUseNormalizeBy, JSON.stringify(globalNormalizeBy)]);
};

export default useGlobalNormalizeBy;
